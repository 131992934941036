import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, OutlinedInput, Button, Alert } from "@mui/material";
import { NavBar } from "components/Components";
import { UserRequest } from "api/requests/Requests";
import { ProcessAction, NetworkAction } from "redux/Actions";
import {
    getLanguageCode,
    getDeviceType,
    getDeviceToken,
    getMajorVersion,
    getMinorVersion,
    getBuildNo,
} from "redux/Selectors";
import {
    loadGoogleAuthenticationScript,
    unloadGoogleAuthenticationScript,
} from "functions/GoogleAuthenticationFunction";
import { pushDataLayer } from "gtm/gtm"
import { AppConstants } from "constants/Constants";
import "styles/pages/login/Login.scss";

const Login: React.FC = () => {
    // Utility.log("@@@@@ Login");
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const langCd = useSelector((state) => getLanguageCode(state));
    const deviceType: number | null = useSelector((state) => getDeviceType(state));
    const deviceToken: string | null = useSelector((state) => getDeviceToken(state));
    const majorVersion: number | null = useSelector((state) => getMajorVersion(state));
    const minorVersion: number | null = useSelector((state) => getMinorVersion(state));
    const buildNo: number | null = useSelector((state) => getBuildNo(state));

    /***** useRef */
    // const langCode = React.useRef<string>();
    const refPassword = React.useRef<HTMLInputElement | null>(null);
    const isUnderProcessing = React.useRef<boolean>(false);

    /***** useState */
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [errMessage, setErrMessage] = React.useState<string>("");
    const [btnLoginLabel, setBtnLoginLabel] = React.useState<string>("");
    const [btnLoginDisabled, setBtnLoginDisabled] =
        React.useState<boolean>(false);

    /**
     * ログインボタンのラベル設定
     * @param labelId
     */
    const setLoginButtonLabel = useCallback((labelId: string) => {
        const btnLabel = intl.formatMessage({
            id: labelId,
        });
        setBtnLoginLabel(btnLabel);
    }, [intl]);

    /**
     * useEffect
     */
    React.useEffect(() => {
        pushDataLayer({
            event: 'page_view',
            screen: "ログイン",
            path: window.location.pathname,
            deviceType: deviceType,
        });
    }, [deviceType]);
    React.useEffect(() => {
        setLoginButtonLabel("btn_login");
        window.onGoogleAuthenticationResult = (response: any) => {
            var clientId = "";
            if (response.hasOwnProperty("client_id")) {
                clientId = response.client_id;
            } else if (response.hasOwnProperty("clientId")) {
                clientId = response.clientId;
            }
            if (clientId !== AppConstants.GOOGLE_OAUTH_CLIENT_ID) {
                return -1;
            }
            var credential = "";
            if (response.hasOwnProperty("credential")) {
                credential = response.credential;
            }
            if (credential === "") {
                return -1;
            }
            login(true, clientId, credential);

            return 0;
        };
        loadGoogleAuthenticationScript();

        return () => {
            unloadGoogleAuthenticationScript();
        };
    }, []);

    React.useEffect(() => {
        if (langCd == null) {
            return;
        }
        setLoginButtonLabel("btn_login");
    }, [
        langCd,
        setLoginButtonLabel,
    ]);

    /**
     * ログインボタンクリック時
     * @returns
     */
    const onClickLogin = async () => {
        if (validate()) {
            login(false);
        }
    };

    function validate() {
        if (email.length === 0) {
            const errMessage = intl.formatMessage({
                id: "err_empty_email",
            });
            setErrMessage(errMessage);
            return false;
        }
        if (password.length === 0) {
            const errMessage = intl.formatMessage({
                id: "err_empty_password",
            });
            setErrMessage(errMessage);
            return false;
        }
        return true;
    }

    /**
     * ログイン
     */
    async function login(
        isGoogleAuth: boolean,
        clientId?: string,
        credential?: string
    ) {
        if (isUnderProcessing.current) {
            return;
        }
        isUnderProcessing.current = true;

        setBtnLoginDisabled(true);
        setLoginButtonLabel("msg_processing");
        dispatch(
            ProcessAction({ processing: true, message: "msg_processing" })
        );
        let result = null;
        if (isGoogleAuth) {
            if (clientId == null || credential == null) {
                return;
            }
            result = await UserRequest.loginWithGoogle(
                clientId, 
                credential, 
                deviceType, 
                deviceToken,
                majorVersion,
                minorVersion,
                buildNo
            );
        } else {
            result = await UserRequest.login(
                email, 
                password, 
                deviceType, 
                deviceToken,
                majorVersion,
                minorVersion,
                buildNo
            );
        }
        try {
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                const errMessage = intl.formatMessage({
                    id: "err_failure_login",
                });
                setErrMessage(errMessage);
                return;
            }

            setTimeout(function () {
                window.location.href = "/";
            });
        } catch (e) {
        } finally {
            setBtnLoginDisabled(false);
            setLoginButtonLabel("btn_login");
            dispatch(ProcessAction({ processing: false, message: "" }));
            isUnderProcessing.current = false;
        }
    }
    return (
        <div className="pageWrapper Login">
            {/* ページタイトル */}
            <NavBar
                showBack={false}
                title={intl.formatMessage({ id: "title_login" })}
            />

            {/* エラーメッセージエリア */}
            {errMessage.length > 0 && (
                <Alert
                    className="error-message"
                    severity="error"
                    onClose={() => {
                        setErrMessage("");
                    }}
                >
                    {errMessage}
                </Alert>
            )}
            <Box
                component="h1"
                className="page-title"
                display={{
                    xs: "none",
                    sm: "block",
                }}
            >
                <FormattedMessage id={"title_login"} />
            </Box>

            {
                // 入力エリア
            }
            <form>
                {
                    // Google認証
                }
                <div id="google-oauth-area" className="google-oauth-area">
                    <div
                        id="g_id_onload"
                        data-client_id="1024662147490-65t4ro788gqhscbm6trrgt0u6vap2nuj.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="onGoogleAuthenticationResult"
                        data-auto_prompt="false"
                    ></div>
                    <div
                        className="g_id_signin"
                        data-type="standard"
                        data-shape="rectangular"
                        data-theme="filled_blue"
                        data-text="signin_with"
                        data-size="large"
                        data-logo_alignment="left"
                    ></div>
                </div>
                {
                    // Email
                }
                <FormControl className="email-area" variant="standard">
                    <label className="email-label" htmlFor="txtEmail">
                        <FormattedMessage id={"email_address"} />
                    </label>
                    <OutlinedInput
                        className="email-value"
                        fullWidth
                        id="txtEmail"
                        label=""
                        type="email"
                        inputProps={{
                            maxLength: 50,
                        }}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setTimeout(() => {
                                    if (
                                        refPassword != null &&
                                        refPassword.current != null
                                    ) {
                                        refPassword.current.focus();
                                    }
                                });
                            }
                        }}
                    />
                </FormControl>
                {
                    // パスワード
                }
                <FormControl className="password-area" variant="standard">
                    <label className="password-label" htmlFor="txtPassword">
                        <FormattedMessage id={"password"} />
                    </label>
                    <OutlinedInput
                        className="password-value"
                        inputRef={(node) => {
                            refPassword.current = node;
                        }}
                        fullWidth
                        id="txtPassword"
                        label=""
                        type="password"
                        inputProps={{
                            maxLength: 300,
                        }}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onClickLogin();
                            }
                        }}
                    />
                </FormControl>
                {langCd === "ja" && (
                    <p className="reset-password">
                        パスワードを忘れた方は
                        <Link to="/reset_password/request">こちら</Link>へ
                    </p>
                )}
                {langCd === "en" && (
                    <p className="reset-password">
                        If you forget your password,{" "}
                        <br className="d-block d-sm-none" /> please proceed from{" "}
                        <Link to="/reset_password/request">here</Link>.
                    </p>
                )}
                {langCd === "cn" && (
                    <p className="reset-password">
                        忘记密码的用户请
                        <Link to="/reset_password/request">点击这里</Link>.
                    </p>
                )}
                {langCd === "tw" && (
                    <p className="reset-password">
                        忘記密碼的用戶請
                        <Link to="/reset_password/request">點擊這裡</Link>.
                    </p>
                )}
                {langCd === "es" && (
                    <p className="reset-password">
                        Si ha olvidado su contraseña, haga clic
                        {" "}
                        <Link to="/reset_password/request">aquí</Link>.
                    </p>
                )}
                {langCd === "de" && (
                    <p className="reset-password">
                        Wenn Sie Ihr Passwort vergessen haben, klicken Sie
                        {" "}
                        <Link to="/reset_password/request">hier</Link>.
                    </p>
                )}
                {langCd === "it" && (
                    <p className="reset-password">
                        Se hai dimenticato la tua password, clicca
                        {" "}
                        <Link to="/reset_password/request">qui</Link>.
                    </p>
                )}
                {langCd === "ru" && (
                    <p className="reset-password">
                        Если вы забыли пароль, нажмите
                        {" "}
                        <Link to="/reset_password/request">здесь</Link>.
                    </p>
                )}
                {langCd !== "ja" && 
                langCd !== "en" &&
                langCd !== "cn" &&
                langCd !== "tw" &&
                langCd !== "es" &&
                langCd !== "de" &&
                langCd !== "it" &&
                langCd !== "ru" && (
                    <p className="reset-password">
                        If you forget your password,{" "}
                        <br className="d-block d-sm-none" /> please proceed from{" "}
                        <Link to="/reset_password/request">here</Link>.
                    </p>
                )}
                <div className="button-area">
                    <Button
                        className={
                            btnLoginDisabled ? "app-button button disabled" : "app-button button"
                        }
                        variant="text"
                        disabled={btnLoginDisabled}
                        onClick={onClickLogin}
                    >
                        {btnLoginLabel}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default Login;
