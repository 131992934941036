/***** import */
import React, { ChangeEvent, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    MouseSensor,
    DragStartEvent,
    DragMoveEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
  } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {
    SortableContainer,
    SortableElement,
    SortableContainerProps,
    SortableElementProps,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AppConstants, DbConstants } from "constants/Constants";
import { AlbumRequest } from "api/requests/Requests";
import { UserModel, AlbumModel, GalleryModel } from "models/Models";
import {
    NavBar,
    GalleryListItem,
    ImageComponent,
    VideoComponent,
} from "components/Components";
import { NetworkAction, ProcessAction } from "redux/Actions";
import { getDeviceType } from "redux/Selectors";
import Utility from "utils/Utility";
import Upload from "assets/images/upload.svg";
import ViewImage from "assets/images/view_image.svg";
import ViewImageOff from "assets/images/view_image_off.svg";
import Play from "assets/images/play.svg";
import PlayOff from "assets/images/play_off.svg";
import Trash from "assets/images/trash.svg";
import TrashOff from "assets/images/trash_off.svg";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/GalleryList.scss";

/**
 * ギャラリー
 */
type SortableGalleryProps = {
    index: number;
    gallery: GalleryModel;
    selected: boolean;
    onClickGallery: () => void;
    onDoubleClickGallery?: () => void;
};
const SortableGallery = SortableElement<
    SortableGalleryProps & SortableElementProps
>((props: SortableGalleryProps) => {
    return (
        <GalleryListItem
            gallery={props.gallery}
            selected={props.selected}
            onClick={props.onClickGallery}
            onDoubleClick={props.onDoubleClickGallery}
        />
    );
});

type SortableGalleryListProps = {
    lstGallery: GalleryModel[];
    onClickGallery: (gallery: GalleryModel) => void;
    onDoubleClickGallery?: (gallery: GalleryModel) => void;
};
const SortableGalleryList = SortableContainer<
    SortableGalleryListProps & SortableContainerProps
>((props: SortableGalleryListProps) => {
    return (
        <div className="gallery-contents">
            {props.lstGallery.map((gallery, index) => {
                return (
                    <SortableGallery
                        key={index}
                        index={index}
                        gallery={gallery}
                        selected={gallery.selected}
                        onClickGallery={() => {
                            props.onClickGallery(gallery);
                        }}
                        onDoubleClickGallery={() => {
                            if (props.onDoubleClickGallery != null) {
                                props.onDoubleClickGallery(gallery);
                            }
                        }}
                    />
                );
            })}
        </div>
    );
});

type GalleryProps = {
    gallery: GalleryModel;
}
const SortableItem: React.FC<GalleryProps> = (props) => {
    const {
        isDragging,
        setActivatorNodeRef,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.gallery.id == null ? 0 : props.gallery.id});
    

    return (
        <li
            className="gallery-item pc"
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition
            }}
             {...listeners}
         >
            <GalleryListItem
                key={props.gallery.id}
                gallery={props.gallery}
                selected={props.gallery.selected}
            />
        </li>
    );
}

/***** type */
type Props = {
    loginUser: UserModel;
};

/**
 * アイテムリスト画面
 * @param props
 * @returns
 */
const GalleryList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GalleryList IN");
        /***** 定数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const { album_id } = useParams<{ album_id: string }>();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(TouchSensor, {
                activationConstraint: {
                    distance: 10,
                },
            }),
            useSensor(KeyboardSensor, {
              coordinateGetter: sortableKeyboardCoordinates,
            }),
            useSensor(MouseSensor, {
                activationConstraint: {
                    distance: 10,
                },
            })
        );

        /***** useRef */
        const refPageWrapper = React.useRef<HTMLDivElement>(null);
        const refTouchStart = React.useRef<boolean>(false);
        const refTouchMoving = React.useRef<boolean>(false);
        const refDoubleClick = React.useRef<boolean>(false);
        const refTouchTime = React.useRef<number>(0);
        const refTouchStartX = React.useRef<number>(0);
        const refTouchStartY = React.useRef<number>(0);

        const refAttachFile = React.useRef<HTMLInputElement>(null);
        // アルバム
        const refAlbum = React.useRef<AlbumModel>();
        // ギャラリー一覧参照
        const refGalleryList = React.useRef<GalleryModel[]>();

        /***** useState */
        // アルバム
        const [album, setAlbum] = React.useState<AlbumModel>();
        // ギャラリー一覧
        const [lstGallery, setGalleryList] = React.useState<GalleryModel[]>();
        const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
        const [selectedGallery, setSelectedGallery] =
            React.useState<GalleryModel>();
        const [showImage, setShowImage] = React.useState<boolean>(false);
        const [showVideo, setShowVideo] = React.useState<boolean>(false);
        const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
            React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] =
            React.useState<string>();
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "アルバムコンテンツ一覧",
                path: window.location.pathname,
                deviceType: deviceType,
            });
        }, []);        
        React.useEffect(() => {
            if (album_id != null && album_id.length > 0) {
                fetchGalleryList();
            }
        }, [album_id]);

        /**
         * ファイルドロップ時
         */
        // const onDrop = React.useCallback(async (files: File[]) => {
        //     dispatch(
        //         ProcessAction({
        //             processing: true,
        //             message: "msg_uploading",
        //         })
        //     );
        //     for (let i = 0; i < files.length; i++) {
        //         await upload(files[i]);
        //     }
        //     dispatch(
        //         ProcessAction({
        //             processing: false,
        //             message: null,
        //         })
        //     );
        // }, []);
        // const { getRootProps } = useDropzone({
        //     onDrop,
        //     accept: {
        //         "image/*": [".jpeg", ".png"],
        //         "video/*": [".mp4", ".mov", ".avi", ".mpeg"],
        //     },
        //     multiple: true,
        // });

        /**
         * 順序変更時
         * @param param0
         */
        const onSortEnd = ({
            oldIndex,
            newIndex,
        }: {
            oldIndex: number;
            newIndex: number;
        }) => {
            if (refGalleryList == null || refGalleryList.current == null) {
                return;
            }
            const oldList = refGalleryList.current;
            if (oldList == null) {
                return;
            }
            const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
            requestReorder(newList);
        };

        /**
         * ギャラリークリック時
         * @param _gallery
         * @returns
         */
        async function onClickGallery(_gallery: GalleryModel) {
            if (refGalleryList == null || refGalleryList.current == null) {
                return;
            }
            const newList = refGalleryList.current.map(
                (gallery: GalleryModel) => {
                    if (_gallery.id === gallery.id) {
                        gallery.selected = !gallery.selected;
                        if (gallery.selected) {
                            setSelectedGallery(gallery);
                        } else {
                            setSelectedGallery(undefined);
                        }
                    } else {
                        gallery.selected = false;
                    }
                    return gallery;
                }
            );
            refGalleryList.current = newList;
            setGalleryList(newList);
        }
        /**
         * ギャラリーダブルクリック時
         * @param _gallery
         * @returns
         */
        async function onDoubleClickGallery(_gallery: GalleryModel) {
            if (refGalleryList == null || refGalleryList.current == null) {
                return;
            }
            if (_gallery.fileName == null) {
                return;
            }
            const newList = refGalleryList.current.map(
                (gallery: GalleryModel) => {
                    if (_gallery.id === gallery.id) {
                        gallery.selected = true;
                        // if (gallery.selected) {
                        setSelectedGallery(gallery);
                        // } else {
                        //     setSelectedGallery(undefined);
                        // }
                    } else {
                        gallery.selected = false;
                    }
                    return gallery;
                }
            );
            refGalleryList.current = newList;
            setGalleryList(newList);
            if (Utility.isImage(_gallery.fileName)) {
                setShowImage(true);
                setShowVideo(false);
            } else if (Utility.isVideo(_gallery.fileName)) {
                setShowImage(false);
                setShowVideo(true);
            }
        }
        /**
         * アップロードボタンクリック時
         */
        async function onClickUpload() {
            if (refAttachFile != null && refAttachFile.current != null) {
                refAttachFile.current.click();
            }
        }
        /**
         * 画像拡大ボタンクリック時
         * @returns
         */
        async function onClickViewImage() {
            if (selectedGallery == null) {
                return;
            }
            if (
                selectedGallery.fileName == null ||
                Utility.isVideo(selectedGallery.fileName)
            ) {
                return;
            }
            if (selectedGallery.selected) {
                setShowImage(true);
            } else {
                setShowImage(false);
            }
        }
        /**
         * 再生ボタンクリック時
         * @returns
         */
        async function onClickPlay() {
            if (selectedGallery == null) {
                return;
            }
            if (
                selectedGallery.fileName == null ||
                Utility.isImage(selectedGallery.fileName)
            ) {
                return;
            }
            if (selectedGallery.selected) {
                setShowVideo(true);
            } else {
                setShowVideo(false);
            }
        }
        /**
         * ゴミ箱ボタンクリック時
         * @returns
         */
        async function onClickTrash() {
            if (selectedGallery == null) {
                return;
            }
            if (selectedGallery.selected) {
                setOpenDeleteConfirmDialog(true);
            }
        }

        /**
         * ファイル選択時
         * @param e
         * @returns
         */
        async function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
            const target = e.currentTarget as HTMLInputElement;
            const lstFile = target.files as FileList;
            if (lstFile == null) {
                return;
            }

            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_uploading",
                })
            );
            for (let i = 0; i < lstFile.length; i++) {
                const file = lstFile[i];
                await upload(file);
            }
            target.value = "";
            dispatch(
                ProcessAction({
                    processing: false,
                    message: null,
                })
            );
        }

        /**
         * ギャラリーアップロード
         * @param file
         * @returns
         */
        async function upload(file: File) {
            if (
                refAlbum == null ||
                refAlbum.current == null ||
                refAlbum.current.id == null
            ) {
                return;
            }
            let fileName = file.name;
            const gallery = new GalleryModel();
            gallery.albumId = refAlbum.current.id;
            if (Utility.isImage(fileName)) {
                gallery.type = DbConstants.FILE_TYPE_IMAGE;
            } else {
                gallery.type = DbConstants.FILE_TYPE_VIDEO;
            }
            if (lstGallery == null || lstGallery.length === 0) {
                gallery.orderNo = 1;
            } else {
                gallery.orderNo = lstGallery.length + 1;
            }
            gallery.file = file;
            const array: string[] = fileName.split(".");
            if (array.length === 1) {
                return;
            }
            // fileName = array.join(".");
            gallery.fileName = Utility.addDateTimeToFileName(fileName);
            const result = await AlbumRequest.appendGallery(
                props.loginUser,
                gallery
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.gallery == null
            ) {
                if (result.rtnCd === AppConstants.ERROR_VISION_VERY_LIKELY) {
                    if (album != null && album.open) {
                        setDialogMessage(intl.formatMessage({id: "err_explicit_contents_to_public"}));
                        setOpenDialog(true);
                    }
                } 
                return;
            }
            if (
                refGalleryList == null ||
                refGalleryList.current == null ||
                refGalleryList.current.length === 0
            ) {
                const wkGalleryList = [result.gallery];
                refGalleryList.current = wkGalleryList;
                setGalleryList(wkGalleryList);
            } else {
                const wkGalleryList = [...refGalleryList.current];
                wkGalleryList.unshift(result.gallery);
                refGalleryList.current = wkGalleryList;
                setGalleryList(wkGalleryList);
            }
        }
        /**
         * ギャラリー順序変更リクエスト
         * @param lstGallery
         * @returns
         */
        async function requestReorder(lstGallery: GalleryModel[]) {
            if (
                refAlbum == null ||
                refAlbum.current == null ||
                refAlbum.current.id == null
            ) {
                return;
            }
            const lstGalleryId: string[] = [];
            const lstGalleryOrder: string[] = [];
            for (let i = 0; i < lstGallery.length; i++) {
                const gallery = lstGallery[i];
                if (gallery.id == null) {
                    return;
                }
                lstGalleryId.push(String(gallery.id));
                lstGalleryOrder.push(String(lstGallery.length - i));
            }
            const result = await AlbumRequest.saveGalleryOrder(
                props.loginUser,
                refAlbum.current.id,
                lstGalleryId,
                lstGalleryOrder
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            refGalleryList.current = lstGallery;
            setGalleryList(lstGallery);
        }

        /**
         * ギャラリー削除
         * @param _gallery
         * @returns
         */
        async function deleteGallery() {
            if (selectedGallery == null) {
                return;
            }
            const result = await AlbumRequest.deleteGallery(
                props.loginUser,
                selectedGallery
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (lstGallery != null) {
                const newList = lstGallery.filter((gallery) => {
                    if (selectedGallery.id === gallery.id) {
                        return false;
                    } else {
                        return true;
                    }
                });
                setSelectedGallery(undefined);
                refGalleryList.current = newList;
                setGalleryList(newList);
            }
        }
        /**
         * ギャラリーリスト取得
         * @returns
         */
        async function fetchGalleryList() {
            if (
                props.loginUser == null ||
                album_id == null ||
                album_id.length === 0
            ) {
                return null;
            }
            let result = await AlbumRequest.getGalleryList(
                props.loginUser,
                album_id
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.album == null
            ) {
                return null;
            }
            refAlbum.current = result.album;
            if (result.album != null) {
                setAlbum(result.album);
            }
            if (result.lstGallery != null) {
                refGalleryList.current = result.lstGallery;
                setGalleryList(result.lstGallery);
            }
            setDataLoaded(true);
        }

        function getGallery(galleryId: number) {
            if (lstGallery == null) {
                return null;
            }
            for (let i=0; i<lstGallery.length; i++) {
                const gallery = lstGallery[i];
                if (gallery.id === galleryId) {
                    return gallery;
                }
            }
            return null;
        }
        function handleClick(galleryId: number) {
            if (refTouchStart.current) {
                refDoubleClick.current = true;
                return;
            }
            const gallery = getGallery(galleryId);
            if (gallery == null) {
                return;
            }
            if (refDoubleClick.current) {
                refDoubleClick.current = false;
                onDoubleClickGallery(gallery);
                return;
            }
            onClickGallery(gallery);
        }
        function handleDragStart(event: DragStartEvent) {
            refTouchStart.current = true;
        }
        function handleDragMove(event: DragMoveEvent) {
            if (refTouchStartX.current === 0 && refTouchStartY.current === 0) {
                refTouchStartX.current = event.delta.x;
                refTouchStartY.current = event.delta.y;
                return;
            }
            const x = event.delta.x;
            const y = event.delta.y;
            const diffX = x - refTouchStartX.current;
            const diffY = y - refTouchStartY.current;
            if (Math.abs(diffX) + Math.abs(diffY) > 2) {
                refTouchMoving.current = true;
            }
        }
        function handleDragEnd(event: DragEndEvent) {
            refTouchTime.current = new Date().getTime();
            if (refTouchStart.current && !refTouchMoving.current) {
                refTouchMoving.current = false;
                refTouchStart.current = false;
                window.setTimeout(() => {
                    handleClick(parseInt(event.active.id.toString()));
                }, 150);
                return;
            }
            refTouchMoving.current = false;
            refTouchStart.current = false;
            refTouchTime.current = new Date().getTime();
            const {active, over} = event;
            
            let wkGalleryList = lstGallery;
            if (wkGalleryList == null) {
                return;
            }
            if (over != null && over.id != null && active.id !== over.id) {
                const oldIndex = wkGalleryList.findIndex((gallery) => gallery.id === active.id);
                const newIndex = wkGalleryList.findIndex((gallery) => gallery.id === over.id);
                
                wkGalleryList = arrayMove(wkGalleryList, oldIndex, newIndex);
                const pos = refPageWrapper.current?.scrollTop
                setGalleryList([]);
                window.setTimeout(() => {
                    setGalleryList(wkGalleryList);
                });
                window.setTimeout(() => {
                    if (pos != null) {
                        refPageWrapper.current?.scrollTo({top: pos});
                    }
                }, 100);
                requestReorder(wkGalleryList);
            }
        }

        /**
         * レンダリング
         */
        return (
            <div ref={refPageWrapper} className="pageWrapper GalleryList">
                <NavBar
                    showBack={true}
                    title={
                        album == null || album.name == null
                            ? intl.formatMessage({ id: "title_item_list" })
                            : album.name
                    }
                />
                {dataLoaded &&
                    (lstGallery == null || lstGallery.length === 0) && (
                        <div className="no-gallery-page">
                            <FormattedMessage id="gallery_guide_sentence" />
                        </div>
                    )}
                {dataLoaded && lstGallery != null && lstGallery.length > 0 && (
                    <>
                        {album != null && album.open === 1 && (
                            <p className="album-precautions">
                                <FormattedMessage id={"section_public_precautions"} />
                            </p>
                        )}
                        <DndContext 
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragStart={handleDragStart}
                            onDragEnd={(event: DragEndEvent) => {
                                handleDragEnd(event);
                            }}
                            onDragMove={handleDragMove}
                        >
                            <ul className="gallery-contents">
                                <SortableContext 
                                    items={
                                        lstGallery.map((gallery) => {
                                            if (gallery.id == null) {
                                                return 0;
                                            } else {
                                                return gallery.id;
                                            }
                                    })
                                    }
                                >
                                    {lstGallery.map((gallery, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {isSizeXS && (
                                                    <li
                                                        className="gallery-item"
                                                        onClick={() => {
                                                            onClickGallery(gallery);
                                                        }}
                                                        onDoubleClick={() => {
                                                            onDoubleClickGallery(gallery);
                                                        }}
                                                    >
                                                        <GalleryListItem
                                                            gallery={gallery}
                                                            selected={gallery.selected}
                                                        />
                                                    </li>
                                        
                                                )}
                                                {!isSizeXS && (
                                                    <SortableItem
                                                        gallery={gallery} />
                                                )}
                                            </React.Fragment>
                                        );
                                    })}

                                </SortableContext>
                            </ul>
                        </DndContext>
                    </>
                )}
                <div className="operation-area">
                    {isSizeXS && (
                        <>
                            {/* ギャラリーアップロード */}
                            <img
                                className="operation-item upload"
                                src={Upload}
                                alt="File Upload Button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickUpload();
                                }}
                            />
                            <input
                                ref={refAttachFile}
                                type="file"
                                style={{ display: "none" }}
                                accept="image/*,video/*"
                                multiple
                                onChange={onFileSelected}
                                onClick={(e: MouseEvent<HTMLInputElement>) => {}}
                            />
                            {/* 画像拡大 */}
                            <img
                                className="operation-item view-image"
                                src={
                                    selectedGallery == null ||
                                    selectedGallery.fileName == null ||
                                    Utility.isVideo(selectedGallery.fileName)
                                        ? ViewImageOff
                                        : ViewImage
                                }
                                style={{
                                    cursor:
                                        selectedGallery == null ||
                                        selectedGallery.fileName == null ||
                                        Utility.isVideo(selectedGallery.fileName)
                                            ? "not-allowed"
                                            : "pointer",
                                }}
                                alt="Enlarge Button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickViewImage();
                                }}
                            />
                            {/* 動画再生 */}
                            <img
                                className="operation-item play"
                                src={
                                    selectedGallery == null ||
                                    selectedGallery.fileName == null ||
                                    Utility.isImage(selectedGallery.fileName)
                                        ? PlayOff
                                        : Play
                                }
                                style={{
                                    cursor:
                                        selectedGallery == null ||
                                        selectedGallery.fileName == null ||
                                        Utility.isImage(selectedGallery.fileName)
                                            ? "not-allowed"
                                            : "pointer",
                                }}
                                alt="Play Video Button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickPlay();
                                }}
                            />
                            {/* ギャラリー削除 */}
                            <img
                                className="operation-item trash"
                                src={selectedGallery == null ? TrashOff : Trash}
                                style={{
                                    cursor:
                                        selectedGallery == null
                                            ? "not-allowed"
                                            : "pointer",
                                }}
                                alt="Delete Button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClickTrash();
                                }}
                            />
                        </>
                    )}
                    {!isSizeXS && (
                        <>
                            {/* ギャラリーアップロード */}
                            <OverlayTrigger
                                overlay={
                                    <Popover id="tooltip-disabled" body>
                                        <FormattedMessage
                                            id={"tooltip_gallery_upload"}
                                        />
                                    </Popover>
                                }
                                delay={{ show: 250, hide: 400 }}
                            >
                                <img
                                    className="operation-item upload"
                                    src={Upload}
                                    alt="File Upload Button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickUpload();
                                    }}
                                />
                            </OverlayTrigger>
                            <input
                                ref={refAttachFile}
                                type="file"
                                style={{ display: "none" }}
                                accept="image/*,video/*"
                                multiple
                                onChange={onFileSelected}
                                onClick={(e: MouseEvent<HTMLInputElement>) => {}}
                            />
                            {/* 画像拡大 */}
                            <OverlayTrigger
                                overlay={
                                    <Popover id="tooltip-disabled" body>
                                        <FormattedMessage id={"tooltip_view_image"} />
                                    </Popover>
                                }
                                delay={{ show: 250, hide: 400 }}
                            >
                                <img
                                    className="operation-item view-image"
                                    src={
                                        selectedGallery == null ||
                                        selectedGallery.fileName == null ||
                                        Utility.isVideo(selectedGallery.fileName)
                                            ? ViewImageOff
                                            : ViewImage
                                    }
                                    style={{
                                        cursor:
                                            selectedGallery == null ||
                                            selectedGallery.fileName == null ||
                                            Utility.isVideo(selectedGallery.fileName)
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                    alt="Enlarge Button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickViewImage();
                                    }}
                                />
                            </OverlayTrigger>
                            {/* 動画再生 */}
                            <OverlayTrigger
                                overlay={
                                    <Popover id="tooltip-disabled" body>
                                        <FormattedMessage id={"tooltip_play_video"} />
                                    </Popover>
                                }
                                delay={{ show: 250, hide: 400 }}
                            >
                                <img
                                    className="operation-item play"
                                    src={
                                        selectedGallery == null ||
                                        selectedGallery.fileName == null ||
                                        Utility.isImage(selectedGallery.fileName)
                                            ? PlayOff
                                            : Play
                                    }
                                    style={{
                                        cursor:
                                            selectedGallery == null ||
                                            selectedGallery.fileName == null ||
                                            Utility.isImage(selectedGallery.fileName)
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                    alt="Play Video Button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickPlay();
                                    }}
                                />
                            </OverlayTrigger>
                            {/* ギャラリー削除 */}
                            <OverlayTrigger
                                overlay={
                                    <Popover id="tooltip-disabled" body>
                                        <FormattedMessage
                                            id={"tooltip_gallery_delete"}
                                        />
                                    </Popover>
                                }
                                delay={{ show: 250, hide: 400 }}
                            >
                                <img
                                    className="operation-item trash"
                                    src={selectedGallery == null ? TrashOff : Trash}
                                    style={{
                                        cursor:
                                            selectedGallery == null
                                                ? "not-allowed"
                                                : "pointer",
                                    }}
                                    alt="Delete Button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickTrash();
                                    }}
                                />
                            </OverlayTrigger>
                        </>
                    )}
                </div>
                {selectedGallery != null && (
                    <>
                        <ImageComponent
                            user={props.loginUser}
                            gallery={selectedGallery}
                            onClose={() => {
                                setShowImage(false);
                            }}
                            open={showImage}
                        />
                        <VideoComponent
                            user={props.loginUser}
                            gallery={selectedGallery}
                            onClose={() => {
                                setShowVideo(false);
                            }}
                            open={showVideo}
                        />
                    </>
                )}
                <Dialog
                    className="dialog"
                    open={openDeleteConfirmDialog}
                    onClose={() => {
                        setOpenDeleteConfirmDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent className="dialog-content2">
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"confirm_delete"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            onClick={async () => {
                                await deleteGallery();
                                setOpenDeleteConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_yes2"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenDeleteConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="dialog"
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent className="dialog-content2">
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            color="primary"
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                        >
                            <FormattedMessage id={"btn_ok"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default GalleryList;
