import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { UserRequest } from "api/requests/Requests";
import { DbConstants, AppConstants } from "constants/Constants";
import {
    UserModel,
    UserProfileData,
    GalleryData,
    GalleryModel,
    TagModel,
    CustomTagModel,
    HistoryUserList,
} from "models/Models";
import {
    NavBar,
    ImageComponent,
    VideoComponent,
    GalleryListItem,
    SendMessageDialog,
} from "components/Components";
import { block, unblock } from "functions/Functions";
import {
    NetworkAction,
    SelectedItemAction,
    UserAction,
    WorkingItemAction,
    UnreadCountAction,
    HistoryAction,
} from "redux/Actions";
import {
    getTimeDifference,
    getLanguageCode,
    getFollowUserList,
    getDeviceType,
    getBlockUserList,
    getUserListHistory,
} from "redux/Selectors";
import Utility from "utils/Utility";
import btnMessage from "assets/images/btn_message.png";
import btnChat from "assets/images/btn_chat.png";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/user/UserDetail.scss";

type Props = {
    loginUser: UserModel | null;
};

const UserDetail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ UserDetail IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { mark } = useParams<{ mark: string }>();
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const langCd = useSelector((state) => getLanguageCode(state));
        const timeDifference = useSelector((state) => getTimeDifference(state));
        const lstFollowUser = useSelector((state) => getFollowUserList(state));
        const lstBlockUser = useSelector((state) => getBlockUserList(state));
        const lstUserListHistory: HistoryUserList = useSelector((state) => getUserListHistory(state));

        /***** useRef */
        // ターゲットID
        const targetId = React.useRef<number>(0);
        // 処理中フラグ
        const isUnderProcess = React.useRef(false);

        /***** useState */
        // ターゲットユーザが自分かどうかフラグ
        const [isMe, setIsMe] = React.useState<boolean>(false);
        // データ読み込み完了フラグ
        const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
        // ユーザ不存在フラグ
        const [noUserFlag, setNoUserFlag] = React.useState<boolean>(false);
        // ターゲットユーザ
        const [target, setTarget] = React.useState<UserModel>(new UserModel());
        // ユーザプロフィールデータ
        const [profile, setProfile] = React.useState<UserProfileData>();
        // ブロックしてるかどうか
        const [blocked, setBlocked] = React.useState<boolean>(false);
        // 選択ギャラリー
        const [selectedGallery, setSelectedGallery] =
            React.useState<GalleryModel>();
        const [showImage, setShowImage] = React.useState<boolean>(false);
        const [showVideo, setShowVideo] = React.useState<boolean>(false);
        // メッセージ送信ダイアログ表示フラグ
        const [openSendMessageDialog, setOpenSendMessageDialog] =
            React.useState<boolean>(false);
        // ダイアログメッセージ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        const [dialogMessage, setDialogMessage] = React.useState<string>();

        const paramArray = mark?.split("-");
        if (paramArray?.length === 3) {
            targetId.current = Number(paramArray[2]);
        }
        const [formattedUpdatedAt, setFormattedUpdatedAt] =
            React.useState<string>("");

        // if (dataLoaded && props.loginUser != null && profile != null) {
        //     setFollowStatus();
        // }

        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "ユーザ詳細",
                path: window.location.pathname,
                deviceType: deviceType,
            })
            if (targetId.current === 0) {
                navigate(-1);
            } else {
                (async () => {
                    const wkTarget = await getTargetProfile();
                    if (wkTarget == null) {
                        return;
                    }

                    const updatedAt = new Date(wkTarget.updatedAt as string);
                    const unixTime = updatedAt.getTime() / 1000;
                    const formattedUpdatedAt = Utility.getFormattedDate(
                        unixTime,
                        timeDifference == null ? 0 : timeDifference,
                        langCd == null ? "en" : langCd
                    );
                    setFormattedUpdatedAt(formattedUpdatedAt);
                })();
            }
        }, []);
        React.useEffect(() => {
            if (props.loginUser != null && props.loginUser.id === target.id) {
                setIsMe(true);
            }
        }, [props.loginUser, target]);
        React.useEffect(() => {
            setFollowStatus();
        }, [lstFollowUser]);
        React.useEffect(() => {
            if (lstBlockUser == null || targetId == null || targetId.current == null) {
                return;
            }
            setBlocked(Utility.isBlocked(lstBlockUser, targetId.current));
        }, [
            targetId,
            lstBlockUser
        ]);

        /**
         * ギャラリークリック時
         * @param gallery
         * @returns
         */
        async function onClickGallery(gallery: GalleryModel) {
            if (gallery.fileName == null) {
                return;
            }
            setSelectedGallery(gallery);
            if (Utility.isImage(gallery.fileName)) {
                setShowImage(true);
            } else if (Utility.isVideo(gallery.fileName)) {
                setShowVideo(true);
            }
        }
        /**
         * メッセージボタン押下時
         */
        async function onClickSendMessage() {
            dispatch(
                WorkingItemAction({
                    mail: null,
                })
            );
            if (isSizeXS) {
                dispatch(
                    SelectedItemAction({
                        user: target,
                    })
                );
                navigate("/mail/send");
            } else {
                setOpenSendMessageDialog(true);
            }
        }

        /**
         * チャットボタン押下時
         */
        async function chat() {
            if (isMe) {
                return;
            }
            if (target == null) {
                return;
            }
            const isTalkAvailable = Utility.isChatAvailable(target);
            if (!isTalkAvailable) {
                const message = intl.formatMessage({
                    id: "msg_chat_unavailable",
                });
                if (message == null) {
                    return;
                }
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            if (isSizeXS) {
                navigate(`/talk/${targetId.current}`);
            } else {
                navigate(`/talk#${targetId.current}`);
            }
        }
        /**
         * フォローステータス設定
         * @returns
         */
        function setFollowStatus() {
            if (
                profile == null ||
                props.loginUser == null ||
                lstFollowUser == null
            ) {
                return;
            }
            let isUnderFollow = false;
            for (let i = 0; i < lstFollowUser.length; i++) {
                if (targetId.current === lstFollowUser[i].id) {
                    isUnderFollow = true;
                    break;
                }
            }
            profile.isUnderFollow = isUnderFollow;
        }
        /**
         * ローカルとReduxデータのブロックユーザリスト更新
         * @param target
         */
        const updateBlockUserList = React.useCallback((blocked: boolean, target: UserModel) => {
            let wkBlockUserList: UserModel[] | null = null;
            if (blocked) {
                wkBlockUserList = [target];
                if (lstBlockUser != null && lstBlockUser.length > 0) {
                    wkBlockUserList = wkBlockUserList.concat(lstBlockUser);
                }
            } else {
                if (lstBlockUser != null && lstBlockUser.length > 0) {
                    wkBlockUserList = lstBlockUser.filter((blockUser: UserModel) => {
                        if (blockUser.id === target.id) {
                            return false;
                        } else {
                            return true;
                        }
                    })
                }
            }
            dispatch(
                UserAction({
                    lstBlockUser: wkBlockUserList,
                })
            );
        }, [
            dispatch,
            lstBlockUser,
        ]);

        const updateUnreadCount = React.useCallback(async() => {
            // メール未読件数取得
            const p1 = Utility.getUnreadMailCount(props.loginUser);
            // トーク未読件数取得
            const p2 = Utility.getUnreadTalkCount(props.loginUser);
            const [unreadMailCount, unreadTalkCount] =
                await Promise.all([p1, p2]);
            dispatch(
                UnreadCountAction({
                    mail:
                        unreadMailCount != null
                            ? unreadMailCount
                            : 0,
                    talk:
                        unreadTalkCount != null
                            ? unreadTalkCount
                            : 0,
                })
            );
        }, [
            props.loginUser,
            dispatch
        ]);
        const updateUserListHistory = React.useCallback((blocked: boolean, blockedUser: UserModel) => {
            if (lstUserListHistory == null) {
                return;
            }
            let wkUserList = lstUserListHistory.list;
            if (wkUserList == null) {
                return;
            }
            for (let i=0; i<wkUserList.length; i++) {
                const user = wkUserList[i];
                if (blockedUser.id === user.id) {
                    if (blocked) {
                        user.blocking = true;
                    } else {
                        user.blocking = false;
                    }
                    break;
                }
            }
        }, [lstUserListHistory]);
        const onClickedBlock = React.useCallback(async() => {
            if (props.loginUser == null) {
                return;
            }
            const rtnCd = await block(props.loginUser, target);
            if (rtnCd === 0) {
                // ローカルとReduxデータのブロックユーザリスト更新
                updateBlockUserList(true, target);
                // 未読件数更新
                await updateUnreadCount();
                // ユーザリスト履歴を更新
                updateUserListHistory(true, target);
            }
        }, [
            props.loginUser,
            target,
            updateBlockUserList,
            updateUnreadCount,
            updateUserListHistory,
        ]);
        const onClickedUnblock = React.useCallback(async() => {
            if (props.loginUser == null) {
                return;
            }
            const rtnCd = await unblock(props.loginUser, target);
            if (rtnCd === 0) {
                // ローカルとReduxデータのブロックユーザリスト更新
                updateBlockUserList(false, target);
                // 未読件数更新
                await updateUnreadCount();
                // ユーザリスト履歴を更新
                updateUserListHistory(false, target);
            }
        }, [
            props.loginUser,
            target,
            updateBlockUserList,
            updateUnreadCount,
            updateUserListHistory,
        ]);

        /**
         * フォロー
         * @returns
         */
        async function follow() {
            if (
                props.loginUser == null ||
                targetId.current === 0 ||
                target == null
            ) {
                return;
            }
            if (isUnderProcess.current) {
                return;
            }
            isUnderProcess.current = true;
            const result = await UserRequest.follow(
                props.loginUser,
                targetId.current
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            let wkFollowUserList: UserModel[] | null = null;
            if (lstFollowUser == null) {
                wkFollowUserList = [];
            } else {
                wkFollowUserList = [...lstFollowUser];
            }
            wkFollowUserList.push(target);
            let user = props.loginUser;
            dispatch(
                UserAction({ loginUser: user, lstFollowUser: wkFollowUserList })
            );
            if (profile != null) {
                profile.isUnderFollow = true;
            }
            isUnderProcess.current = false;
        }
        /**
         * フォロー解除
         * @returns
         */
        async function unfollow() {
            if (
                props.loginUser == null ||
                targetId.current === 0 ||
                target == null
            ) {
                return;
            }
            if (isUnderProcess.current) {
                return;
            }
            isUnderProcess.current = true;
            const result = await UserRequest.unfollow(
                props.loginUser,
                targetId.current
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            let wkFollowUserList: UserModel[] | null = null;
            if (lstFollowUser == null) {
                wkFollowUserList = [];
            } else {
                wkFollowUserList = [...lstFollowUser];
            }
            if (wkFollowUserList.length > 0) {
                for (let i = 0; i < wkFollowUserList.length; i++) {
                    if (targetId.current === wkFollowUserList[i].id) {
                        wkFollowUserList.splice(i, 1);
                        break;
                    }
                }
                dispatch(
                    UserAction({
                        lstFollowUser: wkFollowUserList,
                    })
                );
            }
            if (profile != null) {
                profile.isUnderFollow = false;
            }
            isUnderProcess.current = false;
        }
        async function invite() {
            navigate(`/users/${mark}/invite`);
        }
        /**
         * ユーザのプロフィール情報取得
         * @returns
         */
        async function getTargetProfile() {
            let userId = 0;
            if (props.loginUser != null && props.loginUser.id != null) {
                userId = props.loginUser.id;
            }
            const result = await UserRequest.getProfile(
                userId,
                targetId.current,
                langCd == null ? "en" : langCd,
                timeDifference == null ? 0 : timeDifference
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd !== 0 && result.rtnCd !== AppConstants.ERROR_NO_USER) {
                navigate(-1);
                return;
            }
            if (result.targetUser != null) {
                const targetUser = new UserModel(result.targetUser);
                if (targetUser.invalid !== 0) {
                    setNoUserFlag(true);
                } else {
                    setTarget(targetUser);
                }
            } else {
                setNoUserFlag(true);
            }
            if (result.targetProfile != null) {
                setProfile(result.targetProfile);
            }
            setDataLoaded(true);
            return result.targetUser;
        }

        /**
         * 基本情報エリアタイトルHtml生成
         */
        function getBasicInfoAreaTitleHtml() {
            return (
                <h3
                    className={
                        DbConstants.getCssGenderLabel(target.gender) +
                        " title-header"
                    }
                >
                    <FormattedMessage id={"user_detail_title_basic_info"} />
                </h3>
            );
        }
        /**
         * フォローボタン生成
         * @returns
         */
        function getFollowButtonHtml() {
            if (!dataLoaded || profile == null || isMe) {
                return "";
            }
            let btnLabelKey = "btn_follow";

            if (profile.isUnderFollow) {
                btnLabelKey = "btn_unfollow";
            }
            return (
                <Button
                    className={
                        profile.isUnderFollow
                            ? "btn-follow under-follow"
                            : "btn-follow"
                    }
                    onClick={() => {
                        if (profile != null) {
                            if (profile.isUnderFollow) {
                                unfollow();
                            } else {
                                follow();
                            }
                        }
                    }}
                >
                    <FormattedMessage id={btnLabelKey} />
                </Button>
            );
        }
        /**
         * 招待ボタン生成
         * @returns
         */
        function getInviteButtonHtml() {
            if (!dataLoaded || profile == null || isMe) {
                return "";
            }
            if (!profile.hasGroup) {
                return "";
            }
            return (
                <Button className="btn-invite" onClick={invite}>
                    <FormattedMessage id={"btn_invite"} />
                </Button>
            );
        }
        /**
         * 興味/関心エリアタイトルHtml生成
         */
        function getInterestAreaTitleHtml() {
            return (
                <h3
                    className={
                        DbConstants.getCssGenderLabel(target.gender) +
                        " title-header"
                    }
                >
                    <FormattedMessage id={"user_detail_title_interest"} />
                </h3>
            );
        }
        /**
         * メッセージエリアタイトルHtml生成
         */
        function getMessageAreaTitleHtml() {
            return (
                <h3
                    className={
                        DbConstants.getCssGenderLabel(target.gender) +
                        " title-header"
                    }
                >
                    <FormattedMessage id={"user_detail_title_message"} />
                </h3>
            );
        }
        /**
         * ギャラリーエリアタイトルHtml生成
         */
        function getGalleryAreaTitleHtml() {
            return (
                <h3
                    className={
                        DbConstants.getCssGenderLabel(target.gender) +
                        " title-header"
                    }
                >
                    <FormattedMessage id={"user_detail_title_contents"} />
                </h3>
            );
        }
        /**
         * 興味/関心Html生成
         */
        function getInterestHtml() {
            if (!dataLoaded || profile == null) {
                return <div />;
            }
            if ((profile.tagList2 != null && profile.tagList2.length > 0) ||
                (profile.customTagList != null && profile.customTagList.length > 0)
            ) {
                return (
                    <div className="contents-wrapper">
                        {profile.customTagList != null && profile.customTagList.map((customTag: CustomTagModel, index: number) => {
                            return (
                                <span
                                    className={
                                        "tag " +
                                        DbConstants.getCssGenderLabel(
                                            target.gender
                                        )
                                    }
                                    key={index}
                                >
                                    {customTag.name}
                                </span>
                            );
                        })}
                        {profile.tagList2 != null && profile.tagList2.map((tag: TagModel, index: number) => {
                            let tagName = null;
                            switch (langCd) {
                                case "ja":
                                    tagName = tag.name;
                                    break;
                                case "en":
                                    tagName = tag.nameEn;
                                    break;
                                case "cn":
                                    tagName = tag.nameCn;
                                    break;
                                case "tw":
                                    tagName = tag.nameTw;
                                    break;
                                case "es":
                                    tagName = tag.nameEs;
                                    break;
                                case "de":
                                    tagName = tag.nameDe;
                                    break;
                                case "it":
                                    tagName = tag.nameIt;
                                    break;
                                case "ru":
                                    tagName = tag.nameRu;
                                    break;
                             }
                             if (tagName == null) {
                                tagName = tag.nameEn;
                             }
                            return (
                                <span
                                    className={
                                        "tag " +
                                        DbConstants.getCssGenderLabel(
                                            target.gender
                                        )
                                    }
                                    key={index}
                                >
                                    {tagName}
                                </span>
                            );
                        })}
                    </div>
                );
            } else {
                return "";
            }
        }
        /**
         * ギャラリーエリアのサブヘッダー生成
         * @param title
         * @returns
         */
        function getGallerySubHeaderHtml(title: string | null) {
            if (title != null && title !== "") {
                switch (title) {
                    case "public":
                        return (
                            <div className="sub-header public">
                                <FormattedMessage
                                    id={"user_detail_gallery_sub_title_public"}
                                />
                            </div>
                        );
                    case "follower":
                        return (
                            <div className="sub-header follower">{title}</div>
                        );
                    case "group":
                        return <div className="sub-header group">{title}</div>;
                    case "user":
                        return <div className="sub-header user">{title}</div>;
                    default:
                        return <div className="sub-header">{title}</div>;
                }
            }
        }
        /**
         * ギャラリーHtml生成
         */
        function getGalleryHtml() {
            if (!dataLoaded || profile == null) {
                return <div />;
            }
            if (
                profile.galleryDataList != null &&
                profile.galleryDataList.length > 0
            ) {
                return (
                    <div className="contents-wrapper">
                        {profile.galleryDataList.map(
                            (galleryData: GalleryData) => {
                                return (
                                    <div key={galleryData.title}>
                                        {getGallerySubHeaderHtml(
                                            galleryData.title
                                        )}
                                        <div className="gallery-contents-wrapper">
                                            {galleryData.galleryList?.map(
                                                (gallery: GalleryModel) => {
                                                    return (
                                                        <div
                                                            key={gallery.id}
                                                            className="gallery-item-wrapper"
                                                        >
                                                            <GalleryListItem
                                                                gallery={
                                                                    gallery
                                                                }
                                                                selected={false}
                                                                onClick={() => {
                                                                    onClickGallery(
                                                                        gallery
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>
                );
            } else {
                return "";
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper UserDetail">
                {
                    // ユーザデータが存在しない場合
                }
                {noUserFlag && (
                    <div className="no-user-page">
                        <FormattedMessage id={"msg_no_user"} />
                    </div>
                )}
                {
                    // データ読み込み後
                }
                {dataLoaded && profile != null && !noUserFlag && (
                    <>
                        {
                            // ページタイトル
                        }
                        <NavBar
                            showBack={true}
                            title={target.name == null ? "" : target.name}
                        />

                        {
                            // 基本情報エリア
                        }
                        <div className="basic-info-area">
                            <div className="title-area">
                                {getBasicInfoAreaTitleHtml()}
                            </div>
                            <div className="contents-wrapper">
                                {
                                    // プロフィール画像
                                }
                                <div className="left-pane">
                                    <img
                                        src={target.getIconUrl(props.loginUser?.id === target.id ? props.loginUser?.profileImageUrlParam : null)}
                                        alt="Profile Thumbnail"
                                    />
                                    {props.loginUser != null && !isMe &&(
                                        <>
                                            {blocked && (
                                                <Button 
                                                    className="btn-unblock"
                                                    onClick={onClickedUnblock}
                                                >
                                                    <FormattedMessage id={"btn_unblock"}/>
                                                </Button>
                                            )}
                                            {!blocked && (
                                                <Button 
                                                    className="btn-block"
                                                    onClick={onClickedBlock}
                                                >
                                                    <FormattedMessage id={"btn_block"}/>
                                                </Button>
                                            )}
                                        </>
                                    )}
                                    {!isMe && (
                                        <div className="profile-button-area">
                                            {props.loginUser != null && (
                                                <>
                                                    {
                                                        // フォローボタン
                                                    }
                                                    {getFollowButtonHtml()}
                                                    {
                                                        // 招待ボタン
                                                    }
                                                    {getInviteButtonHtml()}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {
                                    // ユーザ情報
                                }
                                <div className="right-pane">
                                    {
                                        // 名前
                                    }
                                    <div className="username-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={"user_detail_label_name"}
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {target.name}
                                        </div>
                                    </div>
                                    {
                                        // 国
                                    }
                                    <div className="country-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={"user_detail_label_country"}
                                            />
                                        </label>
                                        <div className="user-info-item-value country">
                                            <img
                                                src={
                                                    "/images/flag/" +
                                                    target.countryCd?.toUpperCase() +
                                                    ".png"
                                                }
                                                alt="Country Flag"
                                            />
                                            <div className="ms-1">
                                                {Utility.getCountryLabelKey(
                                                    target.countryCd
                                                ) !== "" && (
                                                    <FormattedMessage
                                                        id={Utility.getCountryLabelKey(
                                                            target.countryCd
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        // 地域
                                    }
                                    <div className="region-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={"user_detail_label_region"}
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {target.region}
                                        </div>
                                    </div>
                                    {
                                        // 年代
                                    }
                                    <div className="age-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={"user_detail_label_age"}
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {target.age != null &&
                                                Utility.getAgeLabelKey(
                                                    target.age!
                                                ) !== "" && (
                                                    <FormattedMessage
                                                        id={Utility.getAgeLabelKey(
                                                            target.age!
                                                        )}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                    {
                                        // 性別
                                    }
                                    <div className="gender-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={"user_detail_label_sex"}
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {Utility.getGenderLabelKey(
                                                target.gender
                                            ) !== "" && (
                                                <FormattedMessage
                                                    id={Utility.getGenderLabelKey(
                                                        target.gender
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {
                                        // 属性
                                    }
                                    <div className="attribute-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={
                                                    "user_detail_label_attribute"
                                                }
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {Utility.getAttributeLabelKey(
                                                target
                                            ) !== "" && (
                                                <FormattedMessage
                                                    id={Utility.getAttributeLabelKey(
                                                        target
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {
                                        // 最終アクセス日
                                    }
                                    <div className="last-access-area">
                                        <label className="user-info-item-label">
                                            <FormattedMessage
                                                id={
                                                    "user_detail_label_last_access"
                                                }
                                            />
                                        </label>
                                        <div className="user-info-item-value">
                                            {formattedUpdatedAt}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            // 興味・関心
                        }
                        {((profile.tagList != null &&
                            profile.tagList.length > 0) ||
                            (profile.customTagList != null &&
                            profile.customTagList.length > 0)) && (
                                <div className="interest-area">
                                    <div className="title-area">
                                        {getInterestAreaTitleHtml()}
                                    </div>
                                    {getInterestHtml()}
                                </div>
                            )}
                        {
                            // メッセージ
                        }
                        {target.profile != null &&
                            target.profile.length > 0 && (
                                <div className="message-area">
                                    <div className="title-area">
                                        {getMessageAreaTitleHtml()}
                                    </div>
                                    <div className="contents-wrapper">
                                        <Box
                                            component="div"
                                            sx={{
                                                textAlign: "left",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    target.profile == null
                                                        ? ""
                                                        : target.profile,
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        {
                            // ギャラリー
                        }
                        {profile.galleryDataList != null &&
                            profile.galleryDataList.length > 0 && (
                                <div className="gallery-area">
                                    <div className="title-area">
                                        {getGalleryAreaTitleHtml()}
                                    </div>
                                    {getGalleryHtml()}
                                </div>
                            )}
                        {
                            // ダミー
                        }
                        <div className="dummy-area"></div>
                        {
                            // ボタンエリア
                        }
                        {props.loginUser != null && !isMe && (
                            <div className="button-area">
                                {
                                    // メッセージ送信ボタン
                                }
                                <div 
                                    className="image-area message"
                                    onClick={onClickSendMessage}
                                >
                                    <img
                                        className="btn message"
                                        src={btnMessage}
                                        alt="Send Message Button"
                                    />
                                    <div className="btn-text">
                                        <FormattedMessage id="btn_message" />
                                    </div>
                                </div>
                                {
                                    // チャットボタン
                                }
                                <div 
                                    className="image-area chat"
                                    onClick={chat}
                                >
                                    <img
                                        className="btn chat"
                                        src={btnChat}
                                        alt="Chat Button"
                                    />
                                    <div className="btn-text">
                                        <FormattedMessage id="btn_chat" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {selectedGallery != null && (
                            <>
                                <ImageComponent
                                    user={props.loginUser}
                                    gallery={selectedGallery}
                                    onClose={() => {
                                        setShowImage(false);
                                    }}
                                    open={showImage}
                                />
                                <VideoComponent
                                    user={props.loginUser}
                                    gallery={selectedGallery}
                                    onClose={() => {
                                        setShowVideo(false);
                                    }}
                                    open={showVideo}
                                />
                            </>
                        )}
                        <SendMessageDialog
                            open={openSendMessageDialog}
                            close={async () => {
                                dispatch(
                                    SelectedItemAction({
                                        childMail: null,
                                        lstUser: null,
                                    })
                                );
                                setOpenSendMessageDialog(false);
                            }}
                            onSendCompleted={async () => {
                                dispatch(
                                    SelectedItemAction({
                                        childMail: null,
                                        lstUser: null,
                                    })
                                );
                                setOpenSendMessageDialog(false);
                            }}
                            loginUser={props.loginUser}
                            parentMail={undefined}
                            target={target}
                        />
                        <Dialog
                            className="dialog"
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent className="dialog-content2">
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                    color="primary"
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default UserDetail;
