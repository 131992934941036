import { AppConstants, MODE } from "constants/AppConstants";
import { TALK_TYPE } from "constants/Enum";
import { UrlConstants } from "constants/UrlConstants";
import {
    UserModel,
    TalkMessageModel,
    AppUserData,
    AttachedFileModel,
} from "models/Models";
import io from "socket.io-client";
import Utility from "utils/Utility";

export class AppServer {
    /***** 定数、変数 */
    // インスタンス
    private static _instance: AppServer;
    // ソケット
    public socket: SocketIOClient.Socket | null = null;
    // コールバック
    connectCallback: (() => void) | null = null;
    eventCallback: ((obj: object) => void) | null = null;
    disconnectCallback: (() => void) | null = null;
    user: UserModel | null = null;
    appUserData: AppUserData | null = null;

    private constructor() {}

    /** インスタンスの取得 */
    public static get instance(): AppServer {
        if (!this._instance) {
            this._instance = new AppServer();
        }
        return this._instance;
    }

    /**
     * 接続
     * @param user
     * @param connect_callback
     * @param event_callback
     * @param disconnect_callback
     */
    connect(
        user: UserModel,
        connect_callback: () => void,
        event_callback: (obj: object) => void,
        disconnect_callback: () => void
    ) {
        // Utility.log("===== AppServer connect IN");
        if (this.socket != null && this.socket.connected) {
            return;
        }
        const url =
            UrlConstants.SOCKET_APP_CONNECTION_HOST +
            ":" +
            UrlConstants.SOCKET_APP_CONNECTION_PORT;
        try {
            this.socket = io(url);
            this.connectCallback = connect_callback;
            this.eventCallback = event_callback;
            this.disconnectCallback = disconnect_callback;
            this.user = user;
            if (user != null && user.id != null) {
                const appUserData = new AppUserData();
                appUserData.id = user.id;
                this.appUserData = appUserData;
            }
            // イベント登録
            this.socket.on("connect", this.connectCallback);
            this.socket.on("disconnect", this.disconnectCallback);
            if (user.id == null) {
                throw Error();
            }
            this.socket.on("user_" + String(user.id), this.eventCallback);
        } catch (e) {
            console.error(e);
        }
    }
    /**
     * アプリオンラインサーバにユーザ情報をおくる
     */
    sendStartupInfoToAppServer() {
        // Utility.log("===== AppServer sendStartupInfoToAppServer IN");
        if (
            this.socket != null &&
            this.socket.connected &&
            this.appUserData != null &&
            this.appUserData.id != null
        ) {
            this.socket.emit("startup", this.appUserData);
        }
    }
    /**
     * アプリサーバに接続終了メッセージを送る
     */
    sendShutdownMessageToAppServer() {
        if (
            this.socket != null &&
            this.socket.connected &&
            this.appUserData != null &&
            this.appUserData.id != null
        ) {
            this.socket.emit("shutdown", this.appUserData);
        }
    }
    /**
     * アプリオンラインサーバにメッセージを送る
     * @param talkMessage
     */
    sendMessage(talkMessage: TalkMessageModel) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("send_talk_message", talkMessage);
        }
    }
    /**
     * アプリオンラインサーバにメッセージ削除通知を送る
     * @param talkMessage
     */
    sendDeleteMessageToAppServer(talkMessage: TalkMessageModel) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("delete_talk_message", talkMessage);
        }
    }
    /**
     * アプリオンラインサーバにメッセージ添付ファイル削除通知を送る
     * @param attachedFileData
     */
    sendDeleteAttachedFileToAppServer(attachedFileData: AttachedFileModel) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("delete_talk_attached_file", attachedFileData);
        }
    }
    /**
     * アプリオンラインサーバに既読通知を送る
     * @param talkMessage
     */
    sendReadNotificationToAppServer(talkMessage: TalkMessageModel) {
        if (this.socket != null && this.socket.connected) {
            this.socket.emit("read_message", talkMessage);
        }
    }

    /**
     * トーク要求者が相手の接続状況の確認を要求
     * @param senderId
     * @param receiverId
     */
    sendConnectStatusConfirmationToAppServer(
        senderId: number,
        receiverId: number
    ) {
        Utility.log("sendConnectStatusConfirmationToAppServer IN")
        if (this.socket == null) {
            Utility.log("socket is null")
            window.location.reload();
        } else {
            Utility.log("connected:" + this.socket.connected)
        }
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
            };
            this.socket.emit("confirm_connect_status", data);
        }
    }
    /**
     * 接続状況の確認要求への返答を送信
     * @param senderId
     * @param receiverId
     */
    sendResponseForConnectStatusConfirmationToAppServer(
        senderId: number,
        receiverId: number
    ) {
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
            };
            this.socket.emit("response_for_confirm_connect_status", data);
        }
    }
    /**
     * チャット相手にトーク要求を送信
     * @param senderId
     * @param receiverId
     * @param talkType
     * @returns
     */
    sendTalkCallRequest(
        senderId: number,
        receiverId: number,
        talkType: TALK_TYPE
    ) {
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
                talk_type: talkType,
            };
            this.socket.emit("request_call", data);
        }
    }
    /**
     * トーク要求のキャンセルを送信
     * @param senderId
     * @param receiverId
     * @param talkType
     * @returns
     */
    sendCancelTalkRequest(senderId: number, receiverId: number) {
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
            };
            this.socket.emit("cancel_request_call", data);
        }
    }
    /**
     * トーク要求への返信
     * @param senderId
     * @param receiverId
     * @param accepted
     * @param talkType
     * @param onTalking
     */
    sendTalkCallResponse(
        senderId: number,
        receiverId: number,
        accepted: boolean,
        talkType: TALK_TYPE,
        onTalking: boolean
    ) {
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
                accepted: accepted,
                talk_type: talkType,
                on_talking: onTalking,
            };
            this.socket.emit("response_to_call_request", data);
        }
    }
    // /**
    //  * トーク要求に対してトーク状況を返信
    //  * @param targetId
    //  * @param senderId
    //  * @param receiverId
    //  * @param senderTalkType
    //  * @param receiverTalkType
    //  */
    // replyTalkStatus(
    //     targetId: number,
    //     senderId: number,
    //     receiverId: number,
    //     senderTalkType: TALK_TYPE,
    //     receiverTalkType: TALK_TYPE
    // ) {
    //     if (this.socket != null && this.socket.connected) {
    //         const data = {
    //             target_id: targetId,
    //             sender_id: senderId,
    //             receiver_id: receiverId,
    //             sender_talk_type: senderTalkType,
    //             receiver_talk_type: receiverTalkType,
    //         };
    //         this.socket.emit("reply_talk_status", data);
    //     }
    // }
    /**
     * トーク開始
     * (トーク要求発信者から通知)
     * @param senderId
     * @param receiverId
     * @param accepted
     * @param talkType
     */
    sendTalkStartNotification(
        senderId: number,
        receiverId: number,
        userTalkType: TALK_TYPE,
        partnerTalkType: TALK_TYPE
    ) {
        Utility.log("sendTalkStartNotification IN")
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
                sender_talk_type: userTalkType,
                receiver_talk_type: partnerTalkType
            };
            this.socket.emit("talk_started", data);
        }
    }
    /**
     * トーク許可変更通知の送信
     * @param senderId
     * @param receiverId
     * @param permitted
     */
    sendTalkPermissionNotification(
        senderId: number,
        receiverId: number,
        permitted: boolean
    ) {
        Utility.log("sendTalkPermissionNotification IN")
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId,
                permitted: permitted
            };
            this.socket.emit("changed_talk_permission", data);
        }
    }
    /**
     * トーク開始失敗通知を送信
     * @param senderId
     * @param receiverId
     */
    sendTalkFailureNotification(
        senderId: number,
        receiverId: number
    ) {
        Utility.log("sendTalkFailureNotification IN")
        if (this.socket != null && this.socket.connected) {
            const data = {
                sender_id: senderId,
                receiver_id: receiverId
            };
            this.socket.emit("talk_failure_notification", data);
        }
    }
    /**
     * 切断
     * @returns
     */
    disconnect() {
        // Utility.log("===== AppServer disconnect IN");
        if (this.socket == null || this.user == null || this.user.id == null) {
            return;
        }
        this.sendShutdownMessageToAppServer();
        if (this.connectCallback != null) {
            this.socket.off("connect", this.connectCallback);
        }
        if (this.eventCallback != null) {
            this.socket.off("user_" + String(this.user.id), this.eventCallback);
        }
        if (this.disconnectCallback != null) {
            this.socket.off("disconnect", this.disconnectCallback);
        }
        this.socket.disconnect();
        this.socket = null;
    }
}
