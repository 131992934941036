import React from "react";
import { FormattedMessage } from "react-intl";
import {
    Link
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDeviceType } from "redux/Selectors";
import { UserModel } from "models/Models";
import { pushDataLayer } from "gtm/gtm"
import BgTop1 from "assets/images/bg_top_1.webp";
import BgTop2 from "assets/images/bg_top_2.webp";
import BgTop3 from "assets/images/bg_top_3.webp";

import "styles/pages/Top.scss";

type Props = {
    loginUser: UserModel | null;
};

const Top: React.FC<Props> = React.memo(
    (props) => {
        /***** 定数、変数 */
        const deviceType: number | null = useSelector((state) => getDeviceType(state));

        /***** useRef */

        /***** useState */

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "トップ",
                path: window.location.pathname,
                deviceType: deviceType,
            })            
        }, []);
        React.useEffect(() => {
            initialProcess();

            return () => {
            }
        }, [props.loginUser]);

        /**
         * 初期処理
         */
        async function initialProcess() {
        }


        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper Top">
                {props.loginUser == null && (
                    <Link
                        className="start-free"
                        component={RouterLink}
                        to={"/register"}
                    >
                        <FormattedMessage id="start_free" />
                    </Link>
                )}

                <section className="hero">
                    <div className="image-area">
                        <img className="hero1" src={BgTop1} alt="" />
                        <img className="hero2" src={BgTop2} alt="" />
                        <img className="hero3" src={BgTop3} alt="" />
                    </div>
                    <div className="catch-phrase">
                        <div className="catch-phrase-1">
                            <FormattedMessage id="top_catch_phrase_1" />
                        </div>
                        <div className="catch-phrase-2">
                            <FormattedMessage id="top_catch_phrase_2" />
                        </div>
                    </div>
                </section>
                <section className="concept-area">
                    <FormattedMessage id="concept_phrase" />
                </section>
                <section className="feature-area">
                    <h1><FormattedMessage id="section_title_1"/></h1>
                    <div className="section-contents">
                        <article>
                            <h2><FormattedMessage id="feature_title_user"/></h2>
                            <p>
                                <FormattedMessage 
                                    id="feature_body_user" 
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </p>
                        </article>
                        <article>
                            <h2><FormattedMessage id="feature_title_group"/></h2>
                            <p>
                                <FormattedMessage 
                                    id="feature_body_group"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </p>
                        </article>
                        <article>
                            <h2><FormattedMessage id="feature_title_board"/></h2>
                            <p>
                                <FormattedMessage 
                                    id="feature_body_board"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </p>
                        </article>
                        <article>
                            <h2><FormattedMessage id="feature_title_chat"/></h2>
                            <p>
                                <FormattedMessage 
                                    id="feature_body_chat"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </p>
                        </article>
                        <article>
                            <h2><FormattedMessage id="feature_title_mail"/></h2>
                            <p>
                                <FormattedMessage 
                                    id="feature_body_mail"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </p>
                        </article>
                    </div>
                </section>
                <section className="howto-area">
                    <h1><FormattedMessage id="section_title_2"/></h1>
                    <div className="section-contents">
                        <article className="howto howto-1">
                            <h2>
                                <span className="number">1</span>
                                <span className="howto-title">
                                    <FormattedMessage id="howto_title_1" />
                                </span>
                            </h2>
                            <div className="howto-detail">
                                <FormattedMessage 
                                    id="howto_body_1"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </div>
                        </article>
                        <article className="howto howto-2">
                            <h2>
                                <span className="number">2</span>
                                <span className="howto-title">
                                    <FormattedMessage id="howto_title_2" />
                                </span>
                            </h2>
                            <div className="howto-detail">
                                <FormattedMessage 
                                    id="howto_body_2"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </div>
                        </article>
                        <article className="howto howto-3">
                            <h2>
                                <span className="number">3</span>
                                <span className="howto-title">
                                    <FormattedMessage id="howto_title_3" />
                                </span>
                            </h2>
                            <div className="howto-detail">
                                <FormattedMessage 
                                    id="howto_body_3"
                                    values={{
                                        html_br: <br/>
                                    }}
                                />
                            </div>
                        </article>
                    </div>
                </section>
                <section className="security-area">
                    <h1><FormattedMessage id="section_title_3"/></h1>
                    <ul className="section-contents-list">
                        <li>
                            <FormattedMessage id="security_1"/>
                        </li>
                        <li>
                            <FormattedMessage id="security_2"/>
                        </li>
                        <li>
                            <FormattedMessage id="security_3"/>
                        </li>
                    </ul>
                </section>
                <section className="engagement-area">
                    <h1><FormattedMessage id="section_title_4"/></h1>
                    <ul className="section-contents-list">
                        <li>
                            <FormattedMessage id="engagement_1"/>
                        </li>
                        <li>
                            <FormattedMessage id="engagement_2"/>
                        </li>
                        <li>
                            <FormattedMessage id="engagement_3"/>
                        </li>
                    </ul>
                </section>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default Top;
