import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Box, Divider, Button } from "@mui/material";
import { UserModel, MailModel, ParentMailModel } from "models/Models";
import { DbConstants } from "constants/Constants";
import { AttachedFileListComponent } from "components/Components";
import Utility from "utils/Utility";
import "styles/components/listItems/ReceivedChildMailListItem.scss";

type Props = {
    loginUser: UserModel | null;
    parentMail: ParentMailModel | undefined;
    mail: MailModel;
    sender: UserModel | undefined;
    receiver: UserModel | undefined;
    timeDifference: number;
    langCd: string;
    deviceType: number;
    onRead: () => void;
    onDelete: () => void;
};

type MailLine = {
    isJsx: boolean;
    isUser?: boolean;
    userId?: number;
    userName?: string;
    isGroup?: boolean;
    groupId?: number;
    groupName?: string;
    text?: string;
}

export const ReceivedChildMailListItem: React.FC<Props> = React.memo((props) => {
    Utility.log("===== ReceivedChildMailListItem IN");
    /***** 定数、変数 */
    /***** useState */
    const [lstMailLine, setMailLineList] = React.useState<MailLine[]>();
    const [createdAt, setCreatedAt] = React.useState<string>("");

    const replaceUserName = React.useCallback((
        message: string,
        userId: number,
        userName: string
    ): MailLine[] => {
        const lineList: MailLine[] = [];
        const splitter = "[" + userName + "]";
        const array = message.split(splitter);
        for (let i=0; i<array.length; i++) {
            const text = array[i];
            if (i === 0 && text.length === 0) {
                const line: MailLine = {
                    isJsx: true,
                    isUser: true,
                    userId: userId,
                    userName: userName
                };
                lineList.push(line);
            } else {
                const line1: MailLine = {
                    isJsx: false,
                    text: text
                };
                lineList.push(line1);
                const line2: MailLine = {
                    isJsx: true,
                    isUser: true,
                    userId: userId,
                    userName: userName
                };
                lineList.push(line2);
            }
        }
        lineList.pop();
        return lineList;
    }, []);

    const replaceGroupName = React.useCallback((
        message: string,
        groupId: number,
        groupName: string
    ): MailLine[] => {
        const lineList: MailLine[] = [];
        const splitter = "[" + groupName + "]";
        const array = message.split(splitter);
        for (let i=0; i<array.length; i++) {
            const text = array[i];
            if (i === 0 && text.length === 0) {
                const line: MailLine = {
                    isJsx: true,
                    isGroup: true,
                    groupId: groupId,
                    groupName: groupName
                };
                lineList.push(line);
            } else {
                const line1: MailLine = {
                    isJsx: false,
                    text: text
                };
                lineList.push(line1);
                const line2: MailLine = {
                    isJsx: true,
                    isGroup: true,
                    groupId: groupId,
                    groupName: groupName
                };
                lineList.push(line2);
            }
        }
        lineList.pop();
        return lineList;
    }, []);

    const buildMailBody = React.useCallback(() => {
        let wkLine: MailLine[] = [];
        if (props.mail == null || props.mail.message == null) {
            return;
        }
        let message = Utility.escape(props.mail.message).replaceAll(
            "\n",
            "<br />"
        );
        const linkInfo = props.mail.linkInfo2;
        if (linkInfo == null) {
            message = Utility.replaceUrlsWithAnchors(props.deviceType, message);
            const line: MailLine = {
                isJsx: false,
                text: message
            };
            wkLine.push(line);
            setMailLineList(wkLine);
            return;
        }
        const userId = linkInfo.userId;
        const userName = linkInfo.userName;
        const groupId = linkInfo.groupId;
        const groupName = linkInfo.groupName;

        // ユーザ名置換
        if (
            userName != null &&
            userName.length > 0 &&
            userId != null &&
            userId > 0
        ) {
            const arrayUser = replaceUserName(
                message,
                userId,
                userName
            );
            // グループ名置換
            if (
                groupName != null &&
                groupName.length > 0 &&
                groupId != null &&
                groupId > 0
            ) {
                for (let i=0; i<arrayUser.length; i++) {
                    const mailLineUser = arrayUser[i];
                    if (mailLineUser.isJsx) {
                        wkLine.push(mailLineUser);
                    } else {
                        const text = mailLineUser.text;
                        if (text != null) {
                            const arrayGroup = replaceGroupName(
                                text,
                                groupId,
                                groupName
                            );
                            wkLine.push(...arrayGroup);
                        }
                    }
    
                }
            } else {
                wkLine = arrayUser;
            }            
        } else {
            // グループ名置換
            if (
                groupName != null &&
                groupName.length > 0 &&
                groupId != null &&
                groupId > 0
            ) {
                wkLine = replaceGroupName(
                    message,
                    groupId,
                    groupName
                );
            } else {
                const line: MailLine = {
                    isJsx: false,
                    text: message
                };
                wkLine.push(line);    
            }
        }        
        setMailLineList(wkLine);
    }, [
        props.mail,
        props.deviceType,
        replaceUserName,
        replaceGroupName,
    ]);

    React.useEffect(() => {
        if (
            props.mail != null &&
            props.mail.createdAt != null &&
            props.timeDifference != null &&
            props.langCd != null
        ) {
            const unixTime = new Date(props.mail.createdAt).getTime() / 1000;
            const wkCreatedAt = Utility.getFormattedDateTime2(
                unixTime,
                props.timeDifference,
                props.langCd
            );
            setCreatedAt(wkCreatedAt);
            buildMailBody();
        }
    }, [
        props.langCd,
        props.mail,
        props.timeDifference,
        buildMailBody,
    ]);

    /**
     * メールメッセージ組み立て
     * @returns
     */
    // function getMailMessage(): string {
    //     if (props.mail == null || props.mail.message == null) {
    //         return "";
    //     }
    //     let message = Utility.escape(props.mail.message).replaceAll(
    //         "\n",
    //         "<br />"
    //     );
    //     const linkInfo = props.mail.linkInfo2;
    //     if (linkInfo == null) {
    //         return Utility.replaceUrlsWithAnchors(props.deviceType, message);
    //     } else {
    //         const userId = linkInfo.userId;
    //         const userName = linkInfo.userName;
    //         const groupId = linkInfo.groupId;
    //         const groupName = linkInfo.groupName;
    //         const urlList = linkInfo.urlList;

    //         // ユーザ名置換
    //         if (
    //             userName != null &&
    //             userName.length > 0 &&
    //             userId != null &&
    //             userId > 0
    //         ) {
    //             message = message.replaceAll(
    //                 "[" + userName + "]",
    //                 `[<a target="_blank" href="/users/012-39111508-${userId}/profile">${userName}</a>]`
    //             );
    //         }
    //         // グループ名置換
    //         if (
    //             groupName != null &&
    //             groupName.length > 0 &&
    //             groupId != null &&
    //             groupId > 0
    //         ) {
    //             message = message.replaceAll(
    //                 "[" + groupName + "]",
    //                 `[<a target="_blank" href="/groups/${groupId}">${groupName}</a>]`
    //             );
    //         }
    //         // URL置換
    //         if (urlList != null && urlList.length > 0) {
    //             const lstUrl = urlList.split(",");
    //             for (let i = 0; i < lstUrl.length; i++) {
    //                 let url = lstUrl[i];
    //                 message = message.replaceAll(
    //                     url,
    //                     `<a href="${url}" target="_blank">${url}</a>`
    //                 );
    //             }
    //         }
    //         return message;
    //     }
    // }

    function getClassName() {
        if (!props.loginUser) {
            return;
        }
        let className = "component ReceivedChildMailListItem";
        if (props.loginUser.id !== props.mail.receiverId) {
            className = className + " sent-mail";
        } else {
            if (props.mail.read !== 1) {
                className = className + " unread";
            }
        }
        return className;
    }
    /**
     * レンダー
     */
    return (
        <>
            {props.loginUser != null &&
                props.parentMail != null &&
                props.mail.sender != null &&
                props.mail.receiver != null && (
                    <Box
                        className={getClassName()}
                    >
                        <Box className="wrapper">
                            {
                                // 送信者名、作成日時
                            }
                            <Box className="row-1">
                                {props.sender != null && (
                                    <>
                                        <Box className="receiver">
                                            {props.sender.id !== 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    <Link to={`/users/${Utility.getUserKey(props.sender)}/profile`}>
                                                        {props.mail.sender.name}
                                                    </Link>
                                                </>
                                            )}
                                            {props.sender.id === 1 && (
                                                <>
                                                    <span>From:&nbsp;</span>
                                                    {props.sender.name}
                                                </>
                                            )}
                                        </Box>
                                        <Box className="date">
                                            {createdAt}
                                        </Box>
                                    </>
                                )}
                            </Box>
                            {
                                // 受信者名
                            }
                            <Box className="row-2">
                                To:{" " + props.mail.receiver.name}
                            </Box>
                            {
                                // タイトル
                            }
                            <Box className="row-3">
                                {props.mail.title}
                            </Box>
                            <Divider className="divider"/>
                            {
                                // 本文
                            }
                            {/* <Box className="row-4"
                                dangerouslySetInnerHTML={{
                                    __html: getMailMessage(),
                                }}
                            ></Box> */}
                            <Box className="row-4">
                                {lstMailLine != null && lstMailLine.map((line: MailLine, index: number) => {
                                    if (line.isJsx) {
                                        if (line.isUser) {
                                            if (props.deviceType === DbConstants.DEVICE_TYPE_BROWSER) {
                                                return (
                                                    [<a key={index} target="_blank" href={`/users/012-39111508-${line.userId}/profile`} rel="noreferrer">{line.userName}</a>]
                                                )
                                            } else {
                                                return (
                                                    <Link key={index} to={`/users/012-39111508-${line.userId}/profile`}>{line.userName}</Link>
                                                )
                                            }
                                        } else if (line.isGroup) {
                                            if (props.deviceType === DbConstants.DEVICE_TYPE_BROWSER) {
                                                return (
                                                    [<a key={index} target="_blank" href={`/groups/${line.groupId}`} rel="noreferrer">[{line.groupName}]</a>]
                                                )
                                            } else {
                                                return (
                                                    <Link key={index} to={`/groups/${line.groupId}`}>[{line.groupName}]</Link>
                                                )
                                            }
                                        } else {
                                            return <></>
                                        }
                                    } else {
                                        if (line.text == null) {
                                            return <React.Fragment key={index}></React.Fragment>
                                        } else {
                                            return (
                                                <span key={index} dangerouslySetInnerHTML={{
                                                    __html: line.text,
                                                }}/>
                    
                                            )
                                        }
                                    }
                                })}
                            </Box>
                            {
                                // 添付ファイル
                            }
                            {props.mail.existsAttachedFiles === 1 &&
                                props.mail.lstAttachedFile != null && (
                                    <AttachedFileListComponent
                                        loginUser={props.loginUser}
                                        lstAttachedFile={
                                            props.mail.lstAttachedFile
                                        }
                                    />
                                )}
                            {
                                // ボタンエリア
                            }
                            {props.loginUser.id === props.mail.receiverId && (
                                <Box className="button-area">
                                    <Button className="btn-delete"
                                        variant="text"
                                        onClick={props.onDelete}
                                    >
                                        <FormattedMessage id={"btn_delete"} />
                                    </Button>
                                    {props.loginUser.id ===
                                        props.mail.receiverId &&
                                        props.mail.read !== 1 && (
                                            <Button className="btn-read"
                                                variant="text"
                                                onClick={props.onRead}
                                            >
                                                <FormattedMessage
                                                    id={"btn_read"}
                                                />
                                            </Button>
                                        )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
        </>
    );
},
(prevProps: Props, nextProps: Props) => {
    if (prevProps.loginUser !== nextProps.loginUser) {
        return false;
    }
    if (prevProps.parentMail !== nextProps.parentMail) {
        return false;
    }
    if (prevProps.parentMail != null && nextProps.parentMail != null) {
        if (prevProps.parentMail.id !== nextProps.parentMail.id) {
            return false;
        }
    }
    if (prevProps.mail !== nextProps.mail) {
        return false;
    }
    if (prevProps.mail != null && nextProps.mail != null) {
        if (prevProps.mail.id !== nextProps.mail.id) {
            return false;
        }
    }
    if (prevProps.sender != null && nextProps.sender != null) {
        if (prevProps.sender.id !== nextProps.sender.id) {
            return false;
        }
    }
    if (prevProps.receiver != null && nextProps.receiver != null) {
        if (prevProps.receiver.id !== nextProps.receiver.id) {
            return false;
        }
    }
    if (prevProps.langCd !== nextProps.langCd) {
        return false;
    }
    if (prevProps.onRead !== nextProps.onRead) {
        return false;
    }
    if (prevProps.onDelete !== nextProps.onDelete) {
        return false;
    }

    return true;
});
