import axios from "axios";
// import * as https from 'https';
import { UrlConstants } from "constants/UrlConstants";
import { SearchUserCondition } from "models/SearchUserCondition";
import {
    UserListResponse,
    UserProfileResponse,
    LoginResponse,
    AccessTokenResponse,
    LogoutResponse,
    UserSaveResponse,
    MyDataResponse,
    UserAlbumListResponse,
    FollowResponse,
    UnfollowResponse,
    UserResponse,
    IsBlockedResponse,
    FollowUserListResponse,
    FollowerListResponse,
    BlockResponse,
    DeleteAccountResponse,
    ResetPasswordResponse,
    RequestResetPasswordResponse,
    SkyWayTokenResponse,
} from "api/responses/Responses";
import { RegisterData, UserModel } from "models/Models";
import { DbConstants } from "constants/DbConstants";

// const httpsAgent = new https.Agent({
//     rejectUnauthorized: false,
// });
// axios.defaults.httpsAgent = httpsAgent

export class UserRequest {
    /**
     * ACCESS TOKEN取得
     * @returns
     */
    static async getAccessToken() {
        // const httpsAgent = new https.Agent({ rejectUnauthorized: false });
        // axios.defaults.httpsAgent = httpsAgent;
        const url = UrlConstants.URL_GET_ACCESS_TOKEN + "?time=" + new Date().getTime();
        let result = null;
        try {
            let res = await axios.get(url, { withCredentials: true });
            result = new AccessTokenResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * SKYWAY TOKEN取得
     * @returns
     */
    static async getSkyWayToken(
        user: UserModel,
        partnerId: number,
        channelName: string
    ) {
        if (user.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.URL_GET_SKYWAY_TOKEN;
        url = url.replace(":id", String(user.id));
        url = url + "?partner_id=" + String(partnerId);
        url = url + "&channel_name=" + channelName;

        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new SkyWayTokenResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ACCESS TOKENリフレッシュ
     * @returns
     */
    static async refreshAccessToken() {
        const url = UrlConstants.URL_REFRESH_ACCESS_TOKEN;
        let result = null;
        try {
            let res = await axios.get(url, { withCredentials: true });
            result = new AccessTokenResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * 言語変更
     * @returns
     */
    static async changeLanguage(
        userId: number,
        langCd: string,
        bearerToken: string
    ) {
        let formData = new FormData();
        formData.append("user_id", String(userId));
        formData.append("language_cd", langCd);
        const url = UrlConstants.URL_CHANGE_LANGUAGE;
        let result = null;
        try {
            const res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                },
            });
            if (res != null) {
                result = new UserResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * 新規登録
     * @param email
     * @param password
     * @returns
     */
    static async register(data: RegisterData) {
        const url = UrlConstants.V5_URL_REGISTRATION;

        const formData = new FormData();
        formData.append("ActionKey", data.actionKey);
        formData.append("AuthType", `${data.authType}`);
        formData.append("DeviceType", `${data.deviceType}`);
        formData.append("Email", data.email);
        formData.append("Password", data.password);
        formData.append("Name", data.userName);
        formData.append("Gender", `${data.gender}`);
        formData.append("Age", `${data.age}`);
        formData.append("Attribute", `${data.attribute}`);
        if (data.region != null && data.region.length > 0) {
            formData.append("Region", data.region);
        }
        if (data.lstTag != null && data.lstTag.length > 0) {
            formData.append("TagList", data.lstTag.join(","));
        }
        if (data.lstCustomTag != null && data.lstCustomTag.length > 0) {
            formData.append("CustomTagList", data.lstCustomTag.join(","));
        }
        if (data.countryCode != null) {
            formData.append("country", data.countryCode);
        }
        if (data.languageCode != null) {
            formData.append("language", data.languageCode);
        }
        if (data.profileImage != null) {
            formData.append("attachedImageFiles", data.profileImage);
        }
        if (data.profile != null) {
            formData.append("Profile", data.profile);
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, { withCredentials: true });
            result = new LoginResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * 更新
     * @param update_user
     * @returns
     */
    static async save(update_user: UserModel) {
        if (
            update_user.id == null ||
            update_user.createdAt == null ||
            update_user.bearerToken == null
        ) {
            return null;
        }
        const url = UrlConstants.V5_URL_SAVE_USER.replace(
            ":id",
            String(update_user.id)
        );

        const formData = new FormData();
        formData.append("Id", String(update_user.id));
        formData.append("CreatedAt", update_user.createdAt);
        
        // ユーザ名
        if (update_user.name != null && update_user.name.length > 0) {
            formData.append("Name", update_user.name);
        }
        // デバイスタイプ
        if (update_user.deviceType != null) {
            formData.append("DeviceType", String(update_user.deviceType));
        }
        // デバイストークン
        if (update_user.deviceToken != null) {
            formData.append("DeviceToken", update_user.deviceToken);
        }
        // メジャーバージョン
        if (update_user.majorVersion != null) {
            formData.append("MajorVersion", String(update_user.majorVersion));
        }
        // マイナーバージョン
        if (update_user.minorVersion != null) {
            formData.append("MinorVersion", String(update_user.minorVersion));
        }
        // ビルド番号
        if (update_user.buildNo != null) {
            formData.append("BuildNo", String(update_user.buildNo));
        }
        // 国コード
        if (update_user.countryCd != null && update_user.countryCd.length > 0) {
            formData.append("CountryCd", update_user.countryCd);
        }
        // 言語コード
        if (
            update_user.languageCd != null &&
            update_user.languageCd.length > 0
        ) {
            formData.append("LanguageCd", update_user.languageCd);
        }
        // 性別
        if (update_user.gender != null) {
            formData.append("Gender", String(update_user.gender));
        }
        // 地域
        if (update_user.region != null && update_user.region.length > 0) {
            formData.append("Region", update_user.region);
        }
        // 年齢
        if (update_user.age != null) {
            formData.append("Age", String(update_user.age));
        }
        // プロフィール
        if (update_user.profile != null) {
            formData.append("Profile", update_user.profile);
        }
        // プロフィール画像
        if (update_user.profileImage != null) {
            formData.append("Icon", update_user.id + ".png");
            formData.append("profileIconImageFile", update_user.profileImage);
        }
        // 属性
        if (update_user.attribute != null) {
            formData.append("Attribute", String(update_user.attribute));
        }
        // プラン
        if (update_user.plan != null) {
            formData.append("Plan", String(update_user.plan));
        }
        // タグ
        if (update_user.lstSelectedTag != null) {
            let lstTagId: string[] = [];
            update_user.lstSelectedTag.forEach((tag) => {
                if (tag.id != null) {
                    lstTagId.push(String(tag.id));
                }
            });
            formData.append("TagList", lstTagId.join(","));
        } else {
            formData.append("TagList", "skip");
        }
        // カスタムタグ
        if (update_user.lstCustomTag != null) {
            let lstCustomTagName: string[] = [];
            update_user.lstCustomTag.forEach((customTag) => {
                if (customTag.name != null) {
                    lstCustomTagName.push(customTag.name);
                }
            });
            formData.append("CustomTagList", lstCustomTagName.join(","));
        } else {
            formData.append("CustomTagList", "skip");
        }
        // 通知
        if (update_user.notification != null) {
            formData.append("Notification", String(update_user.notification));
        }
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${update_user.bearerToken}`,
                },
            });
            result = new UserSaveResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ログイン
     * @param email
     * @param password
     * @param deviceType
     * @param deviceToken
     * @returns
     */
    static async login(
        email: string, 
        password: string, 
        deviceType: number | null,
        deviceToken: string | null,
        majorVersion: number | null,
        minorVersion: number | null,
        buildNo: number | null
    ) {
        const url = UrlConstants.V2_URL_LOGIN;
        const data = new FormData();
        data.append("AuthType", String(DbConstants.AUTH_TYPE_APP));
        data.append("Email", email);
        data.append("Password", password);
        if (deviceType != null) {
            data.append("DeviceType", String(deviceType));
        }
        if (deviceToken != null) {
            data.append("DeviceToken", deviceToken);
        }
        if (majorVersion != null) {
            data.append("MajorVersion", String(majorVersion));
        }
        if (minorVersion != null) {
            data.append("MinorVersion", String(minorVersion));
        }
        if (buildNo != null) {
            data.append("BuildNo", String(buildNo));
        }
        let result = null;
        try {
            let res = await axios.put(url, data, { withCredentials: true });
            result = new LoginResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    static async loginWithGoogle(
        clientId: string, 
        credential: string, 
        deviceType: number | null,
        deviceToken: string | null,
        majorVersion: number | null,
        minorVersion: number | null,
        buildNo: number | null
    ) {
        const url = UrlConstants.V2_URL_LOGIN;
        const data = new FormData();
        data.append("AuthType", String(DbConstants.AUTH_TYPE_GOOGLE));
        data.append("ClientId", clientId);
        data.append("Credential", credential);
        if (deviceType != null) {
            data.append("DeviceType", String(deviceType));
        }
        if (deviceToken != null) {
            data.append("DeviceToken", deviceToken);
        }
        if (majorVersion != null) {
            data.append("MajorVersion", String(majorVersion));
        }
        if (minorVersion != null) {
            data.append("MinorVersion", String(minorVersion));
        }
        if (buildNo != null) {
            data.append("BuildNo", String(buildNo));
        }
        let result = null;
        try {
            let res = await axios.put(url, data, { withCredentials: true });
            result = new LoginResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * パスワードのリセット要求
     * @param email
     */
    static async requestResetPassword(email: string) {
        const url = UrlConstants.URL_REQUEST_PASSWORD_RESET;
        const data = new FormData();
        data.append("email", email);
        let result = null;
        try {
            let res = await axios.put(url, data, { withCredentials: true });
            result = new RequestResetPasswordResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * パスワードのリセット
     * @param resetKey
     * @param password
     */
    static async resetPassword(resetKey: string, password: string) {
        const url = UrlConstants.URL_RESET_PASSWORD;
        const data = new FormData();
        data.append("resetKey", resetKey);
        data.append("password", password);
        let result = null;
        try {
            let res = await axios.put(url, data, { withCredentials: true });
            result = new ResetPasswordResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ログアウト
     * @returns
     */
    static async logout() {
        const url = UrlConstants.URL_LOGOUT;
        let result = null;
        try {
            let res = await axios.put(url, null, { withCredentials: true });
            result = new LogoutResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ユーザリスト取得
     * @param user_id
     * @param index
     * @returns
     */
    static async list(user_id: number, index: number) {
        let url =
            UrlConstants.V3_URL_USER_LIST +
            "?countryCd=99&device_type=3&userId=" +
            user_id +
            "&index=" +
            index;
        let data = null;
        try {
            let res = await axios.get(url);
            data = new UserListResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return data;
    }

    /**
     * ユーザリスト検索
     * @param user_id
     * @param condition
     * @param index
     */
    static async search(condition: SearchUserCondition) {
        let url = UrlConstants.V3_URL_USER_SEARCH;
        // インデックス
        if (condition.index == null) {
            url = url + "?index=0";
        } else {
            url = url + "?index=" + condition.index;
        }
        // ユーザ名
        if (condition.name != null) {
            url = url + "&Name=" + condition.name;
        }
        // 性別
        if (condition.gender != null) {
            url = url + "&Gender=" + condition.gender;
        }
        // エリア
        if (condition.region != null) {
            url = url + "&Region=" + condition.region;
        }
        // 年代
        if (condition.age != null) {
            url = url + "&Age=" + condition.age;
        }
        // 属性
        if (condition.attribute != null) {
            url = url + "&Attribute=" + condition.attribute;
        }
        // 国
        if (condition.countryCd != null) {
            url = url + "&CountryCd=" + condition.countryCd;
        }
        // タグ
        if (condition.lstTagId != null) {
            url = url + "&TagList=" + condition.lstTagId.join(",");
        }
        // ユーザID
        if (condition.userId != null) {
            url = url + "&userId=" + condition.userId;
        }
        let data = null;
        try {
            let res = await axios.get(url);
            data = new UserListResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return data;
    }

    /**
     * マイデータ取得
     * @param user
     * @returns
     */
    static async getMyData(user: UserModel) {
        if (user == null || user.id == null || user.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.V3_URL_MY_DATA;
        url = url.replace(":id", String(user.id));
        url = url + "?id=" + String(user.id);
        let data: MyDataResponse | null = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            data = new MyDataResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return data;
    }
    /**
     * ユーザ取得
     * @param target_id
     * @returns
     */
    static async getUser(target_id: number) {
        const url = UrlConstants.URL_USER_DETAIL.replace(
            ":id",
            String(target_id)
        );
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
            });
            result = new UserResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ユーザプロフィール取得
     * @param user_id
     * @param target_id
     * @param lang
     * @returns
     */
    static async getProfile(
        user_id: number,
        target_id: number,
        lang: string,
        timeDifference: number
    ) {
        let url =
            UrlConstants.V2_URL_USER_PROFILE +
            "?device_type=3&lang=" +
            lang +
            "&time_difference=" +
            timeDifference.toString() +
            "&user_id=" +
            user_id.toString() +
            "&target_id=" +
            target_id.toString();
        let res = await axios.get(url);
        let data = new UserProfileResponse(res.data);
        return data;
    }
    /**
     * ユーザアルバムリスト取得
     * @param user
     * @returns
     */
    static async getUserAlbumList(user: UserModel) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        const url = UrlConstants.URL_USER_ALBUM_LIST.replace(
            ":id",
            String(user.id)
        );
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new UserAlbumListResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * フォロー
     * @param user
     * @param target_id
     * @returns
     */
    static async follow(user: UserModel, target_id: number) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        const url = UrlConstants.URL_FOLLOW_USER.replace(
            ":id",
            String(user.id)
        );
        let formData = new FormData();
        formData.append("targetId", String(target_id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new FollowResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * フォロー解除
     * @param user
     * @param target_id
     * @returns
     */
    static async unfollow(user: UserModel, target_id: number) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        const url = UrlConstants.URL_CANCEL_FOLLOW_USER.replace(
            ":id",
            String(user.id)
        );
        let formData = new FormData();
        formData.append("targetId", String(target_id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new UnfollowResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
    /**
     * ブロックされているかどうか
     * @param user
     * @param target_id
     * @returns
     */
    static async isBlocked(user: UserModel, target_id: number) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.URL_USER_IS_BLOCKED.replace(
            ":id",
            String(user.id)
        );
        url = url + "?target_user_id=" + String(target_id);
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new IsBlockedResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * フォロー中のユーザリスト取得
     * @param user
     * @returns
     */
    static async getFollowUserList(user: UserModel) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.V2_URL_FOLLOW_USER_LIST.replace(
            ":id",
            String(user.id)
        );
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new FollowUserListResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * フォロワーリスト取得
     * @param user
     * @returns
     */
    static async getFollowerList(user: UserModel) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        let url = UrlConstants.V2_URL_FOLLOWER_LIST.replace(
            ":id",
            String(user.id)
        );
        let result = null;
        try {
            let res = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new FollowerListResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ブロック
     * @param user
     * @param target_id
     * @returns
     */
    static async block(user: UserModel, target_id: number) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        const url = UrlConstants.URL_BLOCK_USER.replace(":id", String(user.id));
        let formData = new FormData();
        formData.append("target_user_id", String(target_id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new BlockResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * ブロック解除
     * @param user
     * @param target_id
     * @returns
     */
    static async unblock(user: UserModel, target_id: number) {
        if (user.id == null || user.bearerToken == null) {
            return null;
        }
        const url = UrlConstants.URL_UNBLOCK_USER.replace(
            ":id",
            String(user.id)
        );
        let formData = new FormData();
        formData.append("target_user_id", String(target_id));
        let result = null;
        try {
            let res = await axios.put(url, formData, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new BlockResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    /**
     * アカウント削除
     * @param user
     * @param email
     * @returns
     */
    static async deleteAccount(user: UserModel, email: string) {
        if (
            user.id == null ||
            user.bearerToken == null ||
            user.createdAt == null
        ) {
            return null;
        }

        const url = UrlConstants.URL_DELETE_ACCOUNT.replace(
            ":id",
            String(user.id)
        );
        const data = new FormData();
        data.append("Email", email);
        data.append("CreatedAt", user.createdAt);
        let result = null;
        try {
            let res = await axios.put(url, data, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${user.bearerToken}`,
                },
            });
            result = new DeleteAccountResponse(res.data);
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
