import React, { ChangeEvent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    Divider,
    IconButton,
} from "@mui/material";
import AttachFile from "@mui/icons-material/AttachFile";
import SendButton from "@mui/icons-material/Send";
import { EventSubscription } from "fbemitter";
import { UserModel, TalkUserModel, TalkMessageModel } from "models/Models";
import {
    getTimeDifference,
    getLanguageCode,
    getEmitter,
    getDeviceType,
} from "redux/Selectors";
import {
    ProcessAction,
    DecrementTalkUnreadCountAction,
    NetworkAction,
} from "redux/Actions";

import {
    UserChatListItemComponent,
    AttachedFileNameComponent,
    ImageComponent,
    VideoComponent,
} from "components/Components";
import Utility from "utils/Utility";
import {
    TalkMessageRequest,
    TalkUserRequest,
    UserRequest,
} from "api/requests/Requests";
import { TALK_TYPE } from "constants/Enum";
import { AppServer } from "socket/AppServer";
import { DbConstants } from "constants/Constants";
import { pushDataLayer } from "gtm/gtm"
import "styles/components/UserChatComponent.scss";

type Props = {
    loginUser: UserModel;
    talkUser: TalkUserModel | null | undefined;
};
type SelectedFile = {
    name: string;
    file: File;
};

const UserChat: React.FC<Props> = (props, ref) => {
    Utility.log("===== UserChatComponent IN");

    /***** 定数、変数 */
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const deviceType: number | null = useSelector((state) => getDeviceType(state));
    const langCd = useSelector((state) => getLanguageCode(state));
    // 時差
    const timeDifference = useSelector((state) => getTimeDifference(state));
    // EventEmitter
    const emitter = useSelector((state) => getEmitter(state));

    /***** useRef */
    // EventEmitterの購読
    const lstSubscription = React.useRef<EventSubscription[]>();
    // チャットサーバー
    const appServer = React.useRef<AppServer>(AppServer.instance);
    // ブロックされているかどうか
    const isBlocked = React.useRef<boolean>();
    // 処理中フラグ
    const isUnderProcess = React.useRef(false);
    // 読み込みサイズ
    const readSize = React.useRef<number>(100);
    // 読み込みインデックス
    const currentIndex = React.useRef<number>(0);
    // 次レコード有無
    const hasNext = React.useRef<boolean>(true);
    // 読込中フラグ
    const nowFetching = React.useRef<boolean>(false);
    // タイマーID
    const timeoutId = React.useRef<number>(0);
    // 添付ファイル
    const refAttachFile = React.useRef<HTMLInputElement>(null);
    // チャット表示エリア
    const refChatArea = React.useRef<HTMLDivElement>();
    // チャットメッセージリスト参照(登録したEventListener内処理のため)
    const refTalkMessageList = React.useRef<TalkMessageModel[]>();
    // パートナー参照(登録したEventListener内処理のため)
    const refPartner = React.useRef<UserModel>();
    // 選択トークメッセージ(削除用)
    const refSelectedTalkMessage = React.useRef<TalkMessageModel>();
    // トークメッセージリスト更新中
    const refIsUnderUpdaring = React.useRef<boolean>(false);
    // 既読通知を送信したトークメッセージIDリスト
    const refSentReadMessageIdList = React.useRef<number[]>();
    // 通話タイプ
    const refTalkType = React.useRef<TALK_TYPE>();

    /***** useState */
    // トークパートナー
    const [partner, setPartner] = React.useState<UserModel>();
    // 入力チャットメッセージ
    const [message, setMessage] = React.useState<string>("");
    // チャットメッセージリスト
    const [lstTalkMessage, setTalkMessageList] =
        React.useState<TalkMessageModel[]>();
    // ボタンラベル
    const [btnSendLabel, setBtnSendLabel] = React.useState<string>("");
    // ボタン活性化フラグ
    const [btnSendDisabled, setBtnSendDisabled] = React.useState<boolean>(true);
    // 添付ファイル
    const [attachedFileList, setAttachedFileList] =
        React.useState<SelectedFile[]>();
    // 全画面表示用に選択された添付ファイル
    const [selectedAttachedFile, setSelectedAttachedFile] =
        React.useState<File>();
    // 添付ファイル(画像)全画面表示フラグ
    const [showImage, setShowImage] = React.useState<boolean>(false);
    // 添付ファイル(動画)全画面表示フラグ
    const [showVideo, setShowVideo] = React.useState<boolean>(false);
    // チャット表示エリアの表示制御フラグ
    const [chatAreaVisible, setChatAreaVisible] = React.useState<boolean>(true);
    // ダイアログメッセージ表示フラグ
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    // ダイアログメッセージ
    const [dialogMessage, setDialogMessage] = React.useState<string>();
    // 削除確認ボトムシート
    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);

    /***** useEffect */
    React.useEffect(() => {
        Utility.log("UserChatComponent useEffect 1");
        pushDataLayer({
            event: 'page_view',
            screen: "ユーザチャット",
            path: window.location.pathname,
            deviceType: deviceType,
        });
        
        // EventEmitterを登録
        registerEmitterListener();
        setSendButtonLabel("btn_send");
        refTalkType.current = TALK_TYPE.NONE;
        return () => {
            // EventEmitterを解除
            removeEmitterListener();
        };
    }, []);
    React.useEffect(() => {
        Utility.log("UserChatComponent useEffect 3");
        const talkUser = props.talkUser;
        if (talkUser == null) {
            return;
        }
        const wkPartner = talkUser.partner;
        if (wkPartner == null) {
            return;
        }
        setPartner(wkPartner);
        refPartner.current = wkPartner;
        if (
            props.loginUser != null &&
            wkPartner != null &&
            wkPartner.id != null
        ) {
            // データ初期化
            letDataInitialized();
            (async () => {
                dispatch(
                    ProcessAction({ processing: true, message: "msg_loading" })
                );
                // トークメッセージ読み込み
                nowFetching.current = true;
                await getTalkMessageList(currentIndex.current);
                window.setTimeout(async () => {
                    if (refChatArea.current != null) {
                        refChatArea.current.scrollTo({
                            top: refChatArea.current.scrollHeight,
                        });
                    }
                    nowFetching.current = false;
                    dispatch(
                        ProcessAction({
                            processing: false,
                            message: "",
                        })
                    );
                }, 1000);
                // ブロックされてるかどうか確認
                const result = await checkBlocked(wkPartner);
                Utility.log("checkBlocked result:" + JSON.stringify(result));
                if (result != null) {
                    isBlocked.current = result;
                }
            })();
        }
        return () => {};
    }, [props.loginUser, props.talkUser]);

    // データ初期化
    function letDataInitialized() {
        isUnderProcess.current = false;
        readSize.current = 100;
        currentIndex.current = 0;
        hasNext.current = true;
        nowFetching.current = false;
        timeoutId.current = 0;
        refTalkMessageList.current = undefined;

        setMessage("");
        setTalkMessageList(undefined);
        setBtnSendDisabled(false);
        setAttachedFileList(undefined);
        setSelectedAttachedFile(undefined);
        setShowImage(false);
        setShowVideo(false);
        isUnderProcess.current = false;
        refSentReadMessageIdList.current = [];
    }

    /**
     * EventEmitterのイベント登録
     * @returns
     */
    function registerEmitterListener() {
        if (emitter == null) {
            return;
        }
        const s1 = emitter.addListener(
            "RECEIVED_NEW_MESSAGE",
            (talkMessage: TalkMessageModel) => {
                onReceiveTalkMessage(talkMessage);
            }
        );
        const s2 = emitter.addListener(
            "DELETE_TALK_MESSAGE",
            onReceiveDeleteTalkMessage
        );
        const s3 = emitter.addListener(
            "READ_TALK_MESSAGE",
            onReceiveReadTalkMessage
        );
        lstSubscription.current = [s1, s2, s3];
    }
    /**
     * EventEmitterのイベント解除
     */
    function removeEmitterListener() {
        if (lstSubscription != null && lstSubscription.current != null) {
            for (let i = 0; i < lstSubscription.current.length; i++) {
                lstSubscription.current[i].remove();
            }
        }
        if (emitter != null) {
            emitter.removeAllListeners("RECEIVED_NEW_MESSAGE");
            emitter.removeAllListeners("DELETE_TALK_MESSAGE");
            emitter.removeAllListeners("READ_TALK_MESSAGE");
        }
    }

    /**
     * トークメッセージ受信
     * @param talkMessage
     */
    function onReceiveTalkMessage(talkMessage: TalkMessageModel) {
        if (
            refPartner == null ||
            refPartner.current == null ||
            refPartner.current.id == null
        ) {
            return;
        }
        const senderId = talkMessage.senderId;
        // 送信者が自分の場合
        if (senderId === props.loginUser.id) {
            // 受信トークの追加
            addTalkMessage(talkMessage);
            // 添付ファイルリストクリア
            setAttachedFileList(() => {
                return undefined;
            });
            isUnderProcess.current = false;
            setMessage("");
            setBtnSendDisabled(false);
            // setSendButtonLabel("btn_send");
        } else {
            // 対話中のパートナーからの受信の場合
            if (refPartner.current.id === senderId) {
                // 受信トークの追加
                addTalkMessage(talkMessage);
                refPartner.current.online = 1;
            }
        }
    }
    /**
     * トークメッセージ削除通知受信
     * @param talkMessage
     */
    function onReceiveDeleteTalkMessage(talkMessage: TalkMessageModel) {
        if (refTalkMessageList == null || refTalkMessageList.current == null) {
            return;
        }
        const deletedTalkMessageId = talkMessage.id;
        // 受信トークを削除
        const newList = refTalkMessageList.current.filter(
            (talkMessage: TalkMessageModel) => {
                return deletedTalkMessageId !== talkMessage.id;
            }
        );
        setTalkMessageList(newList);
        refTalkMessageList.current = newList;

        window.setTimeout(() => {
            if (refChatArea.current != null) {
                refChatArea.current.scrollTo({
                    top: refChatArea.current.scrollHeight,
                });
            }
            setOpenDrawer(false);
        }, 1000);
    }
    /**
     * トークメッセージ既読通知受信
     * @param _talkMessage
     */
    function onReceiveReadTalkMessage(_talkMessage: TalkMessageModel) {
        const talkMessageId = _talkMessage.id;
        if (talkMessageId == null || props.loginUser == null) {
            return;
        }
        if (refPartner == null || refPartner.current == null) {
            return;
        }
        const senderId = _talkMessage.senderId;
        const receiverId = _talkMessage.receiverId;

        if (senderId === props.loginUser.id && _talkMessage.selfReturn) {
            return;
        }
        if (
            senderId === props.loginUser.id &&
            receiverId === refPartner.current.id
        ) {
            setReadFlag(talkMessageId);
        } else if (
            senderId === refPartner.current.id &&
            receiverId === props.loginUser.id
        ) {
            setReadFlag(talkMessageId);
        }
    }

    /**
     * 未読表示を消す
     * @param talkMessageId
     */
    function setReadFlag(talkMessageId: number): void {
        if (refIsUnderUpdaring.current) {
            window.setTimeout(() => {
                setReadFlag(talkMessageId);
            }, 100);
            return;
        }
        refIsUnderUpdaring.current = true;
        setTalkMessageList((prevList: TalkMessageModel[] | undefined) => {
            if (prevList == null) {
                refIsUnderUpdaring.current = false;
                return prevList;
            }
            const newList = prevList.map((talkMessage) => {
                if (talkMessage.id === talkMessageId) {
                    talkMessage.read = 1;
                }
                return talkMessage;
            });
            refIsUnderUpdaring.current = false;
            return newList;
        });
    }
    /**
     * スクロール位置取得
     * @returns
     */
    const getScrollPosition = (): number => {
        if (refChatArea != null && refChatArea.current != null) {
            return Math.max(refChatArea.current.scrollTop);
        } else {
            return 0;
        }
    };

    /**
     * スクロール時
     * @param event
     * @returns
     */
    async function onScroll(event: any) {
        if (timeoutId.current !== 0) {
            return;
        }
        timeoutId.current = window.setTimeout(async function () {
            const el = refChatArea.current;
            if (el == null) {
                timeoutId.current = 0;
                return;
            }
            const scrollY = getScrollPosition();
            if (scrollY < 50) {
                if (!hasNext.current) {
                    timeoutId.current = 0;
                    return;
                }
                if (nowFetching.current) {
                    timeoutId.current = 0;
                    return;
                }
                nowFetching.current = true;
                setChatAreaVisible(false);
                dispatch(
                    ProcessAction({ processing: true, message: "msg_loading" })
                );
                // 読み込み前のスクロール位置を保持
                let prevScrollHeight = 0;
                let prevScrollY = 0;
                if (refChatArea != null && refChatArea.current != null) {
                    prevScrollHeight = refChatArea.current.scrollHeight;
                    prevScrollY = getScrollPosition();
                }

                // 読み込みインデックス
                currentIndex.current = currentIndex.current + readSize.current;
                // トークメッセージ読み込み
                await getTalkMessageList(currentIndex.current);
                // スクロール位置の復元
                maintainScrollPos(prevScrollHeight, prevScrollY);

                timeoutId.current = 0;
            } else {
                timeoutId.current = 0;
            }
        }, 100);
    }

    /**
     * スクロール位置復元
     */
    async function maintainScrollPos(
        prevScrollHeight: number,
        prevScrollY: number
    ) {
        window.setTimeout(async () => {
            if (refChatArea != null && refChatArea.current != null) {
                const afterScrollHeight = refChatArea.current.scrollHeight;
                if (prevScrollHeight !== afterScrollHeight) {
                    const scrollPos =
                        afterScrollHeight - prevScrollHeight + prevScrollY;
                    refChatArea.current.scrollTo({
                        top: scrollPos,
                    });
                }
            }
            dispatch(ProcessAction({ processing: false, message: "" }));
            nowFetching.current = false;
            setChatAreaVisible(true);
        }, 300);
    }
    /**
     * 接続コールバック
     * @returns
     */
    // function onConnect() {
    //     if (
    //         appServer == null ||
    //         appServer.current == null ||
    //         appServer.current.socket == null ||
    //         !appServer.current.socket.connected
    //     ) {
    //         setBtnSendDisabled(false);
    //         return;
    //     }
    // }
    /**
     * 送信ボタン押下時
     * @returns
     */
    async function onClickSend() {
        if (btnSendDisabled) {
            return;
        }
        if (
            message.trim().length === 0 &&
            (attachedFileList == null || attachedFileList.length === 0)
        ) {
            return;
        }
        // ブロックされてる場合
        if (isBlocked.current) {
            const message = intl.formatMessage({ id: "err_blocked" });
            if (message != null && message.length > 0) {
                setDialogMessage(message);
                setOpenDialog(true);
            }
            return;
        }
        // トークユーザレコード存在チェック
        if (!existTalkUserRecord()) {
            // トークユーザレコード作成
            const success = await createTalkUserRecord();
            if (!success) {
                return;
            }
        }
        if (isUnderProcess.current === true) {
            return;
        }
        isUnderProcess.current = true;
        // setSendButtonLabel("msg_processing");
        setBtnSendDisabled(true);
        // DBサーバへ送信
        const talkMessage = await sendMessageToDbServer();
        if (talkMessage != null) {
            // Appサーバへ送信
            sendMessageToAppServer(talkMessage);
        } else {
            isUnderProcess.current = false;
            setMessage("");
            setBtnSendDisabled(false);
        }
    }
    /**
     * トークメッセージをDBサーバへ送信
     */
    async function sendMessageToDbServer(): Promise<TalkMessageModel | null> {
        if (props.loginUser == null || partner == null || partner.id == null) {
            return null;
        }
        if (
            message.length === 0 &&
            (attachedFileList == null || attachedFileList.length === 0)
        ) {
            return null;
        }
        let lstFile: File[] | undefined;
        if (attachedFileList != null && attachedFileList.length > 0) {
            lstFile = [];
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_sending",
                })
            );
            for (let i = 0; i < attachedFileList.length; i++) {
                lstFile.push(attachedFileList[i].file);
            }
        }
        // リクエスト実行
        const result = await TalkMessageRequest.send(
            props.loginUser,
            partner.id,
            message,
            lstFile
        );
        if (attachedFileList != null && attachedFileList.length > 0) {
            dispatch(
                ProcessAction({
                    processing: false,
                    message: "",
                })
            );
        }
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return null;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return null;
        }
        return result.talkMessage;
    }
    /**
     * メッセージをAppサーバーへ送信
     * @returns
     */
    function sendMessageToAppServer(talkMessage: TalkMessageModel) {
        if (
            appServer == null ||
            appServer.current == null ||
            appServer.current.socket == null ||
            !appServer.current.socket.connected
        ) {
            return null;
        }
        appServer.current.sendMessage(talkMessage);
    }
    /**
     * トークユーザレコード有無検証
     * @returns
     */
    function existTalkUserRecord(): boolean {
        const talkedAt = props.talkUser?.talkedAt;
        if (talkedAt == null) {
            return false;
        }
        const strYear = talkedAt.substring(0, 4);
        const year = parseInt(strYear);
        if (year < 2000) {
            return false;
        } else {
            return true;
        }
    }
    /**
     * トークユーザレコード作成
     * @returns
     */
    async function createTalkUserRecord(): Promise<boolean> {
        if (partner == null) {
            return false;
        }
        const partnerId = partner.id;
        if (partnerId == null) {
            return false;
        }
        const result = await TalkUserRequest.createTalkUser(
            props.loginUser,
            partnerId
        );
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return false;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return false;
        }
        return true;
    }
    /**
     * チャットサーバから切断
     * @returns
     */
    // function disconnectFromAppServer() {
    //     if (appServer == null || appServer.current == null) {
    //         return null;
    //     }
    //     appServer.current.disconnect();
    // }

    /**
     * ブロックされてるかどうか
     */
    async function checkBlocked(_partner: UserModel): Promise<boolean | null> {
        if (_partner == null) {
            return null;
        }
        const userId = props.loginUser.id;
        const targetId = _partner.id;
        if (userId == null || targetId == null) {
            return null;
        }
        const result = await UserRequest.isBlocked(props.loginUser, targetId);
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return null;
        }

        if (result.rtnCd == null || result.rtnCd < 0) {
            return null;
        }
        return result.blocked;
    }

    /**
     * ファイル選択時
     * @param e
     * @returns
     */
    async function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
        const target = e.currentTarget as HTMLInputElement;
        const lstFile = target.files as FileList;
        if (lstFile == null) {
            return;
        }
        let wkAttachedFileList: SelectedFile[] = [];
        for (let i = 0; i < lstFile.length; i++) {
            const file = lstFile[i];
            // const fileName = Utility.addDateTimeToFileName(file.name);
            // const file2 = new File([file], fileName, { type: file.type });
            const selectedFile: SelectedFile = {
                name: file.name,
                file: file,
            };
            wkAttachedFileList.push(selectedFile);
        }
        if (attachedFileList == null) {
            setAttachedFileList(wkAttachedFileList);
        } else {
            setAttachedFileList((prevState) => {
                if (prevState == null) {
                    return wkAttachedFileList;
                } else {
                    return [...prevState, ...wkAttachedFileList];
                }
            });
        }
        target.value = "";
    }

    /**
     * 選択した添付ファイルの全画面表示
     * @param _selectedFile
     */
    function onClickAttachedFile(_selectedFile: SelectedFile) {
        // const fileName = _selectedFile.file.name;
        // 画像の場合
        if (Utility.isImage(_selectedFile.file.name)) {
            setShowImage(true);
        }
        // 動画の場合
        else if (!Utility.isImage(_selectedFile.file.name)) {
            setShowVideo(true);
        }
        setSelectedAttachedFile(_selectedFile.file);
    }

    /**
     * 添付ファイル削除
     * @param selectedFile
     */
    function onClickDeleteAttachedFile(_selectedFile: SelectedFile) {
        if (attachedFileList == null) {
            return;
        }
        let wkAttachedFileList = [...attachedFileList];
        wkAttachedFileList = wkAttachedFileList.filter((selectedFile) => {
            return selectedFile.name !== _selectedFile.name;
        });
        setAttachedFileList(wkAttachedFileList);
    }

    /**
     * 既読ステータスを更新してトーク相手に通知
     * @param _talkMessage
     */
    async function readPartnerMessage(_talkMessage: TalkMessageModel) {
        if (isAlreadyUpdatedReadStatus(_talkMessage)) {
            return;
        }
        if (_talkMessage.id == null) {
            return;
        }
        if (refSentReadMessageIdList.current == null) {
            refSentReadMessageIdList.current = [_talkMessage.id];
        } else {
            refSentReadMessageIdList.current.push(_talkMessage.id);
        }
        const talkMessage = await updateReadStatusOnDbServer(_talkMessage);
        if (talkMessage != null) {
            dispatch(DecrementTalkUnreadCountAction());
            sendReadNotification(_talkMessage);
        }
    }
    function isAlreadyUpdatedReadStatus(
        _talkMessage: TalkMessageModel
    ): boolean {
        let updated = false;
        if (refSentReadMessageIdList.current != null) {
            for (let i = 0; i < refSentReadMessageIdList.current.length; i++) {
                const tmpTalkMessageId = refSentReadMessageIdList.current[i];
                if (tmpTalkMessageId === _talkMessage.id) {
                    updated = true;
                    break;
                }
            }
        }
        return updated;
    }
    /**
     * DBデータ上に既読フラグを設定する
     * @param talkMessage
     */
    async function updateReadStatusOnDbServer(
        talkMessage: TalkMessageModel
    ): Promise<TalkMessageModel | null> {
        const senderId = talkMessage.senderId;
        const talkMessageId = talkMessage.id;
        const createdAt = talkMessage.createdAt
        if (senderId == null || talkMessageId == null || createdAt == null) {
            return null;
        }
        const result = await TalkMessageRequest.read(
            props.loginUser,
            senderId,
            talkMessageId,
            createdAt
        );
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return null;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return null;
        }
        return talkMessage;
    }
    /**
     * 既読通知を送信する
     * @param talkMessage
     */
    function sendReadNotification(talkMessage: TalkMessageModel) {
        AppServer.instance.sendReadNotificationToAppServer(talkMessage);
    }

    /**
     * トークメッセージリスト取得
     */
    async function getTalkMessageList(index: number) {
        if (
            props.loginUser == null ||
            refPartner == null ||
            refPartner.current == null ||
            refPartner.current.id == null
        ) {
            return;
        }
        if (!hasNext.current) {
            return null;
        }
        if (isUnderProcess.current) {
            return null;
        }
        var createdAt = null;
        if (lstTalkMessage != null && lstTalkMessage.length > 0) {
            const lastTalkMessage = lstTalkMessage[0];
            createdAt = lastTalkMessage.createdAt;
        }
        isUnderProcess.current = true;
        
        // リクエスト実行
        const result = await TalkMessageRequest.getTalkMessageList(
            props.loginUser,
            refPartner.current.id,
            createdAt,
            index
        );
        try {
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                throw new Error();
            }
            currentIndex.current = index;
            // hasNext
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            // readSize
            if (result.readSize != null) {
                readSize.current = result.readSize;
            }
            // lstTalkMessage
            if (result.lstTalkMessage != null) {
                if (result.lstTalkMessage.length === 0 && index === 0) {
                    addDateHeader();
                } else {
                    prependTalkMessageList(result.lstTalkMessage);
                }
            }
        } catch (error) {
        } finally {
            isUnderProcess.current = false;
        }
    }

    /**
     * 取得したトークメッセージリストを、現在のトークメッセージリストにprependする
     * @param lstTalkMessageNew 取得したトークメッセージリスト(日付降順)
     * @returns
     */
    function prependTalkMessageList(lstTalkMessageNew: TalkMessageModel[]) {
        if (lstTalkMessageNew.length === 0) {
            return;
        }

        // 現在のトークメッセージリスト
        let lstTalkMessageCurrent: TalkMessageModel[] = [];
        if (refTalkMessageList != null && refTalkMessageList.current != null) {
            lstTalkMessageCurrent = [...refTalkMessageList.current];
        }

        // 日付変更をチェックするための前レコードの日付。前レコードがなければ現在日付
        let prevDate: string | null = null;
        if (lstTalkMessageCurrent != null && lstTalkMessageCurrent.length > 0) {
            prevDate = lstTalkMessageCurrent[0].displayDate;
        }

        // 取得したメッセージリスト(日付降順)分ループ
        // - 取得したトークメッセージリストに日付ヘッダーを挟み込んだメッセージリストを作成(日付昇順)
        // - 各レコードに表示日付と表示時刻を設定
        let wkTalkMessageListNew: TalkMessageModel[] = [];

        for (let i = 0; i < lstTalkMessageNew.length; i++) {
            const talkMessage = lstTalkMessageNew[i];
            if (talkMessage.createdAt != null) {
                const createdAt = new Date(talkMessage.createdAt);
                const array = Utility.getFormattedDateTimeArray(
                    createdAt.getTime() / 1000,
                    timeDifference == null ? 0 : timeDifference,
                    langCd == null ? "en" : langCd
                );
                // 表示日付セット
                talkMessage.displayDate = array[0];
                // 表示時刻セット
                talkMessage.displayTime = array[1];
                // 前レコードから日付が替わった場合
                if (prevDate !== talkMessage.displayDate) {
                    if (prevDate == null) {
                        wkTalkMessageListNew.unshift(talkMessage);
                    } else {
                        // 日付ヘッダー作成
                        const header = createDateHeader(prevDate);
                        // 日付ヘッダーを挟み込む
                        wkTalkMessageListNew.unshift(header);
                        wkTalkMessageListNew.unshift(talkMessage);
                    }
                } else {
                    wkTalkMessageListNew.unshift(talkMessage);
                }
            }
            prevDate = talkMessage.displayDate;
        }

        // チャットレコードの先頭に日付ヘッダーをprependする
        {
            // 現在日時の日付ヘッダー(チャットレコードの先頭に日付ヘッダーを付ける)
            let headDate = Utility.getFormattedDate(
                new Date().getTime() / 1000,
                timeDifference == null ? 0 : timeDifference,
                langCd == null ? "en" : langCd
            );
            // 取得したメッセージリスト(日付昇順)のレコード件数が１以上の場合
            if (wkTalkMessageListNew.length > 0) {
                if (wkTalkMessageListNew[0].displayDate != null) {
                    // チャットレコードの先頭の日付ヘッダーの日付セット
                    headDate = wkTalkMessageListNew[0].displayDate;
                }
            }
            if (!hasNext.current) {
                // チャットレコードの先頭に日付ヘッダーをprependする
                wkTalkMessageListNew = prependHeadDateHeader(
                    wkTalkMessageListNew,
                    headDate
                );
            }
        }

        // 取得したメッセージリストを現在のメッセージリストにprependする
        setTalkMessageList((prevTalkMessageList) => {
            if (prevTalkMessageList == null) {
                if (wkTalkMessageListNew == null) {
                    refTalkMessageList.current = lstTalkMessageCurrent;
                    return lstTalkMessageCurrent;
                } else {
                    refTalkMessageList.current = wkTalkMessageListNew;
                    return wkTalkMessageListNew;
                }
            } else {
                if (wkTalkMessageListNew == null) {
                    refTalkMessageList.current = lstTalkMessageCurrent;
                    return lstTalkMessageCurrent;
                } else {
                    const newArray = wkTalkMessageListNew.concat(
                        lstTalkMessageCurrent
                    );
                    refTalkMessageList.current = newArray;
                    return newArray;
                }
            }
        });
    }
    /**
     * トークメッセージ追加
     * @param talkMessage
     */
    function addTalkMessage(talkMessage: TalkMessageModel) {
        // 表示日時をセット
        {
            let createdAt: Date | null;
            if (talkMessage.createdAt != null) {
                createdAt = new Date(talkMessage.createdAt);
            } else {
                createdAt = new Date();
            }
            const array = Utility.getFormattedDateTimeArray(
                createdAt.getTime() / 1000,
                timeDifference == null ? 0 : timeDifference,
                langCd == null ? "en" : langCd
            );
            // 表示日付セット
            talkMessage.displayDate = array[0];
            // 表示時刻セット
            talkMessage.displayTime = array[1];
        }
        // 日付変更有無
        if (talkMessage.displayDate != null) {
            if (isChangedDateFromLastRecord(talkMessage.displayDate)) {
                addDateHeader(talkMessage.displayDate);
            }
        }
        // トークメッセージリストの末尾に追加
        setTalkMessageList((prevTalkMessageList) => {
            if (prevTalkMessageList == null) {
                const newList = [talkMessage];
                refTalkMessageList.current = newList;
                return newList;
            } else {
                const newList = [...prevTalkMessageList, talkMessage];
                refTalkMessageList.current = newList;
                return newList;
            }
        });
        window.setTimeout(() => {
            if (refChatArea.current != null) {
                refChatArea.current.scrollTo({
                    top: refChatArea.current.scrollHeight,
                });
            }
        }, 1000);
    }
    /**
     * 日付ヘッダーを末尾に追加
     * @param date
     */
    function addDateHeader(date: string | undefined = undefined) {
        if (date === undefined) {
            date = Utility.getFormattedDate(
                new Date().getTime() / 1000,
                timeDifference == null ? 0 : timeDifference,
                langCd == null ? "en" : langCd
            );
        }
        const header = createDateHeader(date);
        setTalkMessageList((prevTalkMessageList) => {
            if (prevTalkMessageList == null) {
                return [header];
            } else {
                prevTalkMessageList.push(header);
                return prevTalkMessageList;
            }
        });
    }

    /**
     * 日付ヘッダー作成
     * @param date
     * @returns
     */
    function createDateHeader(date: string): TalkMessageModel {
        const talkMessage = new TalkMessageModel();
        talkMessage.isHeader = true;
        talkMessage.displayDate = date;
        return talkMessage;
    }
    /**
     * トークメッセージリストの先頭に日付ヘッダーをprependする
     * @param wkTalkMessageList
     * @param date
     */
    function prependHeadDateHeader(
        wkTalkMessageList: TalkMessageModel[],
        date: string
    ): TalkMessageModel[] {
        const talkMessage = createDateHeader(date);
        wkTalkMessageList.unshift(talkMessage);
        return wkTalkMessageList;
    }
    /**
     * トークメッセ配列の先頭日付ヘッダーを除去
     * @param wkTalkMessageList
     */
    // function removeHeadDateHeader(
    //     wkTalkMessageList: TalkMessageModel[]
    // ): TalkMessageModel[] {
    //     if (wkTalkMessageList != null && wkTalkMessageList.length > 0) {
    //         if (wkTalkMessageList[0].isHeader) {
    //             wkTalkMessageList.splice(0, 1);
    //         }
    //     }
    //     return wkTalkMessageList;
    // }
    /**
     * 最新レコードが、最終レコードと比較して日付が変わったかどうか
     * @param displayDate
     * @returns
     */
    function isChangedDateFromLastRecord(displayDate: string): boolean {
        if (
            refTalkMessageList == null ||
            refTalkMessageList.current == null ||
            refTalkMessageList.current.length === 0
        ) {
            return false;
        }
        const lastRec =
            refTalkMessageList.current[refTalkMessageList.current.length - 1];
        const lastDisplayDate = lastRec.displayDate;
        if (lastDisplayDate == null) {
            return true;
        }
        if (lastDisplayDate !== displayDate) {
            return true;
        } else {
            return false;
        }
    }
    /**
     * 送信ボタンのラベル設定
     * @param labelId
     */
    const setSendButtonLabel = (labelId: string) => {
        const btnLabel = intl.formatMessage({
            id: labelId,
        });
        setBtnSendLabel(btnLabel);
    };

    /**
     * トークメッセージ削除確認シート表示
     * @param talkMessage
     */
    function showDeleteConfirm(talkMessage: TalkMessageModel) {
        refSelectedTalkMessage.current = talkMessage;
        setOpenDrawer(true);
    }
    /**
     * トークメッセージ削除
     * @param talkMessage
     */
    async function deleteTalkMessage() {
        if (refSelectedTalkMessage.current == null) {
            return;
        }
        const deletedTalkMessage = await deleteTalkMessageFromDbServer(
            refSelectedTalkMessage.current
        );
        if (deletedTalkMessage != null) {
            sendDeleteNotificationToAppServer(deletedTalkMessage);
        }
    }
    /**
     * トークメッセージをDBサーバから削除
     * @param talkMessage
     * @returns
     */
    async function deleteTalkMessageFromDbServer(
        talkMessage: TalkMessageModel
    ): Promise<TalkMessageModel | null> {
        const talkMessageId = talkMessage.id;
        const receiverId = talkMessage.receiverId
        const createdAt = talkMessage.createdAt
        if (props.loginUser.id == null || 
            talkMessageId == null || 
            receiverId == null ||
            createdAt == null
        ) {
            return null;
        }
        const result = await TalkMessageRequest.delete(
            props.loginUser,
            talkMessageId,
            receiverId,
            createdAt
        );
        if (result == null) {
            if (window.navigator.onLine) {
                navigate("/maintenance");
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return null;
        }
        if (result.rtnCd == null || result.rtnCd < 0) {
            return null;
        }
        return result.talkMessage;
    }
    /**
     * トークメッセージの削除通知をAppサーバに送信
     * @param talkMessage
     */
    function sendDeleteNotificationToAppServer(talkMessage: TalkMessageModel) {
        AppServer.instance.sendDeleteMessageToAppServer(talkMessage);
    }

    function getPartnerNameClassName(partner: UserModel): string {
        if (partner.gender === DbConstants.GENDER_MALE) {
            return "partner-name-area male";
        } else if (partner.gender === DbConstants.GENDER_FEMALE) {
            return "partner-name-area female";
        } else {
            return "partner-name-area other";
        }
    }
    /***** レンダリング */
    return (
        <div className="component UserChatComponent">
            {partner != null &&
                partner.name != null &&
                partner.name.length > 0 &&
                partner.gender != null &&
                props.loginUser != null &&
                props.loginUser.id != null && (
                    <>
                        {
                            // チャット表示エリア
                        }
                        <Box 
                            ref={refChatArea} 
                            className={chatAreaVisible ? "chat-area-wrapper" : "chat-area-wrapper hidden"}
                            onScroll={onScroll}
                        >
                            <Box className="chat-display-area">
                                {lstTalkMessage != null &&
                                    lstTalkMessage.map(
                                        (
                                            talkMessage: TalkMessageModel,
                                            index: number
                                        ) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {props.loginUser != null &&
                                                        langCd != null &&
                                                        timeDifference != null &&
                                                        partner != null && (
                                                            <UserChatListItemComponent
                                                                loginUser={
                                                                    props.loginUser
                                                                }
                                                                partner={partner}
                                                                talkMessage={
                                                                    talkMessage
                                                                }
                                                                timeDifference={
                                                                    timeDifference
                                                                }
                                                                langCd={langCd}
                                                                visible={
                                                                    chatAreaVisible
                                                                }
                                                                read={
                                                                    talkMessage.read ??
                                                                    0
                                                                }
                                                                onClickDelete={() => {
                                                                    showDeleteConfirm(
                                                                        talkMessage
                                                                    );
                                                                }}
                                                                onRead={() => {
                                                                    readPartnerMessage(
                                                                        talkMessage
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                            </Box>
                        </Box>
                        {
                            // チャット入力エリア
                        }
                        <div className="chat-input-area">
                            {
                                // 添付ファイル一覧表示
                            }
                            {attachedFileList != null &&
                                attachedFileList.length > 0 && (
                                    <Box className="attached_file_area">
                                        {attachedFileList.map(
                                            (
                                                selectedFile: SelectedFile,
                                                index: number
                                            ) => {
                                                return (
                                                    <AttachedFileNameComponent
                                                        key={index}
                                                        fileName={
                                                            selectedFile.name
                                                        }
                                                        onClick={() => {
                                                            onClickAttachedFile(
                                                                selectedFile
                                                            );
                                                        }}
                                                        onDelete={() => {
                                                            onClickDeleteAttachedFile(
                                                                selectedFile
                                                            );
                                                        }}
                                                    />
                                                );
                                            }
                                        )}
                                    </Box>
                                )}
                            {
                                // 入力エリア
                            }
                            <div className="input-area">
                                <AttachFile
                                    className="attach-file-icon"
                                    onClick={() => {
                                        if (
                                            refAttachFile != null &&
                                            refAttachFile.current != null
                                        ) {
                                            refAttachFile.current.click();
                                        }
                                    }}
                                />
                                <input
                                    ref={refAttachFile}
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="image/*,video/*"
                                    multiple
                                    onChange={onFileSelected}
                                    onClick={(
                                        e: MouseEvent<HTMLInputElement>
                                    ) => {}}
                                />
                                <TextField
                                    className="text-field"
                                    variant="outlined"
                                    multiline={true}
                                    maxRows={5}
                                    minRows={1}
                                    InputProps={{
                                        style: {
                                            padding: "2px 10px",
                                            minHeight: "40px",
                                        },
                                    }}
                                    type="text"
                                    value={message}
                                    onChange={(event) =>
                                        setMessage(event.target.value)
                                    }
                                />

                                <IconButton
                                    className="button-area"
                                    disabled={btnSendDisabled}
                                    onClick={onClickSend}
                                >
                                    <SendButton className="send-button" />
                                </IconButton>
                            </div>
                        </div>
                    </>
                )}
            {
                // ダイアログメッセージ
            }
            <Dialog
                className="dialog"
                open={openDialog}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="dialog-title">
                    <FormattedMessage id={"dlg_title_message"} />
                </DialogTitle>
                <DialogContent className="dialog-content2">
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="dialog-button"
                        onClick={() => {
                            setOpenDialog(false);
                        }}
                        color="primary"
                    >
                        <FormattedMessage id={"btn_ok"} />
                    </Button>
                </DialogActions>
            </Dialog>
            {
                // 画像、動画表示
            }
            {props.loginUser != null && selectedAttachedFile != null && (
                <>
                    <ImageComponent
                        user={props.loginUser}
                        file={selectedAttachedFile}
                        onClose={() => {
                            setShowImage(false);
                        }}
                        open={showImage}
                    />
                    <VideoComponent
                        user={props.loginUser}
                        file={selectedAttachedFile}
                        onClose={() => {
                            setShowVideo(false);
                        }}
                        open={showVideo}
                    />
                </>
            )}
            {
                // 削除確認シート
            }
            <Drawer
                anchor={"bottom"}
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
            >
                <Box
                    sx={{
                        width: "auto",
                    }}
                    role="presentation"
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{
                                    fontWeight: "bold",
                                    color: "#ff0000",
                                    justifyContent: "center !important",
                                }}
                                onClick={deleteTalkMessage}
                            >
                                <FormattedMessage id={"btn_delete"} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem disablePadding>
                            <ListItemButton
                                sx={{
                                    fontWeight: "bold",
                                    justifyContent: "center !important",
                                }}
                                onClick={() => {
                                    setOpenDrawer(false);
                                }}
                            >
                                <FormattedMessage id={"btn_cancel"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </div>
    );
};

export const UserChatComponent = React.memo(
    UserChat,
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.talkUser == null && nextProps.talkUser != null) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser == null) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
        }

        return true;
    }
);
