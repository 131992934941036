import React from "react";
import { Box, Zoom, Fab, useScrollTrigger, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    UserModel,
    ParentMailModel,
    HistoryReceivedMailList,
    MailModel,
} from "models/Models";
import { KeyboardArrowUp } from "@mui/icons-material";
import { MailRequest } from "api/requests/Requests";
import {
    ReceivedParentMailListItem,
    ReceivedChildMailComponent,
    SendMessageDialog,
} from "components/Components";
import {
    ProcessAction,
    SelectedItemAction,
    HistoryAction,
    UnreadCountAction,
    WorkingItemAction,
    NetworkAction,
} from "redux/Actions";
import {
    getReceivedMailListHistory,
    getTimeDifference,
    getLanguageCode,
    getDeviceType
} from "redux/Selectors";
import Utility from "utils/Utility";
import WriteIcon from "assets/images/write.svg";
import ReplyIcon from "@mui/icons-material/Reply";
import { DbConstants } from "constants/DbConstants";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mail/ReceivedParentMail.scss";
import { AppConstants } from "constants/AppConstants";

type Props = {
    loginUser: UserModel | null;
    selectedTab: number;
    refreshTime: number;
    deviceType: number;
};

const ReceivedParentMail: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("@@@@@ ReceivedParentMail IN");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const langCd = useSelector((state) => getLanguageCode(state));
        // 時差
        const timeDifference = useSelector((state) => getTimeDifference(state));
        let history: HistoryReceivedMailList | null = useSelector((state) => getReceivedMailListHistory(state) as HistoryReceivedMailList);

        /***** useRef */
        const container = React.useRef<HTMLDivElement>(null);
        const refParentMailList = React.useRef<HTMLDivElement>();
        // 処理中フラグ
        const isUnderProcess = React.useRef<boolean>(false);
        // 読込中フラグ
        const nowFetching = React.useRef<boolean>(false);
        // タイマーID
        const timeoutId = React.useRef<number>(0);
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス日付
        const limitUnixTime = React.useRef<number>(0);
        const prevLimitUnixTime = React.useRef<number>(0);
        // リプライフラグ
        const isReply = React.useRef<boolean>(false);

        /***** useState */
        // 親メールリスト読み込み完了フラグ
        const [loadedParentMail, setLoadedParentMail] =
            React.useState<boolean>(false);
        // 親メールリスト
        const [lstParentMail, setParentMailList] =
            React.useState<ParentMailModel[]>();
        // 選択親メール
        const [selectedParent, setSelectedParent] =
            React.useState<ParentMailModel>();
        // メッセージ送信ダイアログ表示フラグ
        const [openSendMessageDialog, setOpenSendMessageDialog] =
            React.useState<boolean>(false);
        const [childRefreshTime, setChildRefreshTime] = React.useState<number>(0);
        // スクロールターゲット
        // const [scrollTarget, setScrollTarget] = React.useState<
        //     Window | HTMLDivElement
        // >();
        
        // let trigger = useScrollTrigger({
        //     target: refParentMailList.current,
        //     disableHysteresis: true,
        //     threshold: 300,
        // });

        // const scrollToTop = React.useCallback(() => {
        //     if (refParentMailList.current != null) {
        //         refParentMailList.current.scrollTo({ top: 0 });
        //     }
        // }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "受信メール一覧",
                path: window.location.pathname,
                deviceType: deviceType,
            });
        }, []);
        React.useEffect(() => {
            if (props.loginUser == null) {
                return;
            }
            if (props.selectedTab !== 0) {
                return;
            }
            window.setTimeout(async() => {
                dispatch(
                    SelectedItemAction({
                        childMail: null,
                        parentMail: null,
                    })
                );
                dispatch(
                    ProcessAction({
                        processing: true,
                        message: "msg_loading",
                    })
                );
                const tmpParentMailList = await getParentMailList();
                if (tmpParentMailList != null && tmpParentMailList.length > 0) {
                    const parentMail = tmpParentMailList[0];
                    dispatch(SelectedItemAction({ parentMail: parentMail }));
                    setSelectedParent(parentMail);
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
                window.setTimeout(() => {
                    setLoadedParentMail(true);
                }, 100);
            });
            return () => {
                (async () => {
                    dispatch(SelectedItemAction({ parentMail: null }));
                })();
                if (limitUnixTime != null && limitUnixTime.current != null &&
                    prevLimitUnixTime != null && prevLimitUnixTime.current != null &&
                    hasNext != null && hasNext.current != null &&
                    lstParentMail != null)
                {
                    saveHistory(
                        limitUnixTime.current,
                        prevLimitUnixTime.current,
                        hasNext.current,
                        lstParentMail,
                        true,
                        true
                    );
                }
            };
        }, [
            dispatch,
            props.loginUser,
        ]);
        React.useEffect(() => {
            if (props.refreshTime === 0) {
                return;
            }
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_loading",
                })
            );
            isUnderProcess.current = false;
            nowFetching.current = false;
            hasNext.current = true;
            limitUnixTime.current = 0;
            prevLimitUnixTime.current = 0;
            window.setTimeout(async() => {
                const tmpParentMailList = await getParentMailList(true);
                if (tmpParentMailList != null && tmpParentMailList.length > 0) {
                    const parentMail = tmpParentMailList[0];
                    dispatch(SelectedItemAction({ parentMail: parentMail }));
                    setSelectedParent(parentMail);
                    if (refParentMailList.current != null) {
                        refParentMailList.current.scrollTo({ top: 0 });
                    }
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
                window.setTimeout(() => {
                    setChildRefreshTime(props.refreshTime);
                    setLoadedParentMail(true);
                }, 100);
            });
        }, [
            props.refreshTime,
        ]);
        React.useEffect(() => {
            if (props.selectedTab !== 0) {
                return;
            }
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_loading",
                })
            );
            isUnderProcess.current = false;
            nowFetching.current = false;
            hasNext.current = true;
            limitUnixTime.current = 0;
            prevLimitUnixTime.current = 0;
            window.setTimeout(async() => {
                const tmpParentMailList = await getParentMailList(true);
                if (tmpParentMailList != null && tmpParentMailList.length > 0) {
                    const parentMail = tmpParentMailList[0];
                    dispatch(SelectedItemAction({ parentMail: parentMail }));
                    setSelectedParent(parentMail);
                    if (refParentMailList.current != null) {
                        refParentMailList.current.scrollTo({ top: 0 });
                    }
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
                window.setTimeout(() => {
                    setChildRefreshTime(props.refreshTime);
                    setLoadedParentMail(true);
                }, 100);
            });
        }, [
            props.selectedTab,
        ]);
        React.useEffect(() => {
            if (lstParentMail == null) {
                return;
            }
            if (props.selectedTab !== 0) {
                return;
            }
            addScrollEventListener();
            return () => {
                removeScrollEventListener();
            };
        }, [lstParentMail]);

        function addScrollEventListener() {
            if (
                refParentMailList == null ||
                refParentMailList.current == null
            ) {
                window.setTimeout(addScrollEventListener, 100);
                return;
            }
            refParentMailList.current.addEventListener("scroll", onScroll);
        }
        function removeScrollEventListener() {
            if (
                refParentMailList != null &&
                refParentMailList.current != null
            ) {
                refParentMailList.current.removeEventListener(
                    "scroll",
                    onScroll
                );
            }
        }
        /**
         * スクロール位置取得
         * @returns
         */
        const getScrollPosition = (): number => {
            if (
                refParentMailList != null &&
                refParentMailList.current != null
            ) {
                return Math.max(refParentMailList.current.scrollTop);
            } else {
                return 0;
            }
        };

        /**
         * スクロール時
         * @param event
         * @returns
         */
        async function onScroll(event: any) {
            if (timeoutId.current !== 0) {
                return;
            }
            timeoutId.current = window.setTimeout(async function () {
                const el = refParentMailList.current;
                if (el == null) {
                    timeoutId.current = 0;
                    return;
                }
                const scrollHeight = el.scrollHeight;
                const scrollY = getScrollPosition();
                const windowHeight = window.innerHeight;
                if (scrollHeight - 50 < scrollY + windowHeight) {
                    if (!hasNext.current) {
                        return;
                    }
                    if (nowFetching.current) {
                        return;
                    }
                    nowFetching.current = true;

                    await getParentMailList();

                    nowFetching.current = false;
                }
                timeoutId.current = 0;
            }, 100);
        }

        /**
         * メッセージを書く
         */
        function onClickNewMessage() {
            dispatch(
                SelectedItemAction({
                    childMail: null,
                    parentMail: null,
                })
            );
            dispatch(
                WorkingItemAction({
                    mail: null,
                })
            );
            window.setTimeout(() => {
                isReply.current = false;
                if (isSizeXS) {
                    navigate("/mail/send");
                } else {
                    setOpenSendMessageDialog(true);
                }
            });
        }
        /**
         * 返信を書く
         */
        function onClickReplyMessage() {
            if (
                selectedParent == null ||
                selectedParent.senderId === DbConstants.SMMATE_STAFF_ID
            ) {
                return;
            }
            dispatch(
                SelectedItemAction({
                    parentMail: selectedParent,
                })
            );
            dispatch(
                WorkingItemAction({
                    mail: null,
                })
            );
            isReply.current = true;
            if (isSizeXS) {
                navigate("/mail/send");
            } else {
                setOpenSendMessageDialog(true);
            }
        }

        /**
         * 次レコード取得のためのLimitUnixTimeを取得
         * @returns
         */
        function getNextLimitUnixTime(limitTime: number) {
            let nextUnixTime = limitTime;
            if (nextUnixTime === 0) {
                nextUnixTime = Math.floor(new Date().getTime() / 1000);
            }
            // 6ヶ月前(60秒x60分x24時間x30日x6)のunixtime取得
            nextUnixTime = nextUnixTime - 15552000;
            return nextUnixTime;
        }
        /**
         * アイテムクリック時
         * @param parent_mail
         */
        const onClickItem = React.useCallback(async(parent_mail: ParentMailModel) => {
            if (parent_mail.id != null) {
                if (isSizeXS) {
                    const scrollPos = getScrollPosition();
                    if (history != null) {
                        history.scrollPos = scrollPos;
                        dispatch(
                            HistoryAction({
                                receivedMailListHistory: history,
                            })
                        );
                    }
                    dispatch(SelectedItemAction({ parentMail: parent_mail }));
                    window.setTimeout(function () {
                        navigate(`/mail/received/${parent_mail.id}`);
                    });
                } else {
                    dispatch(SelectedItemAction({ parentMail: parent_mail }));
                    setSelectedParent(parent_mail);
                }
            }
        }, [
            isSizeXS,
            history,
            dispatch,
            navigate,
        ]);

        /**
         * 子メール既読
         * @returns
         */
        const readMail = React.useCallback(async() => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (selectedParent == null || selectedParent.id == null) {
                return;
            }
            let parentId = selectedParent.id;
            if (lstParentMail != null) {
                let list = [...lstParentMail];
                for (let i = 0; i < list.length; i++) {
                    let parentMail = list[i];
                    if (parentMail.id === parentId) {
                        if (props.loginUser.id === parentMail.receiverId) {
                            if (
                                parentMail.receiverUnreadCnt != null &&
                                parentMail.receiverUnreadCnt > 0
                            ) {
                                parentMail.receiverUnreadCnt -= 1;
                            }
                            if (parentMail.receiverUnreadCnt === 0) {
                                parentMail.read = true;
                            }
                        } else if (props.loginUser.id === parentMail.senderId) {
                            if (
                                parentMail.senderUnreadCnt != null &&
                                parentMail.senderUnreadCnt > 0
                            ) {
                                parentMail.senderUnreadCnt -= 1;
                            }
                            if (parentMail.senderUnreadCnt === 0) {
                                parentMail.read = true;
                            }
                        }
                    }
                }
                setParentMailList(list);
            }
        }, [
            props.loginUser,
            selectedParent,
            lstParentMail,
        ]);
        /**
         * 子メール削除
         * @returns
         */
        const deleteMail = React.useCallback(async(
            child_mail: MailModel,
            childMailCount: number
        ) => {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (child_mail.receiverId == null || child_mail.senderId == null) {
                return;
            }
            if (selectedParent == null || selectedParent.id == null) {
                return;
            }
            if (lstParentMail == null) {
                return;
            }
            let parentId = selectedParent.id;
            for (let i = 0; i < lstParentMail.length; i++) {
                let parentMail = lstParentMail[i];
                if (parentMail.id === parentId) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.receiverReceiveCnt != null &&
                                parentMail.receiverReceiveCnt > 0
                            ) {
                                parentMail.receiverReceiveCnt -= 1;
                            }
                            if (parentMail.receiverReceiveCnt === 0) {
                                parentMail.hidden = true;
                            }
                            if (child_mail.read === 0) {
                                if (
                                    parentMail.receiverUnreadCnt != null &&
                                    parentMail.receiverUnreadCnt > 0
                                ) {
                                    parentMail.receiverUnreadCnt -= 1;
                                }
                                if (parentMail.receiverUnreadCnt === 0) {
                                    parentMail.read = true;
                                }
                            }
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (props.loginUser.id === child_mail.receiverId) {
                            if (
                                parentMail.senderReceiveCnt != null &&
                                parentMail.senderReceiveCnt > 0
                            ) {
                                parentMail.senderReceiveCnt -= 1;
                            }
                            if (parentMail.senderReceiveCnt === 0) {
                                parentMail.hidden = true;
                            }
                            if (child_mail.read === 0) {
                                if (
                                    parentMail.senderUnreadCnt != null &&
                                    parentMail.senderUnreadCnt > 0
                                ) {
                                    parentMail.senderUnreadCnt -= 1;
                                }
                                if (parentMail.senderUnreadCnt === 0) {
                                    parentMail.read = true;
                                }
                            }
                        }
                    }
                }
            }
            if (childMailCount === 0) {
                let index = -1;
                if (lstParentMail != null && selectedParent != null) {
                    for (let i = 0; i < lstParentMail.length; i++) {
                        const parentMail = lstParentMail[i];
                        if (parentMail.id === selectedParent.id) {
                            index = i;
                            break;
                        }
                    }
                }
                if (index > -1) {
                    if (lstParentMail.length > index + 1) {
                        for (let i = index + 1; i < lstParentMail.length; i++) {
                            const parentMail = lstParentMail[i];
                            if (!parentMail.hidden) {
                                dispatch(
                                    SelectedItemAction({
                                        parentMail: parentMail,
                                    })
                                );
                                setSelectedParent(parentMail);
                                break;
                            }
                        }
                    } else {
                        if (index - 1 >= 0) {
                            for (let i = index - 1; i >= 0; i--) {
                                const parentMail = lstParentMail[i];
                                if (!parentMail.hidden) {
                                    dispatch(
                                        SelectedItemAction({
                                            parentMail: parentMail,
                                        })
                                    );
                                    setSelectedParent(parentMail);
                                    break;
                                }
                            }
                        }
                    }
                }
                let newParentMailList = lstParentMail.filter((parentMail) => {
                    return !parentMail.hidden;
                });
                setParentMailList(newParentMailList);
            }
        }, [
            props.loginUser,
            selectedParent,
            lstParentMail,
            dispatch,
        ]);

        /**
         * 親メールリスト取得
         * @param index_receive
         * @param index_send
         * @returns
         */
        async function getParentMailList(force: boolean | null = null): Promise<ParentMailModel[] | null> {
            if (props.loginUser == null || props.loginUser.id == null) {
                return null;
            }
            if (!hasNext.current && force !== true) {
                return null;
            }
            if (isUnderProcess.current) {
                return null;
            }
            if (force !== true) {
                if (limitUnixTime.current === 0) {
                    const tmpParentMailList = loadHistory();
                    if (tmpParentMailList != null) {
                        return tmpParentMailList;
                    }
                }
            } else {
                limitUnixTime.current = 0;
                prevLimitUnixTime.current = 0;
                hasNext.current = true;
            }
            if (isUnderProcess.current) {
                return null;
            }
            isUnderProcess.current = true;
            const limit = getNextLimitUnixTime(limitUnixTime.current);
            const prevLimit = limitUnixTime.current;
            prevLimitUnixTime.current = limitUnixTime.current;
            const tmpParentMailList = await getParentMailListExecute(
                limit,
                prevLimit
            );
            return tmpParentMailList;
        }

        /**
         * 親メールリスト取得実行
         * @param index_receive
         * @param index_send
         * @returns
         */
        async function getParentMailListExecute(
            limit_unixtime: number,
            prev_limit_unixtime: number
        ): Promise<ParentMailModel[] | null> {
            if (props.loginUser == null || props.loginUser.id == null) {
                return null;
            }
            isUnderProcess.current = false;
            const result = await MailRequest.getReceivedParentMailList(
                props.loginUser,
                limit_unixtime,
                prev_limit_unixtime
            );
            try {
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return null;    
                }
                if (result.rtnCd == null || result.rtnCd < 0) {
                    throw new Error();
                }
                // hasNext
                if (result.hasNext != null) {
                    hasNext.current = result.hasNext;
                }
                // limitUnixTime
                if (result.limitUnixTime != null) {
                    limitUnixTime.current = result.limitUnixTime;
                }
                // prevLimitUnixTime
                if (result.prevLimitUnixTime != null) {
                    prevLimitUnixTime.current = result.prevLimitUnixTime;
                }
                // lstParentMailReceive
                let wkParentMailList: ParentMailModel[] = [];
                if (result.lstParentMailReceive != null) {
                    result.lstParentMailReceive.forEach((parentMail) => {
                        parentMail.sortDate =
                            parentMail.updatedAtReceiverReceive;
                        wkParentMailList.push(parentMail);
                    });
                }
                // lstParentMailSend
                if (result.lstParentMailSend != null) {
                    result.lstParentMailSend.forEach((parentMail) => {
                        parentMail.sortDate = parentMail.updatedAtSenderReceive;
                        wkParentMailList.push(parentMail);
                    });
                }
                // ソート処理
                wkParentMailList.sort((a, b) => {
                    if (a.sortDate == null || b.sortDate == null) {
                        return 1;
                    } else {
                        const time1 = new Date(a.sortDate).getTime();
                        const time2 = new Date(b.sortDate).getTime();
                        return time1 > time2 ? -1 : 1;
                    }
                });
                wkParentMailList.forEach((parentMail) => {
                    if (props.loginUser != null && props.loginUser.id != null) {
                        if (props.loginUser.id === parentMail.receiverId) {
                            if (
                                parentMail.receiverUnreadCnt != null &&
                                parentMail.receiverUnreadCnt === 0
                            ) {
                                parentMail.read = true;
                            }
                        } else if (props.loginUser.id === parentMail.senderId) {
                            if (
                                parentMail.senderUnreadCnt != null &&
                                parentMail.senderUnreadCnt === 0
                            ) {
                                parentMail.read = true;
                            }
                        }
                    }
                });
                if (prev_limit_unixtime > 0 && lstParentMail != null) {
                    if (wkParentMailList != null) {
                        wkParentMailList = lstParentMail.concat(wkParentMailList);
                    }
                }
                setParentMailList(wkParentMailList);
                if (result.limitUnixTime != null &&
                    result.prevLimitUnixTime != null &&
                    result.hasNext != null) {

                    saveHistory(
                        result.limitUnixTime,
                        result.prevLimitUnixTime,
                        result.hasNext,
                        wkParentMailList,
                        true,
                        true
                    );
                }
                return wkParentMailList;
            } catch (e) {
                return null;
            } finally {
            }
        }

        /**
         * 履歴から受信メールリスト取得
         * @returns
         */
        function loadHistory(): ParentMailModel[] | null {
            if (history == null) {
                return null;
            }
            try {
                const expiredTime = Number(history.expiredTime);
                const now = new Date().getTime() / 1000;
                // セッションデータが10分経過してる場合は取得し直し
                if (isNaN(expiredTime) || now - expiredTime > 0) {
                    return null;
                }
            } catch (e) {
                console.error(e);
                return null;
            }
            limitUnixTime.current = Number(history.limitUnixTime);
            prevLimitUnixTime.current = Number(history.prevLimitUnixTime);
            hasNext.current = Boolean(history.hasNext);
            const wkReceivedMailList = history.list;
            if (wkReceivedMailList.length > 0) {
                setParentMailList(wkReceivedMailList);
                const scrollPos = Number(history.scrollPos);
                window.setTimeout(() => {
                    if (
                        refParentMailList != null &&
                        refParentMailList.current != null
                    ) {
                        refParentMailList.current.scrollTo({
                            top: scrollPos,
                            behavior: "auto",
                        });
                    }
                }, 500);
            } else {
                return null;
            }
            return wkReceivedMailList;
        }
        /**
         * 履歴保存
         * @param saveScrollPos
         */
        async function saveHistory(
            _limitUnixTime: number,
            _prevLimitUnixTime: number,
            _hasNext: boolean,
            _list: ParentMailModel[],
            _refresh_expired_time: boolean,
            _saveScrollPos: boolean
        ) {
            let expiredTime: number | null =
                new Date().getTime() / 1000 +
                AppConstants.STORAGE_EXPIRE_TIME_RECEIVED_MAIL_LIST;
            if (!_refresh_expired_time) {
                expiredTime = getExpiredTime();
                if (expiredTime == null) {
                    expiredTime =
                        new Date().getTime() / 1000 +
                        AppConstants.STORAGE_EXPIRE_TIME_RECEIVED_MAIL_LIST;
                }
            }
            let history = new HistoryReceivedMailList();
            history.expiredTime = expiredTime;
            history.limitUnixTime = _limitUnixTime;
            history.prevLimitUnixTime = _prevLimitUnixTime;
            history.hasNext = _hasNext;
            history.list = _list;
            history.scrollPos =
                _saveScrollPos === false ? 0 : getScrollPosition();
            dispatch(HistoryAction({ receivedMailListHistory: history }));
        }

        /**
         * 有効期限取得
         * @returns
         */
        function getExpiredTime(): number | null {
            if (history == null || history.expiredTime == null) {
                return null;
            }
            try {
                const expiredTime = Number(history.expiredTime);
                return expiredTime;
            } catch (e) {
                return null;
            }
        }

        /**
         * メール未読件数取得
         * @returns
         */
        async function getUnreadMailCount() {
            const unreadCount = await Utility.getUnreadMailCount(
                props.loginUser
            );
            dispatch(UnreadCountAction({ mail: unreadCount }));
        }

        function clearMailData() {
            refParentMailList.current = undefined;
            isUnderProcess.current = false;
            nowFetching.current = false;
            hasNext.current = true;
            limitUnixTime.current = 0;
            prevLimitUnixTime.current = 0;
            // setLoadedParentMail(false);
            // setParentMailList(undefined);
            // setSelectedParent(() => {
            //     return undefined;
            // });
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper ReceivedParentMail">
                {props.loginUser != null && loadedParentMail && (
                    <>
                        {lstParentMail != null && (
                            <div ref={container} className="contents-wrapper">
                                <div
                                    ref={(ref: HTMLDivElement) => {
                                        refParentMailList.current = ref;
                                        // setScrollTarget(ref);
                                    }}
                                    className={
                                        lstParentMail.length === 0
                                            ? "no-record parent-list-wrapper"
                                            : "parent-list-wrapper"
                                    }
                                >
                                    {lstParentMail.length === 0 && (
                                        <div className="no-received-page">
                                            <FormattedMessage
                                                id={
                                                    "msg_not_yet_exist_receive_message"
                                                }
                                            />
                                        </div>
                                    )}
                                    {langCd != null &&
                                        timeDifference != null &&
                                        lstParentMail.length > 0 &&
                                        lstParentMail.map((parentMail) => {
                                            return (
                                                <React.Fragment
                                                    key={parentMail.id}
                                                >
                                                    {!parentMail.hidden && (
                                                        <div className="item-wrapper">
                                                            <ReceivedParentMailListItem
                                                                parentMail={
                                                                    parentMail
                                                                }
                                                                read={
                                                                    parentMail.read
                                                                }
                                                                selected={
                                                                    selectedParent !=
                                                                        null &&
                                                                    parentMail.id ===
                                                                        selectedParent.id
                                                                }
                                                                loginUser={
                                                                    props.loginUser as UserModel
                                                                }
                                                                langCd={langCd}
                                                                timeDifference={
                                                                    timeDifference
                                                                }
                                                                onClick={onClickItem}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                                {lstParentMail.length > 0 && (
                                    <Box className="child-list-wrapper">
                                        {selectedParent != null &&
                                            selectedParent.id != null &&
                                            !isSizeXS && (
                                                <ReceivedChildMailComponent
                                                    loginUser={props.loginUser}
                                                    parentId={String(
                                                        selectedParent.id
                                                    )}
                                                    refreshTime={childRefreshTime}
                                                    deviceType={props.deviceType}
                                                    onRead={readMail}
                                                    onDelete={deleteMail}
                                                />
                                            )}
                                    </Box>
                                )}
                                <SendMessageDialog
                                    open={openSendMessageDialog}
                                    close={async () => {
                                        dispatch(
                                            SelectedItemAction({
                                                childMail: null,
                                                lstUser: null,
                                            })
                                        );
                                        setOpenSendMessageDialog(false);
                                    }}
                                    onSendCompleted={async () => {
                                        dispatch(
                                            SelectedItemAction({
                                                childMail: null,
                                                lstUser: null,
                                            })
                                        );
                                        setChildRefreshTime(new Date().getTime());
                                        setOpenSendMessageDialog(false);
                                    }}
                                    loginUser={props.loginUser}
                                    parentMail={
                                        isReply.current
                                            ? selectedParent
                                            : undefined
                                    }
                                />
                                <div className="icon-new-wrapper">
                                    <img
                                        className="new-message"
                                        src={WriteIcon}
                                        alt="Create Mail Button"
                                        onClick={onClickNewMessage}
                                    />
                                </div>
                                {lstParentMail != null &&
                                    lstParentMail.length > 0 && (
                                        <Box
                                            className={
                                                selectedParent == null ||
                                                selectedParent.senderId ===
                                                    DbConstants.SMMATE_STAFF_ID
                                                    ? "disabled icon-reply-wrapper"
                                                    : "icon-reply-wrapper"
                                            }
                                            sx={{
                                                display: {
                                                    xs: "none",
                                                    sm: "flex",
                                                },
                                                cursor:
                                                    selectedParent == null ||
                                                    selectedParent.senderId ===
                                                        DbConstants.SMMATE_STAFF_ID
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                        >
                                            <ReplyIcon
                                                style={{
                                                    width: "80%",
                                                    height: "80%",
                                                    color:
                                                        selectedParent ==
                                                            null ||
                                                        selectedParent.senderId ===
                                                            DbConstants.SMMATE_STAFF_ID
                                                            ? "#aaaaaa"
                                                            : "#0978f6",
                                                }}
                                                onClick={onClickReplyMessage}
                                            />
                                        </Box>
                                    )}
                                {/* <Zoom in={trigger}>
                                    <Box
                                        className="scroll-trigger"
                                        role="presentation"
                                    >
                                        <Fab
                                            onClick={scrollToTop}
                                            color="primary"
                                            size="small"
                                            aria-label="scroll back to top"
                                        >
                                            <KeyboardArrowUp />
                                        </Fab>
                                    </Box>
                                </Zoom> */}
                            </div>
                        )}
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.selectedTab !== nextProps.selectedTab) {
            return false;
        }
        if (prevProps.refreshTime !== nextProps.refreshTime) {
            return false;
        }
        return true;
    }
);

export default ReceivedParentMail;
