import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
    Link
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getDeviceType } from "redux/Selectors";
import { UserModel } from "models/Models";
import { pushDataLayer } from "gtm/gtm"

import "styles/pages/TermsOfUse.scss";

type Props = {
};

const TermsOfUse: React.FC<Props> = React.memo(
    (props) => {
        /***** 定数、変数 */
        const intl = useIntl();
        const deviceType: number | null = useSelector((state) => getDeviceType(state));

        /***** useRef */

        /***** useState */
        const [articleTitle1, setArticleTitle1] = React.useState<string>("")
        const [articleContents1, setArticleContents1] = React.useState<string>("")
        const [articleTitle2, setArticleTitle2] = React.useState<string>("")
        const [articleContents2, setArticleContents2] = React.useState<string>("")
        const [articleTitle3, setArticleTitle3] = React.useState<string>("")
        const [articleContents3, setArticleContents3] = React.useState<string>("")
        const [articleTitle4, setArticleTitle4] = React.useState<string>("")
        const [articleContents4, setArticleContents4] = React.useState<string>("")
        const [articleTitle5, setArticleTitle5] = React.useState<string>("")
        const [articleContents5, setArticleContents5] = React.useState<string>("")
        const [articleTitle6, setArticleTitle6] = React.useState<string>("")
        const [articleContents6, setArticleContents6] = React.useState<string>("")
        const [articleTitle7, setArticleTitle7] = React.useState<string>("")
        const [articleContents7, setArticleContents7] = React.useState<string>("")
        const [articleTitle8, setArticleTitle8] = React.useState<string>("")
        const [articleContents8, setArticleContents8] = React.useState<string>("")
        const [articleTitle9, setArticleTitle9] = React.useState<string>("")
        const [articleContents9, setArticleContents9] = React.useState<string>("")
        const [articleTitle10, setArticleTitle10] = React.useState<string>("")
        const [articleContents10, setArticleContents10] = React.useState<string>("")
        const [articleTitle11, setArticleTitle11] = React.useState<string>("")
        const [articleContents11, setArticleContents11] = React.useState<string>("")
        const [articleTitle12, setArticleTitle12] = React.useState<string>("")
        const [articleContents12, setArticleContents12] = React.useState<string>("")
        const [articleTitle13, setArticleTitle13] = React.useState<string>("")
        const [articleContents13, setArticleContents13] = React.useState<string>("")
        const [articleTitle14, setArticleTitle14] = React.useState<string>("")
        const [articleContents14, setArticleContents14] = React.useState<string>("")
        const [articleTitle15, setArticleTitle15] = React.useState<string>("")
        const [articleContents15, setArticleContents15] = React.useState<string>("")

        /**
         * 初期処理
         */
        const initialProcess = React.useCallback(() => {
            let title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_1"})
            let contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_1"})
            setArticleTitle1(title);
            setArticleContents1(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_2"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_2"});
            setArticleTitle2(title);
            setArticleContents2(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_3"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_3"});
            setArticleTitle3(title);
            setArticleContents3(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_4"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_4"});
            setArticleTitle4(title);
            setArticleContents4(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_5"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_5"});
            setArticleTitle5(title);
            setArticleContents5(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_6"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_6"});
            setArticleTitle6(title);
            setArticleContents6(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_7"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_7"});
            setArticleTitle7(title);
            setArticleContents7(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_8"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_8"});
            setArticleTitle8(title);
            setArticleContents8(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_9"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_9"});
            setArticleTitle9(title);
            setArticleContents9(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_10"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_10"});
            setArticleTitle10(title);
            setArticleContents10(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_11"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_11"});
            setArticleTitle11(title);
            setArticleContents11(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_12"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_12"});
            setArticleTitle12(title);
            setArticleContents12(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_13"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_13"});
            setArticleTitle13(title);
            setArticleContents13(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_14"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_14"});
            setArticleTitle14(title);
            setArticleContents14(contents);

            title = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_TITLE_15"});
            contents = intl.formatMessage({id: "TERMS_OF_USE_ARTICLE_CONTENTS_15"});
            setArticleTitle15(title);
            setArticleContents15(contents);

        }, [intl]);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "利用規約",
                path: window.location.pathname,
                deviceType: deviceType,
            })            
        }, [deviceType]);
        React.useEffect(() => {
            initialProcess()
        }, [initialProcess]);


        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper TermsOfUse">
                <h1>
                    <FormattedMessage id="TERMS_OF_USE_PAGE_TITLE" />
                </h1>
                <ul>
                    <li>
                        <p>{articleTitle1}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents1}} />
                    </li>
                    <li>
                        <p>{articleTitle2}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents2}} />
                    </li>
                    <li>
                        <p>{articleTitle3}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents3}} />
                    </li>
                    <li>
                        <p>{articleTitle4}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents4}} />
                    </li>
                    <li>
                        <p>{articleTitle5}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents5}} />
                    </li>
                    <li>
                        <p>{articleTitle6}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents6}} />
                    </li>
                    <li>
                        <p>{articleTitle7}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents7}} />
                    </li>
                    <li>
                        <p>{articleTitle8}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents8}} />
                    </li>
                    <li>
                        <p>{articleTitle9}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents9}} />
                    </li>
                    <li>
                        <p>{articleTitle10}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents10}} />
                    </li>
                    <li>
                        <p>{articleTitle11}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents11}} />
                    </li>
                    <li>
                        <p>{articleTitle12}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents12}} />
                    </li>
                    <li>
                        <p>{articleTitle13}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents13}} />
                    </li>
                    <li>
                        <p>{articleTitle14}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents14}} />
                    </li>
                    <li>
                        <p>{articleTitle15}</p>
                        <span dangerouslySetInnerHTML={{__html: articleContents15}} />
                    </li>
                </ul>
                <p id="terms_date">
                    <FormattedMessage id={"TERMS_OF_USE_DATE"} />
                </p>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        return true;
    }
);
export default TermsOfUse;
