import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import createStore from "./redux/store/store";
import {
    getLanguageCode
} from "redux/Selectors";
import {
    LanguageAction,
} from "redux/Actions";
import { ThemeProvider } from "@emotion/react";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createTheme } from "@mui/material/styles";
import { AppConstants, MODE } from "constants/AppConstants";
import jaJson from "lang/ja.json";
import enJson from "lang/en.json";
import cnJson from "lang/cn.json";
import twJson from "lang/tw.json";
import esJson from "lang/es.json";
import deJson from "lang/de.json";
import itJson from "lang/it.json";
import ruJson from "lang/ru.json";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import Utility from "utils/Utility";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);
const store = createStore();
const theme = createTheme({
    palette: {
        primary: {
            main: AppConstants.COLOR_PRIMARY,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const RootComponent = () => {
    const dispatch = useDispatch();

    var lang = useSelector((state) => getLanguageCode(state));
    if (lang == null) {
        lang = getBrowserLanguage();
        dispatch(
            LanguageAction({
                code: lang,
            })
        );
    }
    function getBrowserLanguage(): string {
        const code = Utility.getLanguageCode();
        return code;
    }
    function getMessageFile() {
        if (lang === "ja") {
            return jaJson;
        } else if (lang === "cn") {
            return cnJson;
        } else if (lang === "tw") {
            return twJson;
        } else if (lang === "es") {
            return esJson;
        } else if (lang === "de") {
            return deJson;
        } else if (lang === "ru") {
            return ruJson;
        } else if (lang === "it") {
            return itJson;
        } else {
            return enJson;
        }
    }

    return (
        // <React.StrictMode>
        <ThemeProvider theme={theme}>
            <IntlProvider locale={navigator.language} messages={getMessageFile()}>
                <App />
            </IntlProvider>
        </ThemeProvider>
        // </React.StrictMode>
    );
}
root.render(
  <Provider store={store}>
      <RootComponent/>
  </Provider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if ('serviceWorker' in navigator) {
  if (AppConstants.mode === MODE.PRODUCTION) {
    // serviceWorkerRegistration.register();
    serviceWorkerRegistration.unregister();
  } else {
    // serviceWorkerRegistration.register();
    serviceWorkerRegistration.unregister();
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
