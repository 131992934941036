import { AppConstants } from "constants/AppConstants";

const initialState = {
    footerMenu: {
        selectedFooterMenu: AppConstants.FOOTER_MENU_NONE,
    },
    user: {
        loginUser: null,
        lstOwnGroup: null,
        lstBlockUser: null,
        lstFollowUser: null,
        lstTag: null,
        lstCustomTag: null,
        lstInvite: null,
    },
    timeDifference: {
        difference: 0,
    },
    maintenance: {
        isMaintenance: false,
    },
    network: {
        connected: true,
    },
    language: {
        code: null,
    },
    appInfo: {
        deviceType: null,
        deviceToken: null,
        majorVersion: null,
        minorVersion: null,
        buildNo: null,
        grantedCamera: null,
        grantedAudio: null,
    },
    process: {
        processing: false,
        message: "",
    },
    selectedItem: {
        userId: 0,
        user: null,
        group: null,
        parentMail: null,
        childMail: null,
        talkUser: null,
        album: null,
        lstUser: null,
        lstGroup: null,
        albumName: null,
        albumPublicSetting: null,
        albumTemplateId: null,
        country: null,
        category: null,
    },
    workingItem: {
        register: null,
        album: null,
        profile: null,
        mail: null,
    },
    history: {
        userListHistory: null,
        receivedMailListHistory: null,
        sentMailListHistory: null,
    },
    unreadCount: {
        mail: 0,
        talk: 0,
    },
    emitter: {
        instance: null,
    },
    readSize: {
        USER_LIST: 100,
        GROUP_LIST: 100,
        MESSAGEBOARD_LIST: 100,
        PARENT_MAIL_LIST: 100,
        GROUP_MEMBER_LIST: 100,
        GROUP_CHAT_LIST: 100,
        TALK_USER_LIST: 100,
        TALK_MESSAGE_LIST: 100,
        TWEET_LIST: 100,
    },
    albumTemplate: {
        list: null,
    },
    tagList: {
        list: null,
    },
    countryList: {
        list: null,
    },
    categoryList: {
        list: null,
    },
    rtcInfo: {
        senderId: null,
        receiverId: null,
        senderTalkType: null,
        receiverTalkType: null,
    },
    shoot: {
        photoId: null,
        videoId: null,
    }
};

export default initialState;
