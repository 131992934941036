import { createSelector } from "reselect";

const appInfoSelector = (state) => state.appInfo;

export const getDeviceType = createSelector(
    [appInfoSelector],
    (state) => state.deviceType
);
export const getDeviceToken = createSelector(
    [appInfoSelector],
    (state) => state.deviceToken
);
export const getMajorVersion = createSelector(
    [appInfoSelector],
    (state) => state.majorVersion
);
export const getMinorVersion = createSelector(
    [appInfoSelector],
    (state) => state.minorVersion
);
export const getBuildNo = createSelector(
    [appInfoSelector],
    (state) => state.buildNo
);
export const isGrantedCamera = createSelector(
    [appInfoSelector],
    (state) => state.grantedCamera
);
export const isGrantedAudio = createSelector(
    [appInfoSelector],
    (state) => state.grantedAudio
);
