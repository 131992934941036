import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { getDeviceType } from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"

import "styles/pages/ChildSafety.scss";

type Props = {
};

const ChildSafety: React.FC<Props> = React.memo(
    (props) => {
        /***** 定数、変数 */
        const intl = useIntl();
        const deviceType: number | null = useSelector((state) => getDeviceType(state));

        /***** useRef */

        /***** useState */

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "子どもの安全",
                path: window.location.pathname,
                deviceType: deviceType,
            })            
        }, [deviceType]);


        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper ChildSafety">
                <h1>
                    <FormattedMessage id="CHILD_SAFETY_TITLE" />
                </h1>
                <div className="headline">
                    <FormattedMessage id="CHILD_SAFETY_HEADLINE" />
                </div>
                <div className="counter-area">
                    <div className="label">
                        <FormattedMessage id="CHILD_SAFETY_COUNTER_LABEL" />
                    </div>
                    <div className="title">
                        <FormattedMessage id="CHILD_SAFETY_COUNTER_1_TITLE" />
                    </div>
                    <div className="contents">
                        <FormattedMessage id="CHILD_SAFETY_COUNTER_1_CONTENTS" />
                        <a href="mailto:smmate2012@gmail.com">smmate2012@gmail.com</a>
                    </div>
                    <div className="title">
                        <FormattedMessage id="CHILD_SAFETY_COUNTER_2_TITLE" />
                    </div>
                    <div className="contents">
                        <FormattedMessage id="CHILD_SAFETY_COUNTER_2_CONTENTS" />
                    </div>
                </div>
                <div className="procedure-area">
                    <div className="label">
                        <FormattedMessage id="CHILD_SAFETY_PROCEDURE_LABEL" />
                    </div>
                    <div className="contents">
                        <FormattedMessage 
                            id="CHILD_SAFETY_PROCEDURE_CONTENTS"
                            defaultMessage={intl.formatMessage({id: "CHILD_SAFETY_PROCEDURE_CONTENTS"})}
                            values={{br: <br />}}
                        />
                    </div>
                </div>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        return true;
    }
);
export default ChildSafety;
