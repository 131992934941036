import axios, { AxiosResponse } from "axios";
import { UrlConstants } from "constants/UrlConstants";
import { CountryListResponse } from "api/responses/Responses";

export class CountryRequest {
    /**
     * 国リスト取得
     * @returns
     */
    static async getList(langCd: string) {
        let url = UrlConstants.URL_COUNTRY_LIST;
        url = url + "?lang=" + langCd
        let result = null;
        try {
            let res: AxiosResponse = await axios.get(url, {
                withCredentials: true,
            });
            if (res != null) {
                result = new CountryListResponse(res.data);
            }
        } catch (error) {
            console.error(error);
        }
        return result;
    }
}
