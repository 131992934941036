export const APP_INFO = "APP_INFO";

export const AppInfoAction = (appInfoState) => {
    let payload = {};
    if (appInfoState.hasOwnProperty("deviceType")) {
        payload["deviceType"] = appInfoState.deviceType;
    }
    if (appInfoState.hasOwnProperty("deviceToken")) {
        payload["deviceToken"] =
            appInfoState.deviceToken;
    }
    if (appInfoState.hasOwnProperty("majorVersion")) {
        payload["majorVersion"] =
            appInfoState.majorVersion;
    }
    if (appInfoState.hasOwnProperty("minorVersion")) {
        payload["minorVersion"] =
            appInfoState.minorVersion;
    }
    if (appInfoState.hasOwnProperty("buildNo")) {
        payload["buildNo"] =
            appInfoState.buildNo;
    }
    if (appInfoState.hasOwnProperty("grantedCamera")) {
        payload["grantedCamera"] =
            appInfoState.grantedCamera;
    }
    if (appInfoState.hasOwnProperty("grantedAudio")) {
        payload["grantedAudio"] =
            appInfoState.grantedAudio;
    }
    return {
        type: APP_INFO,
        payload: payload,
    };
};
