import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { EventSubscription } from "fbemitter";
import {
    NetworkAction,
} from "redux/Actions";
import {
    getEmitter,
    isGrantedCamera,
    isGrantedAudio
} from "redux/Selectors";
import { UserModel, TalkUserModel, TalkPermissionModel } from "models/Models";
import { NavBar, UserChatComponent, UserTalkComponent, OkDialog } from "./Components";
import {
    UserRequest,
    TalkPermissionRequest,
} from "api/requests/Requests";
import { DbConstants } from "constants/Constants";
import { AppServer } from "socket/AppServer";
import {USER_CHAT_COMPONENT_STATUS, USER_TALK_COMPONENT_STATUS} from "constants/Enum";
import Utility from "utils/Utility";
// import VoiceCalling from "assets/images/voice_calling.png";
// import VideoCalling from "assets/images/video_calling.png";
import VoiceCalling from "assets/images/voice_calling.svg";
import VideoCalling from "assets/images/video_calling.svg";

import "styles/components/UserCommunicationComponent.scss";
import { UserTalkHandler } from "handlers/handler";
import { TALK_TYPE } from "constants/Enum";

type Props = {
    loginUser: UserModel;
    talkUser: TalkUserModel | null | undefined;
    isSizeXS: boolean
    deviceType: number | null;
};

export const UserCommunicationComponent: React.FC<Props> = React.memo(
    (props) => {
        Utility.log("@@@@@ UserCommunicationComponent IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const href = window.location.href;
        const grantedCamera: boolean | null = useSelector((state) => isGrantedCamera(state));
        const grantedAudio: boolean | null = useSelector((state) => isGrantedAudio(state));
        
        // EventEmitter
        const emitter = useSelector((state) => getEmitter(state));

        /***** useRef */
        // トークコンポーネント
        const refUserTalkComponent = React.useRef<UserTalkHandler | null>(null);
        const refBlocked = React.useRef<boolean | null>(null);
        const refTalkPermitted = React.useRef<boolean | null>(null);
        const refAnchorTouchDown = React.useRef<boolean>(false);
        const refUserTalkType = React.useRef<TALK_TYPE>(TALK_TYPE.NONE);
        // EventEmitterの購読
        const lstSubscription = React.useRef<EventSubscription[]>();

        /***** useState */
        const [appServer, setAppServer] = React.useState<AppServer>();
        const [partner, setPartner] = React.useState<UserModel>();
        const [userChatComponentStatus, setUserChatComponentStatus] = React.useState<USER_CHAT_COMPONENT_STATUS>(USER_CHAT_COMPONENT_STATUS.VISIBLE);
        const [userTalkComponentStatus, setUserTalkComponentStatus] = React.useState<USER_TALK_COMPONENT_STATUS>(USER_TALK_COMPONENT_STATUS.HIDDEN);
        // ダイアログメッセージ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>("");


        const onGrantAppPermission = React.useCallback((receiverId: number) => {
            if (refUserTalkComponent == null ||
                refUserTalkComponent.current == null
            ) {
                return;
            }
            navigate("/talk/call/" + receiverId);
            refUserTalkComponent.current.onReceivedResponseForConfirmConnectStatus();   
        }, [navigate]);

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * 接続状況確認の返答受信時
         * @param obj
         */
        const onReceivedResponseForConfirmConnectStatus = React.useCallback(async(obj: any) => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            const senderId = obj.sender_id;
            const receiverId = obj.receiver_id;
            const connected = obj.connected;
            if (senderId == null || receiverId == null || connected == null) {
                return;
            }
            if (!connected) {
                const message = intl.formatMessage({
                    id: "partner_is_not_online",
                });
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            const result = await UserRequest.getUser(parseInt(receiverId));
            if (result == null || result.user == null) {
                return;
            }
            const isPartnerTalkAvailable = Utility.isTalkAvailable(result.user);
            if (isPartnerTalkAvailable) {
                if (props.deviceType === DbConstants.DEVICE_TYPE_BROWSER) {
                    navigate("/talk/call/" + receiverId);
                    refUserTalkComponent.current.onReceivedResponseForConfirmConnectStatus();           
                } else if (props.deviceType === DbConstants.DEVICE_TYPE_ANDROID) {
                    if (partner == null || partner.id == null) {
                        return;
                    }
                    if (window.AndroidInterface) {
                        if (grantedCamera && grantedAudio) {
                            navigate("/talk/call/" + receiverId);
                            refUserTalkComponent.current.onReceivedResponseForConfirmConnectStatus();           
                        } else {
                            const obj = {
                                talk_type: refUserTalkType.current,
                                callback: "request_talk",
                                receiver_id: partner.id
                            }
                            if (!grantedCamera) {
                                window.AndroidInterface.requestCameraPermission(JSON.stringify(obj));
                            } else if (!grantedAudio) {
                                window.AndroidInterface.requestAudioPermission(JSON.stringify(obj));
                            }
                        }
                    }
                } else if (props.deviceType === DbConstants.DEVICE_TYPE_IOS) {
                    // <TODO>
                }
            } else {
                const message = intl.formatMessage({
                    id: "err_talk_call_device_type",
                });
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
        }, [
            intl,
            props.deviceType,
            grantedAudio,
            grantedCamera,
            partner,
            navigate,
        ]);

        /**
         * EventEmitterから呼び出されるため、State値は参照できない
         * トーク要求に対する返信の受信
         * @param obj
         * @returns
         */
        const onReceivedResponseOfTalkRequest = React.useCallback((obj: any) => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            if (partner == null) {
                return;
            }

            const senderId = obj.sender_id;
            const receiverId = obj.receiver_id;
            const partnerTalkType = obj.talk_type;
            const accepted = obj.accepted;
            const onTalking = obj.on_talking;
            if (
                props.loginUser == null ||
                senderId == null ||
                receiverId == null ||
                partnerTalkType == null ||
                accepted == null
            ) {
                return;
            }
            if (props.loginUser.id !== senderId) {
                return;
            }
            if (partner.id !== receiverId) {
                return;
            }
            if (onTalking) {
                refUserTalkComponent.current.onReceivedPartnerTalkingResponse(receiverId);
                let message = intl.formatMessage({id: "msg_on_talking"});
                if (partner.name != null) {
                    message = intl.formatMessage({id: "msg_partner_talking"});
                    message = message.replace("USER", partner.name);
                }    
                setDialogMessage(message);
                setOpenDialog(true);
                return;
            }
            if (!accepted) {
                if (
                    obj.hasOwnProperty("blocked") &&
                    obj.hasOwnProperty("blocked") === true
                ) {
                    const message = intl.formatMessage({
                        id: "msg_you_are_blocked",
                    });
                    setDialogMessage(message);
                    setOpenDialog(true);
                } else {
                    const message = intl.formatMessage({
                        id: "msg_rejected_calling_request",
                    });
                    setDialogMessage(message);
                    setOpenDialog(true);
                }
                refUserTalkComponent.current.onReceivedRejectTalkResponse(receiverId);
                navigate(-1);
                return;
            }
            refUserTalkComponent.current.onReceivedResponseOfTalkRequest(partnerTalkType);
        }, [
            intl,
            navigate,
            props.loginUser,
            partner
        ]);

        const onReceivedStartTalk = React.useCallback((obj: any) => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            if (Object.keys(obj).indexOf("sender_id") === -1) {
                return;
            }
            if (Object.keys(obj).indexOf("receiver_id") === -1) {
                return;
            }
            const receiverId = obj.receiver_id;            
            if (props.loginUser.id !== receiverId) {
                return;
            }

            if (Object.keys(obj).indexOf("sender_talk_type") === -1) {
                return;
            }
            const senderTalkType = obj.sender_talk_type;

            if (Object.keys(obj).indexOf("receiver_talk_type") === -1) {
                return;
            }
            const receiverTalkType = obj.receiver_talk_type;
            refUserTalkComponent.current.onReceivedStartTalk(
                senderTalkType,
                receiverTalkType
            );
        }, [props.loginUser]);

        /**
         * パートナーがトーク開始失敗した通知の受信時
         */
        const onReceivedTalkFailureNotification = React.useCallback((obj: any) => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            if (partner == null) {
                return;
            }
            if (Object.keys(obj).indexOf("sender_id") === -1) {
                return;
            }
            const senderId = obj.sender_id;
            if (Object.keys(obj).indexOf("receiver_id") === -1) {
                return;
            }
            if (senderId !== partner.id) {
                return;
            }
            const receiverId = obj.receiver_id;
            if (receiverId !== props.loginUser.id) {
                return;
            }
            refUserTalkComponent.current.onReceivedTalkFailureNotification();
        }, [
            props.loginUser,
            partner
        ]);

        /**
         * EventEmitterのイベント登録
         * @returns
         */
        const registerEmitterListener = React.useCallback(() => {
            if (emitter == null) {
                return;
            }
            // トーク要求の相手の接続確認
            const s1 = emitter.addListener(
                "RESPONSE_FOR_CONFIRM_CONNECT_STATUS",
                onReceivedResponseForConfirmConnectStatus
            );
            // トーク要求に対する応答
            const s2 = emitter.addListener(
                "ON_RECEIVE_RESPONSE_OF_TALK_REQUEST",
                onReceivedResponseOfTalkRequest
            );
            // トーク開始通知
            const s3 = emitter.addListener(
                "START_TALK",
                onReceivedStartTalk
            );
            const s4 = emitter.addListener(
                "TALK_FAILURE_NOTIFICATION",
                onReceivedTalkFailureNotification
            );
            const s5 = emitter.addListener(
                "ON_GRANT_APP_PERMISSION",
                onGrantAppPermission
            );
            lstSubscription.current = [s1, s2, s3, s4, s5];
        }, [
            emitter,
            onReceivedResponseForConfirmConnectStatus,
            onReceivedResponseOfTalkRequest,
            onReceivedStartTalk,
            onReceivedTalkFailureNotification,
            onGrantAppPermission,
        ]);

        /**
         * EventEmitterのイベント解除
         */
        const removeEmitterListener = React.useCallback(() => {
            if (lstSubscription != null && lstSubscription.current != null) {
                for (let i = 0; i < lstSubscription.current.length; i++) {
                    lstSubscription.current[i].remove();
                }
            }
            if (emitter != null) {
                emitter.removeAllListeners("RESPONSE_FOR_CONFIRM_CONNECT_STATUS");
                emitter.removeAllListeners("ON_RECEIVE_RESPONSE_OF_TALK_REQUEST");
                emitter.removeAllListeners("START_TALK");
                emitter.removeAllListeners("TALK_FAILURE_NOTIFICATION");
                emitter.removeAllListeners("ON_GRANT_APP_PERMISSION");
            }
        }, [emitter]);

        /***** useEffect */
        React.useEffect(() => {
            if (appServer == null) {
                setAppServer(AppServer.instance);
            }
            // EventEmitterを登録
            registerEmitterListener();

            // デストラクタ
            return () => {
                // EventEmitterを解除
                removeEmitterListener();
            };
        }, [
            appServer,
            registerEmitterListener,
            removeEmitterListener
        ]);
        // React.useEffect(() => {
        //     refAppPermissionList.current = [];
        //     if (grantedCamera === true) {
        //         const permissionList = refAppPermissionList.current.filter((permission: APP_PERMISSION) => {
        //             if (permission === APP_PERMISSION.CAMERA) {
        //                 return false;
        //             } else {
        //                 return true;
        //             }
        //         });
        //         refAppPermissionList.current = permissionList;
        //     } else {
        //         let exist = false;
        //         for (let i=0; i<refAppPermissionList.current.length; i++) {
        //             const permission = refAppPermissionList.current[0];
        //             if (permission === APP_PERMISSION.CAMERA) {
        //                 exist = true;
        //                 break;
        //             }
        //         }
        //         if (!exist) {
        //             refAppPermissionList.current.push(APP_PERMISSION.CAMERA);
        //         }
        //     }
        // }, [
        //     grantedCamera,
        // ]);
        // React.useEffect(() => {
        //     refAppPermissionList.current = [];
        //     if (grantedAudio !== true) {
        //         let exist = false;
        //         for (let i=0; i<refAppPermissionList.current.length; i++) {
        //             const permission = refAppPermissionList.current[0];
        //             if (permission === APP_PERMISSION.AUDIO) {
        //                 exist = true;
        //                 break;
        //             }
        //         }
        //         if (!exist) {
        //             refAppPermissionList.current.push(APP_PERMISSION.AUDIO);
        //         }
        //     } else {
        //         const permissionList = refAppPermissionList.current.filter((permission: APP_PERMISSION) => {
        //             if (permission === APP_PERMISSION.AUDIO) {
        //                 return false;
        //             } else {
        //                 return true;
        //             }
        //         });
        //         refAppPermissionList.current = permissionList;
        //     }
        // }, [
        //     grantedAudio,
        // ]);
        // React.useEffect(() => {
        //     if (grantedCamera != null) {
        //         refGrantedCameraRequest.current = grantedCamera;
        //         if (grantedCamera !== true) {
        //             refAppPermissionList.current.push(APP_PERMISSION.CAMERA);
        //         }
        //     }
        //     if (grantedAudio != null) {
        //         refGrantedAudioRequest.current = grantedAudio;
        //         if (grantedAudio !== true) {
        //             refAppPermissionList.current.push(APP_PERMISSION.AUDIO);
        //         }
        //     }
        // }, [
        //     grantedCamera,
        //     grantedAudio
        // ]);
        React.useEffect(() => {
            Utility.log("UserCommunicationComponent useEffect 1");
            window.setTimeout(() => {
                if (props.talkUser != null && props.talkUser.partner != null) {
                    setPartner(props.talkUser.partner);
                } else {
                    setPartner(undefined);
                }
                const pathname = window.location.pathname;
                if (pathname === "/talk") {
                    setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.VISIBLE);
                    setUserTalkComponentStatus(USER_TALK_COMPONENT_STATUS.HIDDEN);
                } else {
                    if (pathname.includes("/talk/call")) {
                        setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.HIDDEN);
                        setUserTalkComponentStatus(USER_TALK_COMPONENT_STATUS.VISIBLE);
                    } else if (pathname.includes("/talk/chat")) {
                        setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.VISIBLE);
                        setUserTalkComponentStatus(USER_TALK_COMPONENT_STATUS.HIDDEN);
                    }
                }
    
                refBlocked.current = null;
                refTalkPermitted.current = null;
            });
        }, [href, props.talkUser]);

        /**
         * ブロックされてるかどうか
         */
        const checkBlocked = React.useCallback(async(): Promise<boolean | null> => {
            if (partner == null) {
                return null;
            }
            const userId = props.loginUser.id;
            const targetId = partner.id;
            if (userId == null || targetId == null) {
                return null;
            }
            const result = await UserRequest.isBlocked(
                props.loginUser,
                targetId
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            return result.blocked;
        }, [
            dispatch,
            navigate,
            props.loginUser,
            partner,
        ]);
        
        /**
         * 選択パートナーのトーク許可情報取得
         */
        const fetchPartnerTalkPermission = React.useCallback(async(target: UserModel): Promise<TalkPermissionModel | null> => {
            const result = await TalkPermissionRequest.getPermission(
                props.loginUser,
                target
            )
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
                if (result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            return result.talkPermission;
        }, [
            dispatch,
            navigate,
            props.loginUser,
        ]);

        /**
         * 通話前チェック
         * @returns
         */
        const checkTalkPermission = React.useCallback(async(): Promise<boolean> => {
            Utility.log("checkTalkPermission IN");
            if (props.loginUser == null || props.loginUser.id == null || props.talkUser == null) {
                return false;
            }
            if (
                partner == null ||
                partner == null ||
                partner.id == null ||
                partner.name == null
            ) {
                return false;
            }
            // ブロックされてるかどうか確認
            if (refBlocked == null || refBlocked.current == null) {
                refBlocked.current = await checkBlocked();
            }
            if (refBlocked.current) {
                const message = intl.formatMessage({ id: "err_blocked" });
                setDialogMessage(message);
                setOpenDialog(true);
                return false;
            }
            // トーク許可チェック
            if (refTalkPermitted == null || refTalkPermitted.current == null) {
                const talkPermission = await fetchPartnerTalkPermission(partner);
                if (talkPermission == null || !talkPermission.permitted) {
                    refTalkPermitted.current = false;
                } else {
                    refTalkPermitted.current = true;
                }
            }
            if (!refTalkPermitted.current) {
                let message = intl.formatMessage({ id: "partner_not_permitted_talk" });
                message = message.replace("USER", partner.name)
                setDialogMessage(message);
                setOpenDialog(true);
                return false;
            }
            // パートナーのAppサーバー接続確認
            if (appServer == null) {
                return false;
            }

            return true;
        }, [
            intl,
            props.loginUser,
            props.talkUser,
            partner,
            appServer,
            checkBlocked,
            fetchPartnerTalkPermission,
        ]);

        const partnerNameClass = React.useMemo((): string => {
            if (partner == null) {
                return "";
            }
            if (partner.gender === DbConstants.GENDER_MALE) {
                return "partner-name-area male";
            } else if (partner.gender === DbConstants.GENDER_FEMALE) {
                return "partner-name-area female";
            } else {
                return "partner-name-area other";
            }
        }, [partner]);

        const userChatComponentClass = React.useMemo(() => {
            let className = "component-wrapper chat";
            switch (userChatComponentStatus) {
                case USER_CHAT_COMPONENT_STATUS.VISIBLE:
                    className = className + " visible";
                    break;
                case USER_CHAT_COMPONENT_STATUS.HIDDEN:
                    className = className + " hidden";
                    break;
                case USER_CHAT_COMPONENT_STATUS.RIGHT_HIDE:
                    className = className + " right-hide";
                    break;
                case USER_CHAT_COMPONENT_STATUS.OVERLAP:
                    className = className + " overlap";
                    break;
            }
            return className;
        }, [userChatComponentStatus]);
        const userTalkComponentClass = React.useMemo(() => {
            let className = "component-wrapper talk";
            switch (userTalkComponentStatus) {
                case USER_TALK_COMPONENT_STATUS.VISIBLE:
                    className = className + " visible";
                    break;
                case USER_TALK_COMPONENT_STATUS.HIDDEN:
                    className = className + " hidden";
                    break;
            }
            return className;
        }, [userTalkComponentStatus]);

        const onClickVoiceCall = React.useCallback(async() => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            refUserTalkType.current = TALK_TYPE.VOICE;
            if (await checkTalkPermission()) {
                const loginUserId = props.loginUser.id;
                const partnerId = partner?.id;
                if (appServer == null || loginUserId == null || partnerId == null) {
                    return;
                }
                refUserTalkComponent.current.setTalkType(TALK_TYPE.VOICE);
                appServer.sendConnectStatusConfirmationToAppServer(
                    loginUserId,
                    partnerId
                );
            }
        }, [
            props.loginUser,
            appServer,
            checkTalkPermission,
            partner,
        ]);
        const onClickVideoCall = React.useCallback(async() => {
            if (refUserTalkComponent == null || refUserTalkComponent.current == null) {
                return;
            }
            refUserTalkType.current = TALK_TYPE.VIDEO;
            if (await checkTalkPermission()) {
                const loginUserId = props.loginUser.id;
                const partnerId = partner?.id;
                if (appServer == null || loginUserId == null || partnerId == null) {
                    return;
                }
                refUserTalkComponent.current.setTalkType(TALK_TYPE.VIDEO);
                appServer.sendConnectStatusConfirmationToAppServer(
                    loginUserId,
                    partnerId
                );
            }
        }, [
            props.loginUser,
            appServer,
            checkTalkPermission,
            partner,
        ]);

        /**
         * トーク開始コールバック
         */
        const onTalkStarted = React.useCallback(() => {
            setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.RIGHT_HIDE);
        }, []);

        const onClickCloseChat = React.useCallback(() => {
            setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.RIGHT_HIDE);
        }, []);
        const onClickOpenChat = React.useCallback(() => {
            setUserChatComponentStatus(USER_CHAT_COMPONENT_STATUS.OVERLAP);
        }, []);
        const onTouchDownAnchor = React.useCallback(() => {
            refAnchorTouchDown.current = true;
        }, []);
        const onTouchEndAnchor = React.useCallback(() => {
            refAnchorTouchDown.current = false;
        }, []);
        const onTouchMoveAnchor = React.useCallback(() => {
            if (refAnchorTouchDown.current) {
                refAnchorTouchDown.current = false;
                if (userChatComponentStatus === USER_CHAT_COMPONENT_STATUS.RIGHT_HIDE) {
                    onClickOpenChat();
                } else if (userChatComponentStatus === USER_CHAT_COMPONENT_STATUS.OVERLAP) {
                    onClickCloseChat();
                }
            }
        }, [
            userChatComponentStatus,
            onClickOpenChat,
            onClickCloseChat,
        ]);

        /**
         * レンダリング
         */
        return (
            <Box className={partner == null ? "component UserCommunicationComponent blank" : "component UserCommunicationComponent"}>
                {props.loginUser != null &&
                 props.loginUser.id != null &&
                partner != null &&
                partner.name != null &&
                partner.name.length > 0 &&
                partner.gender != null && (
                    <>
                        {
                            // ナビゲーションバー
                        }
                        {userTalkComponentStatus === USER_TALK_COMPONENT_STATUS.VISIBLE && (
                            <NavBar 
                                showBack={true}
                                title={partner.name}
                            />
                        )}
                        {userTalkComponentStatus === USER_TALK_COMPONENT_STATUS.HIDDEN &&
                        partner.deviceType !== DbConstants.DEVICE_TYPE_BROWSER && (
                            <NavBar 
                                showBack={true}
                                title={partner.name}
                            />
                        )}
                        {userTalkComponentStatus === USER_TALK_COMPONENT_STATUS.HIDDEN &&
                        partner.deviceType === DbConstants.DEVICE_TYPE_BROWSER && (
                            <NavBar 
                                showBack={true}
                                title={partner.name}
                                onClickVideoCallingButton={onClickVideoCall}
                                onClickVoiceCallingButton={onClickVoiceCall}
                            />
                        )}

                        {
                            // パートナー名エリア
                        }
                        <div className={partnerNameClass}>
                            <div>{partner.name}</div>
                            {userTalkComponentStatus === USER_TALK_COMPONENT_STATUS.HIDDEN &&
                            partner.deviceType === DbConstants.DEVICE_TYPE_BROWSER && (
                                <div className="button-area">
                                    <img 
                                        className="calling-button voice"
                                        src={VoiceCalling}
                                        onClick={onClickVoiceCall}
                                        alt="voice call"
                                    />
                                    <img
                                        className="calling-button video"
                                        src={VideoCalling}
                                        onClick={onClickVideoCall}
                                        alt="video call"
                                    />
                                </div>
                            )}
                        </div>
                        {props.talkUser == null && (
                            <div className="component-wrapper no-user"/>
                        )}
                        {props.talkUser != null && appServer != null && (
                            <>
                                <div className={userChatComponentClass}>
                                    {userChatComponentStatus === USER_CHAT_COMPONENT_STATUS.RIGHT_HIDE && (
                                        <div 
                                            className="anchor open"
                                            onClick={onClickOpenChat}
                                            onTouchStart={onTouchDownAnchor}
                                            onTouchEnd={onTouchEndAnchor}
                                            onMouseDown={onTouchDownAnchor}
                                            onMouseUp={onTouchEndAnchor}
                                            onMouseMove={onTouchMoveAnchor}
                                            onTouchMove={onTouchMoveAnchor}
                                        >
                                            <span />
                                            <span />
                                        </div>
                                    )}
                                    {userChatComponentStatus === USER_CHAT_COMPONENT_STATUS.OVERLAP && (
                                        <div 
                                            className="anchor close"
                                            onClick={onClickCloseChat}
                                            onTouchStart={onTouchDownAnchor}
                                            onTouchEnd={onTouchEndAnchor}
                                            onMouseDown={onTouchDownAnchor}
                                            onMouseUp={onTouchEndAnchor}
                                            onMouseMove={onTouchMoveAnchor}
                                            onTouchMove={onTouchMoveAnchor}
                                        >
                                            <span />
                                            <span />
                                        </div>
                                    )}
                                    <UserChatComponent
                                        loginUser={props.loginUser}
                                        talkUser={props.talkUser}
                                    />
                                </div>
                                <div className={userTalkComponentClass}>
                                    <UserTalkComponent
                                        ref={refUserTalkComponent}
                                        loginUser={props.loginUser}
                                        appServer={appServer}
                                        talkUser={props.talkUser}
                                        pathname={window.location.pathname}
                                        talkStarted={onTalkStarted}
                                    />
                                </div>
                            </>
                        )}
                        <OkDialog
                            open={openDialog}
                            title={intl.formatMessage({
                                id: "dlg_title_message",
                            })}
                            message={dialogMessage}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            onOk={() => {
                                setOpenDialog(false);
                            }}
                        />
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.talkUser !== nextProps.talkUser) {
            return false;
        }
        if (prevProps.talkUser != null && nextProps.talkUser != null) {
            if (prevProps.talkUser.userId !== nextProps.talkUser.userId) {
                return false;
            }
            if (prevProps.talkUser.partnerId !== nextProps.talkUser.partnerId) {
                return false;
            }
            if (prevProps.talkUser.partner !== nextProps.talkUser.partner) {
                return false;
            }
            if (prevProps.talkUser.partner != null && nextProps.talkUser.partner != null) {
                const prevPartner = prevProps.talkUser.partner;
                const nextPartner = nextProps.talkUser.partner;
                if (prevPartner.id !== nextPartner.id) {
                    return false;
                }
            }
        }
        // if (
        //     prevProps.userListAreaWidth !== nextProps.userListAreaWidth
        // ) {
        //     return false;
        // }
        if (prevProps.isSizeXS !== nextProps.isSizeXS) {
            return false;
        }

        return true;
    }
);
