/***** import */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, useScrollTrigger } from "@mui/material";
import { UserModel, HistoryUserList } from "models/Models";
import { UserAction, SelectedItemAction, HistoryAction, UnreadCountAction } from "redux/Actions";
import { NavBar, UserListItem2 } from "components/Components";
import { unblock } from "functions/BlockFunction";
import {
    getLanguageCode,
    getTimeDifference,
    getBlockUserList,
    getUserListHistory,
    getDeviceType
} from "redux/Selectors";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/BlockUserList.scss";

/***** type */
type Props = {
    loginUser: UserModel;
};

/**
 * フォローユーザリスト画面
 * @param props
 * @returns
 */
const BlockUserList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ BlockUserList IN");
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const container = React.useRef<HTMLDivElement>(null);
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const langCd = useSelector((state) => getLanguageCode(state));
        const lstBlockUser = useSelector((state) => getBlockUserList(state));
        // 時差
        const timeDifference = useSelector((state) => getTimeDifference(state));
        const history = useSelector((state) => getUserListHistory(state) as HistoryUserList);

        /***** useRef */

        /***** useState */

        const trigger = useScrollTrigger({
            target: window,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            window.scrollTo({ top: 0 });
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "ブロックユーザ一覧",
                path: window.location.pathname,
                deviceType: deviceType,
            });
        }, []);

        /**
         * ユーザクリック時
         * @param user
         */
        async function onClickUser(user: UserModel) {
            if (user != null) {
                const key = Utility.getUserKey(user);
                if (key.length > 0) {
                    dispatch(SelectedItemAction({ userId: user.id }));
                    window.setTimeout(function () {
                        navigate(`/users/${key}/profile`);
                    });
                }
            }
        }

        /**
         * ブロック解除
         * @param user
         */
        async function onClickedUnblock(user: UserModel) {
            const rtnCd = await unblock(props.loginUser, user);
            if (rtnCd === 0) {
                // ローカルとReduxデータのブロックユーザリスト更新
                updateBlockUserList(user);
                // ユーザリスト履歴を更新
                updateUserFromUserListHistory(user);
                // メール未読件数取得
                const p1 = Utility.getUnreadMailCount(props.loginUser);
                // トーク未読件数取得
                const p2 = Utility.getUnreadTalkCount(props.loginUser);
                const [unreadMailCount, unreadTalkCount] =
                    await Promise.all([p1, p2]);
                dispatch(
                    UnreadCountAction({
                        mail:
                            unreadMailCount != null
                                ? unreadMailCount
                                : 0,
                        talk:
                            unreadTalkCount != null
                                ? unreadTalkCount
                                : 0,
                    })
                );
            }
        }
        /**
         * ローカルとReduxデータのブロックユーザリスト更新
         * @param target
         */
        function updateBlockUserList(target: UserModel) {
            if (lstBlockUser == null) {
                return;
            }
            const wkBlockUserList = [...lstBlockUser];
            let idx = -1;
            for (let i = 0; i < wkBlockUserList.length; i++) {
                const blockUser = wkBlockUserList[i];
                if (target.id === blockUser.id) {
                    idx = i;
                    break;
                }
            }
            if (idx > -1) {
                wkBlockUserList.splice(idx, 1);
            }

            dispatch(
                UserAction({
                    lstBlockUser: wkBlockUserList,
                })
            );
        }
        /**
         * ユーザリスト履歴を更新
         * @param target
         * @returns
         */
        function updateUserFromUserListHistory(target: UserModel) {
            if (history == null) {
                return false;
            }
            let wkUserList = history.list;
            if (wkUserList.length > 0) {
                for (let i = 0; i < wkUserList.length; i++) {
                    const user = wkUserList[i];
                    if (user.id === target.id) {
                        user.blocking = false;
                    }
                }
                history.list = wkUserList;
                dispatch(HistoryAction({ userListHistory: history }));
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper BlockUserList">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "block_list" })}
                />
                <Box
                    component="h1"
                    className="page-title"
                    display={{
                        xs: "none",
                        sm: "block",
                    }}
                >
                    <FormattedMessage id={"block_list"} />
                </Box>

                {langCd != null &&
                    timeDifference != null &&
                    lstBlockUser != null && (
                        <>
                            <div className="list-container" ref={container}>
                                {lstBlockUser.map((user: UserModel) => {
                                    return (
                                        <div
                                            className="list-item-wrapper"
                                            key={user.id}
                                            onClick={() => onClickUser(user)}
                                        >
                                            <UserListItem2
                                                user={user}
                                                langCd={langCd}
                                                timeDifference={timeDifference}
                                                profImageUrlParam={user.id === props.loginUser?.id ? props.loginUser.profileImageUrlParam : null}
                                                unblock={() => {
                                                    onClickedUnblock(user);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <Zoom in={trigger}>
                                <div
                                    className="scroll-to-top"
                                    role="presentation"
                                >
                                    <Fab
                                        onClick={scrollToTop}
                                        color="primary"
                                        size="small"
                                        aria-label="scroll back to top"
                                    >
                                        <KeyboardArrowUp />
                                    </Fab>
                                </div>
                            </Zoom> */}
                        </>
                    )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default BlockUserList;
