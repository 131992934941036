/***** import */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Alert,
    Box,
    Button,
    OutlinedInput,
    RadioGroup,
    FormControlLabel,
    Radio,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppConstants, UrlConstants } from "constants/Constants";
import { AlbumRequest } from "api/requests/Requests";
import {
    UserModel,
    AlbumTemplateModel,
    AlbumModel,
    GroupModel,
} from "models/Models";
import {
    SelectGroupDialog,
    SelectUserDialog,
    NavBar,
} from "components/Components";
import "styles/pages/mypage/AlbumEdit.scss";
import {
    AlbumTemplateAction,
    NetworkAction,
    SelectedItemAction,
    WorkingItemAction,
} from "redux/Actions";
import {
    getSelectedAlbum,
    getAlbumTemplateList,
    getSelectedGroupList,
    getSelectedUserList,
    getWorkingAlbum,
    getDeviceType
} from "redux/Selectors";
import { pushDataLayer } from "gtm/gtm"

/***** type */
type Props = {
    loginUser: UserModel;
};

/**
 * アルバム作成画面
 * @param props
 * @returns
 */
const AlbumEdit: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ AlbumEdit IN");
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const { album_id } = useParams<{ album_id: string }>();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const ALBUM_SETTING_PUBLIC = "1";
        const ALBUM_SETTING_FOLLOWER = "2";
        const ALBUM_SETTING_GROUP = "3";
        const ALBUM_SETTING_USER = "4";
        const ALBUM_SETTING_PRIVATE = "5";
        const wkSelectedGroupList = useSelector((state) => getSelectedGroupList(state));
        const wkSelectedUserList = useSelector((state) => getSelectedUserList(state));
        const workingAlbum = useSelector((state) => getWorkingAlbum(state));
        const selectedAlbum = useSelector((state) => getSelectedAlbum(state));
        const tmpAlbumTemplateList = useSelector((state) => getAlbumTemplateList(state));

        /***** useRef */
        const refPageWrapper = React.useRef<HTMLDivElement>();
        const refInUnderProcess = React.useRef<boolean>(false);
        const retainEdittedData = React.useRef<boolean>(false);

        /***** useState */
        // アルバム
        const [album, setAlbum] = React.useState<AlbumModel>();
        // アルバムテンプレートリスト
        const [lstAlbumTemplate, setAlbumTemplateList] =
            React.useState<AlbumTemplateModel[]>();
        // アルバム名
        const [albumName, setAlbumName] = React.useState<string>("");
        React.useState<boolean>(true);
        // アルバム公開設定
        const [selectedAlbumSetting, setSelectedAlbumSetting] =
            React.useState<string>("1");
        // 選択テンプレートID
        const [selectedTemplateId, setSelectedTemplateId] =
            React.useState<number>();
        // 選択グループリスト
        const [lstSelectedGroup, setSelectedGroupList] =
            React.useState<GroupModel[]>();
        // 選択ユーザリスト
        const [lstSelectedUser, setSelectedUserList] =
            React.useState<UserModel[]>();
        // エラー表示フラグ
        const [showErrorMessage, setShowErrorMessage] =
            React.useState<boolean>();
        // エラーメッセージ
        const [errorMessage, setErrorMessage] = React.useState<string>();
        // グループ選択ダイアログ表示フラグ
        const [openDialogSelectGroup, setOpenDialogSelectGroup] =
            React.useState<boolean>(false);
        // ユーザ選択ダイアログ表示フラグ
        const [openDialogSelectUser, setOpenDialogSelectUser] =
            React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] =
            React.useState<string>();
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "アルバム編集",
                path: window.location.pathname,
                deviceType: deviceType,
            });

            retainEdittedData.current = false;

            return () => {
                if (
                    retainEdittedData == null ||
                    retainEdittedData.current == null ||
                    !retainEdittedData.current
                ) {
                    dispatch(
                        SelectedItemAction({
                            lstGroup: null,
                            lstUser: null,
                        })
                    );
                    dispatch(
                        WorkingItemAction({
                            album: null,
                        })
                    );
                }
            };
        }, []);
        React.useEffect(() => {
            // アルバムテンプレート設定、アルバム設定
            if (tmpAlbumTemplateList == null) {
                fetchAlbumTemplateList();
                return;
            }
            setAlbumTemplateList(tmpAlbumTemplateList);
            if (workingAlbum != null) {
                init(workingAlbum, tmpAlbumTemplateList);
                setAlbum(workingAlbum);
                return;
            } else {
                if (selectedAlbum == null) {
                    fetchAlbumData();
                    return;
                }
            }
            init(selectedAlbum, tmpAlbumTemplateList);
            setAlbum(selectedAlbum);            
        }, [
            tmpAlbumTemplateList,
            workingAlbum,
            selectedAlbum,
        ]);
        // 選択グループリスト変更時
        React.useEffect(() => {
            setSelectedGroupList(wkSelectedGroupList);
        }, [wkSelectedGroupList]);
        // 選択ユーザリスト変更時
        React.useEffect(() => {
            setSelectedUserList(wkSelectedUserList);
        }, [wkSelectedUserList]);

        /**
         * 初期処理
         */
        function init(
            _album: AlbumModel,
            _lstAlbumTemplate: AlbumTemplateModel[]
        ) {
            if (_album.name != null) {
                setAlbumName(_album.name);
            }

            if (_album.open === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_PUBLIC);
            } else if (_album.openFollower === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_FOLLOWER);
            } else if (_album.openGroup === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_GROUP);
            } else if (_album.openIndividual === 1) {
                setSelectedAlbumSetting(ALBUM_SETTING_USER);
            } else {
                setSelectedAlbumSetting(ALBUM_SETTING_PRIVATE);
            }

            if (_album.albumTemplateId != null) {
                setSelectedTemplateId(_album.albumTemplateId);
            }

            if (_album.openGroup === 1 && _album.lstAlbumGroup != null) {
                const lstAlbumGroup = _album.lstAlbumGroup;
                const lstGroup: GroupModel[] = [];
                for (let i = 0; i < lstAlbumGroup.length; i++) {
                    const albumGroup = lstAlbumGroup[i];
                    if (albumGroup.group != null) {
                        lstGroup.push(albumGroup.group);
                    }
                }
                setSelectedGroupList(lstGroup);
            }
            if (_album.openIndividual === 1 && _album.lstAlbumUser != null) {
                const lstAlbumUser = _album.lstAlbumUser;
                const lstUser: UserModel[] = [];
                for (let i = 0; i < lstAlbumUser.length; i++) {
                    const albumUser = lstAlbumUser[i];
                    if (albumUser.user != null) {
                        lstUser.push(albumUser.user);
                    }
                }
                setSelectedUserList(lstUser);
            }

            if (_album.openGroup === 1) {
                setSelectedUserList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstUser: null,
                    })
                );
            } else if (_album.openIndividual === 1) {
                setSelectedGroupList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstGroup: null,
                    })
                );
            } else {
                setSelectedGroupList(undefined);
                setSelectedUserList(undefined);
                dispatch(
                    SelectedItemAction({
                        lstUser: null,
                        lstGroup: null,
                    })
                );
            }
        }

        /**
         * アルバム公開設定変更時
         * @param e
         * @returns
         */
        function onChangePublicSetting(e: React.ChangeEvent<HTMLInputElement>) {
            if (e.target == null || e.target.value == null) {
                return;
            }
            const value = e.target.value;
            setSelectedAlbumSetting(value);
            dispatch(SelectedItemAction({ lstGroup: null, lstUser: null }));
        }
        /**
         * グループ選択押下時
         */
        async function onClickSelectGroup() {
            dispatch(SelectedItemAction({ lstGroup: lstSelectedGroup }));
            const wkAlbum = getWorkingState();
            dispatch(
                WorkingItemAction({
                    album: wkAlbum,
                })
            );
            // スマホの場合
            if (isSizeXS) {
                retainEdittedData.current = true;
                window.setTimeout(function () {
                    navigate(`/select/group`);
                }, 300);
            } else {
                setOpenDialogSelectGroup(true);
            }
        }
        /**
         * グループ選択ダイアログOK時
         * @returns
         */
        function onOkSelectedGroupDialog() {
            setSelectedGroupList(wkSelectedGroupList);
            setOpenDialogSelectGroup(false);
        }
        /**
         * グループ選択ダイアログキャンセル時
         * @returns
         */
        function onCancelSelectedGroupDialog() {
            if (lstSelectedGroup == null) {
                setOpenDialogSelectGroup(false);
                return;
            }
            setOpenDialogSelectGroup(false);
        }

        /**
         * ユーザ選択押下時
         */
        async function onClickSelectUser() {
            dispatch(SelectedItemAction({ lstUser: lstSelectedUser }));
            const wkAlbum = getWorkingState();
            dispatch(
                WorkingItemAction({
                    album: wkAlbum,
                })
            );
            // スマホの場合
            if (isSizeXS) {
                retainEdittedData.current = true;
                window.setTimeout(function () {
                    navigate(`/select/user`);
                }, 300);
            } else {
                setOpenDialogSelectUser(true);
            }
        }
        /**
         * ユーザ選択ダイアログOK時
         * @returns
         */
        function onOkSelectedUserDialog(_lstSelectedUser: UserModel[] | null) {
            if (_lstSelectedUser == null) {
                setSelectedUserList(undefined);
            } else {
                setSelectedUserList(_lstSelectedUser);
            }
            setOpenDialogSelectUser(false);
            dispatch(SelectedItemAction({ lstUser: _lstSelectedUser }));
        }
        /**
         * ユーザ選択ダイアログキャンセル時
         * @returns
         */
        function onCancelSelectedUserDialog() {
            if (lstSelectedUser == null) {
                setOpenDialogSelectUser(false);
                return;
            }
            setOpenDialogSelectUser(false);
        }

        /**
         * アルバムテンプレート選択時
         * @param _template
         */
        function onSelectTemplate(_template: AlbumTemplateModel) {
            if (_template.id != null) {
                setSelectedTemplateId(_template.id);
            }
        }

        /**
         * 画面遷移前に現在の状態を一時保存するためのアルバムデータ作成
         */
        function getWorkingState(): AlbumModel {
            const workingAlbum = new AlbumModel(album);
            if (album == null) {
                return workingAlbum;
            }
            workingAlbum.name = albumName;
            switch (selectedAlbumSetting) {
                case ALBUM_SETTING_PUBLIC:
                    workingAlbum.open = 1;
                    workingAlbum.openGroup = 0;
                    workingAlbum.openFollower = 0;
                    workingAlbum.openIndividual = 0;
                    break;
                case ALBUM_SETTING_FOLLOWER:
                    workingAlbum.open = 0;
                    workingAlbum.openGroup = 0;
                    workingAlbum.openFollower = 1;
                    workingAlbum.openIndividual = 0;
                    break;
                case ALBUM_SETTING_GROUP:
                    workingAlbum.open = 0;
                    workingAlbum.openGroup = 1;
                    workingAlbum.openFollower = 0;
                    workingAlbum.openIndividual = 0;
                    break;
                case ALBUM_SETTING_USER:
                    workingAlbum.open = 0;
                    workingAlbum.openGroup = 0;
                    workingAlbum.openFollower = 0;
                    workingAlbum.openIndividual = 1;
                    break;
                case ALBUM_SETTING_PRIVATE:
                    workingAlbum.open = 0;
                    workingAlbum.openGroup = 0;
                    workingAlbum.openFollower = 0;
                    workingAlbum.openIndividual = 0;
                    break;
            }
            if (selectedTemplateId != null) {
                workingAlbum.albumTemplateId = selectedTemplateId;
            }
            return workingAlbum;
        }
        /**
         * アルバム保存
         */
        async function saveAlbum() {
            if (!validate()) {
                if (refPageWrapper != null && refPageWrapper.current != null) {
                    refPageWrapper.current.scrollTo({ top: 0 });
                }
                return null;
            }
            if (album == null || album.id == null) {
                return null;
            }
            if (selectedTemplateId == null) {
                return null;
            }
            if (refInUnderProcess.current) {
                return;
            }
            refInUnderProcess.current = true;
            const editAlbum = new AlbumModel();
            // アルバムID
            editAlbum.id = album.id;
            // アルバム名
            editAlbum.name = albumName;
            // テンプレートID
            editAlbum.albumTemplateId = selectedTemplateId;
            // 公開設定
            switch (selectedAlbumSetting) {
                case ALBUM_SETTING_PUBLIC:
                    editAlbum.open = 1;
                    editAlbum.openGroup = 0;
                    editAlbum.openFollower = 0;
                    editAlbum.openIndividual = 0;
                    break;
                case ALBUM_SETTING_FOLLOWER:
                    editAlbum.open = 0;
                    editAlbum.openGroup = 0;
                    editAlbum.openFollower = 1;
                    editAlbum.openIndividual = 0;
                    break;
                case ALBUM_SETTING_GROUP:
                    editAlbum.open = 0;
                    editAlbum.openGroup = 1;
                    editAlbum.openFollower = 0;
                    editAlbum.openIndividual = 0;
                    if (lstSelectedGroup != null) {
                        const lstGroupId: number[] = [];
                        lstSelectedGroup.forEach((group) => {
                            if (group.id != null) {
                                lstGroupId.push(group.id);
                            }
                        });
                        editAlbum.lstOpenGroupIdList = lstGroupId;
                    }
                    break;
                case ALBUM_SETTING_USER:
                    editAlbum.open = 0;
                    editAlbum.openGroup = 0;
                    editAlbum.openFollower = 0;
                    editAlbum.openIndividual = 1;
                    if (lstSelectedUser != null) {
                        const lstUserId: number[] = [];
                        lstSelectedUser.forEach((user) => {
                            if (user.id != null) {
                                lstUserId.push(user.id);
                            }
                        });
                        editAlbum.lstOpenUserIdList = lstUserId;
                    }
                    break;
                case ALBUM_SETTING_PRIVATE:
                    editAlbum.open = 0;
                    editAlbum.openGroup = 0;
                    editAlbum.openFollower = 0;
                    editAlbum.openIndividual = 0;
                    break;
            }
            try {
                const result = await AlbumRequest.saveAlbum(
                    props.loginUser,
                    editAlbum
                );
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }

                if (
                    result.rtnCd == null ||
                    result.rtnCd < 0
                ) {
                    if (result.rtnCd === AppConstants.ERROR_VISION_VERY_LIKELY) {
                        if (editAlbum.open) {
                            setDialogMessage(intl.formatMessage({id: "err_explicit_contents_to_public"}));
                            setOpenDialog(true);
                        }
                    } 
                    return;
                }
                navigate(-1);
            } catch (error) {
                console.error(error);
            } finally {
                refInUnderProcess.current = false;
            }
        }

        /**
         * バリデーション
         */
        function validate(): boolean {
            // アルバム名
            if (albumName == null || albumName.trim().length === 0) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_album_name",
                });
                if (errorMessage != null && errorMessage.length > 0) {
                    setErrorMessage(errorMessage);
                    setShowErrorMessage(true);
                }
                return false;
            }
            // グループ公開の場合
            if (selectedAlbumSetting === ALBUM_SETTING_GROUP) {
                if (lstSelectedGroup == null || lstSelectedGroup.length === 0) {
                    const errorMessage = intl.formatMessage({
                        id: "err_no_group_selected",
                    });
                    if (errorMessage != null && errorMessage.length > 0) {
                        setErrorMessage(errorMessage);
                        setShowErrorMessage(true);
                    }
                    return false;
                }
            }
            // ユーザ公開の場合
            if (selectedAlbumSetting === ALBUM_SETTING_USER) {
                if (lstSelectedUser == null || lstSelectedUser.length === 0) {
                    const errorMessage = intl.formatMessage({
                        id: "err_no_user_selected",
                    });
                    if (errorMessage != null && errorMessage.length > 0) {
                        setErrorMessage(errorMessage);
                        setShowErrorMessage(true);
                    }
                    return false;
                }
            }
            // テンプレート
            if (selectedTemplateId == null) {
                const errorMessage = intl.formatMessage({
                    id: "err_empty_template",
                });
                if (errorMessage != null && errorMessage.length > 0) {
                    setErrorMessage(errorMessage);
                    setShowErrorMessage(true);
                }
                return false;
            }
            return true;
        }
        /**
         * アルバムテンプレートリスト取得
         */
        async function fetchAlbumTemplateList(): Promise<
            AlbumTemplateModel[] | null
        > {
            if (props.loginUser == null) {
                return null;
            }
            let result = await AlbumRequest.getAlbumTemplateList();
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.lstAlbumTemplate == null
            ) {
                return null;
            }
            dispatch(
                AlbumTemplateAction({
                    list: result.lstAlbumTemplate,
                })
            );
            return result.lstAlbumTemplate;
        }

        /**
         * アルバムデータ取得
         * @returns
         */
        async function fetchAlbumData(): Promise<AlbumModel | null> {
            if (
                props.loginUser == null ||
                album_id == null ||
                album_id.length === 0
            ) {
                return null;
            }
            let result = await AlbumRequest.getAlbum(props.loginUser, album_id);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.album == null
            ) {
                return null;
            }
            const album = result.album;
            if (album.openGroup === 1) {
                const lstAlbumGroup = album.lstAlbumGroup;
                if (lstAlbumGroup != null) {
                    const lstGroup: GroupModel[] = [];
                    for (let i = 0; i < lstAlbumGroup.length; i++) {
                        const albumGroup = lstAlbumGroup[i];
                        const group = albumGroup.group;
                        if (group != null) {
                            group.selected = true;
                            lstGroup.push(group);
                        }
                    }
                    setSelectedGroupList(lstGroup);
                }
            } else if (album.openIndividual === 1) {
                const lstAlbumUser = album.lstAlbumUser;
                if (lstAlbumUser != null) {
                    const lstUser: UserModel[] = [];
                    for (let i = 0; i < lstAlbumUser.length; i++) {
                        const albumUser = lstAlbumUser[i];
                        const user = albumUser.user;
                        if (user != null) {
                            user.selected = true;
                            lstUser.push(user);
                        }
                    }
                    setSelectedUserList(lstUser);
                }
            }
            dispatch(SelectedItemAction({ album: result.album }));

            return result.album;
        }
        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper AlbumEdit" ref={refPageWrapper}>
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_album_edit" })}
                />
                {album != null &&
                    lstAlbumTemplate !== undefined &&
                    lstAlbumTemplate.length > 0 && (
                        <>
                            {showErrorMessage && errorMessage != null && (
                                <div className="error-message-area">
                                    <Alert
                                        className="error-message"
                                        severity="error"
                                        onClose={() => {
                                            setShowErrorMessage(false);
                                            setErrorMessage("");
                                        }}
                                    >
                                        {errorMessage}
                                    </Alert>
                                </div>
                            )}
                            <div className="input-area">
                                {
                                    // アルバム名
                                }
                                <div className="album-name-area">
                                    <label className="label" htmlFor="txtEmail">
                                        <FormattedMessage id={"album_name"} />
                                    </label>
                                    <OutlinedInput
                                        className="album-name-value"
                                        fullWidth
                                        id="txtAlbumName"
                                        label=""
                                        inputProps={{
                                            maxLength: 255,
                                        }}
                                        value={albumName}
                                        onChange={(e) => {
                                            setAlbumName(e.target.value);
                                        }}
                                    />
                                </div>
                                {
                                    // 公開設定
                                }
                                <div className="album-public-setting-area">
                                    <label className="label">
                                        <FormattedMessage
                                            id={"album_public_setting"}
                                        />
                                    </label>
                                    <RadioGroup
                                        className="radio-group"
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={intl.formatMessage({
                                            id: "album_public",
                                        })}
                                        name="radio-buttons-group"
                                        value={selectedAlbumSetting}
                                        onChange={onChangePublicSetting}
                                    >
                                        {
                                            // 一般公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={ALBUM_SETTING_PUBLIC}
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_public",
                                            })}
                                        />
                                        <div className="album-precautions">
                                            <FormattedMessage id={"section_public_precautions"} />
                                        </div>
                                        {
                                            // フォロワー公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        ALBUM_SETTING_FOLLOWER
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_open_follower",
                                            })}
                                        />
                                        {
                                            // 特定グループ公開
                                        }
                                        <div className="radio-item select-group">
                                            <div className="select-area">
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={
                                                                ALBUM_SETTING_GROUP
                                                            }
                                                        />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "album_open_group",
                                                    })}
                                                />
                                                <Button
                                                    className={selectedAlbumSetting ===
                                                            ALBUM_SETTING_GROUP ? "select-group-button" : "select-group-button disabled"}
                                                    variant="text"
                                                    disabled={
                                                        selectedAlbumSetting !==
                                                        ALBUM_SETTING_GROUP
                                                    }
                                                    onClick={onClickSelectGroup}
                                                >
                                                    <FormattedMessage
                                                        id={"btn_select"}
                                                    />
                                                </Button>
                                            </div>
                                            <div className="group-name-list-area">
                                                {lstSelectedGroup != null &&
                                                    lstSelectedGroup.map(
                                                        (
                                                            group: GroupModel,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="group-name-wrapper"
                                                                >
                                                                    {group.name}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        {
                                            // 特定ユーザ公開
                                        }
                                        <div className="radio-item select-user">
                                            <div className="select-area">
                                                <FormControlLabel
                                                    control={
                                                        <Radio
                                                            value={
                                                                ALBUM_SETTING_USER
                                                            }
                                                        />
                                                    }
                                                    label={intl.formatMessage({
                                                        id: "album_open_individual",
                                                    })}
                                                />
                                                <Button
                                                    className={selectedAlbumSetting ===
                                                            ALBUM_SETTING_USER ? "select-user-button" : "select-user-button disabled"}
                                                    variant="text"
                                                    disabled={
                                                        selectedAlbumSetting !==
                                                        ALBUM_SETTING_USER
                                                    }
                                                    onClick={onClickSelectUser}
                                                >
                                                    <FormattedMessage
                                                        id={"btn_select"}
                                                    />
                                                </Button>
                                            </div>
                                            <div className="user-name-list-area">
                                                {lstSelectedUser != null &&
                                                    lstSelectedUser.map(
                                                        (
                                                            user: UserModel,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="user-name-wrapper"
                                                                >
                                                                    {user.name}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        {
                                            // 非公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        ALBUM_SETTING_PRIVATE
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "album_private",
                                            })}
                                        />
                                    </RadioGroup>
                                </div>
                                {
                                    // テンプレート選択
                                }
                                <div className="album-template-select-area">
                                    <label className="label">
                                        <FormattedMessage
                                            id={"select_album_template"}
                                        />
                                    </label>
                                    <div className="value-area">
                                        {lstAlbumTemplate.map((template) => {
                                            return (
                                                <React.Fragment
                                                    key={template.id}
                                                >
                                                    {template.name != null && (
                                                        <Box
                                                            className="img"
                                                            component="img"
                                                            sx={{
                                                                backgroundColor:
                                                                    template.id ===
                                                                    selectedTemplateId
                                                                        ? // template.selected
                                                                          "#ff88ff"
                                                                        : "none",
                                                            }}
                                                            src={
                                                                UrlConstants.URL_S3_ALBUM_ICON +
                                                                template.name
                                                            }
                                                            onClick={() => {
                                                                onSelectTemplate(
                                                                    template
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            {
                                // ボタンエリア
                            }
                            <div className="btn-area">
                                <Button
                                    className="app-button button"
                                    variant="text"
                                    onClick={saveAlbum}
                                >
                                    <FormattedMessage id={"btn_save2"} />
                                </Button>
                            </div>
                        </>
                    )}
                <SelectGroupDialog
                    loginUser={props.loginUser}
                    openDialog={openDialogSelectGroup}
                    onClose={() => {
                        onCancelSelectedGroupDialog();
                    }}
                    onCancel={() => {
                        onCancelSelectedGroupDialog();
                    }}
                    onOk={() => {
                        onOkSelectedGroupDialog();
                    }}
                />
                <SelectUserDialog
                    loginUser={props.loginUser}
                    openDialog={openDialogSelectUser}
                    onClose={onCancelSelectedUserDialog}
                    onCancel={onCancelSelectedUserDialog}
                    onOk={onOkSelectedUserDialog}
                />
                <Dialog
                    className="dialog"
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent className="dialog-content2">
                        <DialogContentText id="alert-dialog-description">
                            {dialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            color="primary"
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                        >
                            <FormattedMessage id={"btn_ok"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default AlbumEdit;
