export enum MODE {
    PRODUCTION,
    DEVELOPMENT,
}

export class AppConstants {
    /*************************************** 開発時用 START ***********************************/
    // static mode = MODE.DEVELOPMENT;
    /*************************************** 開発時用 END ***********************************/
    /*************************************** 本番用 START ***********************************/
    static mode = MODE.PRODUCTION;
    /*************************************** 本番用 END ***********************************/

    // 動画再生タイム
    // static FREE_PLAN_AVAILABLE_PLAYTIME_PRODUCTION = 60 * 5;
    // static STANDARD_PLAN_AVAILABLE_PLAYTIME_PRODUCTION = 60 * 15;
    // static TWENTY_FOUR_HOURS_PRODUCTION = 60 * 60 * 24;

    // static FREE_PLAN_AVAILABLE_PLAYTIME_DEVELOPMENT = 60 * 5;
    // static STANDARD_PLAN_AVAILABLE_PLAYTIME_DEVELOPMENT = 60 * 15;
    // static TWENTY_FOUR_HOURS_DEVELOPMENT = 60 * 60 * 24;

    // static FREE_PLAN_AVAILABLE_PLAYTIME = 60 * 5;
    // static STANDARD_PLAN_AVAILABLE_PLAYTIME = 60 * 15;
    // static TWENTY_FOUR_HOURS = 60 * 60 * 24;

    // Google OAUTH ClientID
    static GOOGLE_OAUTH_CLIENT_ID =
        "1024662147490-65t4ro788gqhscbm6trrgt0u6vap2nuj.apps.googleusercontent.com";
    // リリース日
    static RELEASE_DATE = "2024/08/21 00:00:00";

    // 動画タイプ
    static MIMETYPE_CODECS_MP4 = 'video/mp4;codecs="avc1.4d001e"';
    static MIMETYPE_CODECS_WEBM = 'video/webm;codecs="vp9"';
    static MIMETYPE_MP4 = "video/mp4"
    static MIMETYPE_WEBM = "video/webm";
    // 写真タイプ
    static MIMETYPE_PHOTO = "images/jpeg";
    
    // IndexedDB関連
    static DB_NAME = "SMMateDb";
    static STORE_NAME_SHOOT_RESULT = "SMMateShootResultStore"
    static STORE_NAME_SHOOT_BACKGROUND = "SMMateShootBackgroundStore"
    static STORE_NAME_SHOOT_DECORATION = "SMMateShootDecorationStore"
    static DB_VERSION = 1;
    
    // 読み込みレコード数
    static READ_SIZE_USER_LIST = 100;
    static READ_SIZE_GROUP_LIST = 100;
    static READ_SIZE_MESSAGEBOARD_LIST = 100;
    static READ_SIZE_PARENT_MAIL_LIST = 100;
    static READ_SIZE_GROUP_MEMBER_LIST = 100;
    static READ_SIZE_GROUP_CHAT_LIST = 100;
    static READ_SIZE_TALK_USER_LIST = 100;
    static READ_SIZE_TALK_MESSAGE_LIST = 100;
    static READ_SIZE_TWEET_LIST = 100;

    // フッターメニューID
    static FOOTER_MENU_NONE = 0;
    static FOOTER_MENU_USERS = 1;
    static FOOTER_MENU_GROUPS = 2;
    static FOOTER_MENU_MESSAGE_BOARD = 3;
    static FOOTER_MENU_TALK = 4;
    static FOOTER_MENU_MAIL = 5;
    static FOOTER_MENU_MYPAGE = 6;

    // 色
    static BG_COLOR_APP = "linear-gradient(180deg, #ff55ff 0%, rgb(0, 153, 255) 100%);";
    static COLOR_PRIMARY = "#3F51B5";
    static COLOR_DISABLED_BUTTON = "#AAAAAA";
    static BG_COLOR_GENDER_MALE = "#55AAFF";
    static BG_COLOR_GENDER_FEMALE = "#FF55CC";
    static BG_COLOR_GENDER_OTHER = "#999999";
    static BG_COLOR_SELECTED_FILE = "#cc55ff";
    static BG_COLOR_SELECT_ITEM = "rgba(244,7,241,0.3)";
    static BG_COLOR_DIALOG = "#cacaff";
    static BG_COLOR_NAV_BAR = "#990099";
    static FONT_COLOR_GENDER_MALE = "#5555FF";
    static FONT_COLOR_GENDER_FEMALE = "#FF22AA";
    static FONT_COLOR_GENDER_OTHER = "#555555";
    static COLOR_NORMAL = "#333333";
    static COLOR_GRAY_DATE = "#777777";
    static BG_COLOR_GROUP_OWNER = "#3F51B5";
    static BG_COLOR_GROUP_UNDER_APPLICATION = "#AAAA33";
    static BG_COLOR_GROUP_UNDER_INVITATION = "#AAAA33";
    static BG_COLOR_GROUP_MEMBER = "#55AA55";
    static BG_COLOR_GROUP_PRIVATE = "#999999";
    static BG_COLOR_UNREAD = "#FFFFCC";
    static BG_COLOR_UNREAD_LABEL = "#AAAA00";
    static BG_COLOR_UNREAD_SELECTED = "#CCCCAA";
    static BTN_READ_BG_COLOR_INBOX = "#AAAA00";
    static BTN_READ_BG_COLOR_OUTBOX = "#999999";
    static BTN_READ_BG_COLOR_HOVER_INBOX = "#888800";
    static BTN_READ_BG_COLOR_HOVER_OUTBOX = "#999999";
    static BTN_DELETE_BG_COLOR = "#FF5555";
    static BTN_DELETE_BG_COLOR_HOVER = "#AA2222";
    static DIALOG_TITLE_BG_COLOR = "#3399ff";

    // ヘッダー高さ
    static HEADER_HEIGHT = "40px";
    static NAVBAR_HEIGHT = "40px";

    // フッター高さ
    static FOOTER_HEIGHT = "65px";

    // 広告エリアサイズ
    static AD_AREA_WIDTH = 320;
    static AD_AREA_BOTTOM_HEIGHT = 50;

    // フォントサイズ
    static FONT_SIZE_BUTTON = "1.2rem";

    // メール返信
    static MAIL_REPLY = "RE:";

    // 掲示板MAXレス数
    static MAX_RESPONSE_COUNT = 100;

    // SessionStorage名
    // static STORAGE_KEY_USER_LIST = "USER_LIST";
    // static STORAGE_KEY_GROUP_LIST = "GROUP_LIST";
    // static STORAGE_KEY_MESSAGEBOARD_LIST = "MESSAGEBOARD_LIST";
    // static STORAGE_KEY_RECEIVED_MAIL_LIST = "RECEIVED_MAIL_LIST";
    // static STORAGE_KEY_SENT_MAIL_LIST = "SENT_MAIL_LIST";

    // SessionStorage有効期限
    static STORAGE_EXPIRE_TIME_USER_LIST = 60;
    static STORAGE_EXPIRE_TIME_GROUP_LIST = 60;
    static STORAGE_EXPIRE_TIME_MESSAGEBOARD_LIST = 60;
    static STORAGE_EXPIRE_TIME_RECEIVED_MAIL_LIST = 60;
    static STORAGE_EXPIRE_TIME_SENT_MAIL_LIST = 60;

    // ユーザチャット機能可能バージョン
    static USER_CHAT_POSSIBLE_MAJOR_VERSION = 2;
    static USER_CHAT_POSSIBLE_MINOR_VERSION = 8;
    static USER_CHAT_POSSIBLE_BUILD_NO = 0;

    // ユーザトーク機能可能バージョン
    static USER_TALK_POSSIBLE_MAJOR_VERSION = 3;
    static USER_TALK_POSSIBLE_MINOR_VERSION = 0;
    static USER_TALK_POSSIBLE_BUILD_NO = 0;

    // 広告インターバル
    static AD_INTERVAL_LEFT = 60000;
    static AD_INTERVAL_RIGHT = 120000;
    static AD_INTERVAL_BOTTOM = 60000;

    // IPinfo(https://ipinfo.io)
    static IPINFO_ACCESS_TOKEN = "d018d1512d144e";
    
    // エラーコード
    static ERROR_DUPLICATE_EMAIL = -2;
    static ERROR_EMAIL_NOT_FOUND = -9;
    static ERROR_DUPLICATE_USER_NAME = -10;
    static ERROR_VISION_VERY_LIKELY = -19;
    static ERROR_BEARER_TOKEN = -100;
    static ERROR_NO_USER = -999;
    static ERROR_PASSWORD_RESET_REQUEST_GOOGLE = -20;

}
