import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
    Backdrop,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Alert,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Router from "./Router";
import {
    isProcessing,
    getProcessingMessage,
    isMaintenance,
    getLoginUser,
    connected,
    getDeviceType,
    getMajorVersion,
    getMinorVersion,
    getBuildNo,
    getDeviceToken,
    isGrantedCamera,
    isGrantedAudio
} from "redux/Selectors";
import {
    ProcessAction,
    UserAction,
    UnreadCountAction,
    TimeDifferenceAction,
    MaintenanceAction,
    EmitterAction,
    IncrementTalkUnreadCountAction,
    DecrementTalkUnreadCountAction,
    LanguageAction,
    NetworkAction,
    AppInfoAction,
} from "redux/Actions";
import { EventEmitter } from "fbemitter";
import { SystemInfoRequest, UserRequest } from "api/requests/Requests";
import { UserModel, TalkMessageModel } from "models/Models";
import { AppConstants, DbConstants, UrlConstants } from "constants/Constants";
import { MODE } from "constants/AppConstants";
import Utility from "utils/Utility";
// import AuthenticateScreen from "AuthenticateScreen";
import { AppServer } from "socket/AppServer";
import { APP_PERMISSION, TALK_STATUS, TALK_TYPE } from "constants/Enum";
import { TalkCallingDialog } from "components/Components";
import "styles/App.scss";
import IndexedDb from "IndexedDb";

const App: React.FC = () => {
    // function App() {
    // Utility.log("@@@@@ App");
    // Utility.log("mode is " + process.env.NODE_ENV);
    /***** 定数、変数 */
    const intl = useIntl();
    const dispatch = useDispatch();
    const deviceType = useSelector((state) => getDeviceType(state));
    const majorVersion: number | null = useSelector((state) => getMajorVersion(state));
    const minorVersion: number | null = useSelector((state) => getMinorVersion(state));
    const buildNo: number | null = useSelector((state) => getBuildNo(state));
    const deviceToken: string | null = useSelector((state) => getDeviceToken(state));
    let grantedCamera: boolean | null = useSelector((state) => isGrantedCamera(state));
    let grantedAudio: boolean | null = useSelector((state) => isGrantedAudio(state));
    const processing = useSelector((state) => isProcessing(state));
    const processingMessage = useSelector((state) => getProcessingMessage(state));
    const underMaintenance = useSelector((state) => isMaintenance(state));
    const networkConnected = useSelector((state) => connected(state));
    // ログインユーザ
    const wkLoginUser = useSelector((state) => getLoginUser(state));

    /***** useRef */
    // ログインユーザ参照
    const refLoginUser = React.useRef<UserModel>();
    // Appサーバー
    const appServer = React.useRef<AppServer>(AppServer.instance);
    // EventEmitter
    const emitter = React.useRef<EventEmitter>();
    // 初期処理完了フラグ
    const initializeCompleted = React.useRef<boolean>(false);
    // トークリクエストユーザ参照
    const refTalkRequestUser = React.useRef<UserModel>();
    // 必要なアプリパーミッション
    const refAppPermissionList = React.useRef<APP_PERMISSION[]>([]);

    /***** useState */
    // ログインユーザ
    const [loginUser, setLoginUser] = React.useState<UserModel | null>(null);
    // アクセストークン取得結果
    const [completedAccessTokenResult, setCompletedAccessTokenResult] =
        React.useState<boolean>(false);
    // トークリクエストユーザ
    const [talkRequestUser, setTalkRequestUser] = React.useState<UserModel>();
    // トークタイプ(リクエスト側)
    const [talkTypeRequest, setTalkTypeRequest] =
        React.useState<TALK_TYPE>();
    // トークリクエスト確認ダイアログ表示フラグ
    const [showConfirmTalkRequest, setShowConfirmTalkRequest] =
        React.useState<boolean>(false);
    // ダイアログメッセージ
    const [dialogMessage, setDialogMessage] =
        React.useState<string>();
    // ダイアログ表示フラグ
    const [openDialog, setOpenDialog] =
        React.useState<boolean>(false);
    // スナックバー表示フラグ
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    // ページ表示フラグ
    const [showPage, setShowPage] = React.useState<boolean>(false);

    /**
     * システム情報取得
     */
    const getSystemInfo = React.useCallback(async() => {
        const result = await SystemInfoRequest.getSystemInfo();
        if (result == null) {
            if (window.navigator.onLine) {
                dispatch(MaintenanceAction({ isMaintenance: true }));
            } else {
                dispatch(NetworkAction({connected: false}));
            }
            return;
        }
        if (result.systemInfo == null) {
            dispatch(MaintenanceAction({ isMaintenance: true }));
            return;
        }
        if (result.systemInfo.maintenance === 1) {
            dispatch(MaintenanceAction({ isMaintenance: true }));
        } else {
            if (window.location.pathname.includes("maintenance")) {
                window.location.href = "/";
            } else {
                dispatch(MaintenanceAction({ isMaintenance: false }));
            }
        }
    }, [
        dispatch
    ]);

    
    /**
     * アクセストークン取得
     */
    const fetchAccessToken = React.useCallback(async() => {
        // console.log("fetchAccessToken IN")
        const resAccessToken = await UserRequest.getAccessToken();
        if (resAccessToken?.rtnCd === 0) {
            let user = resAccessToken?.user;
            if (user != null) {
                user.profileImageUrlParam = String(new Date().getTime());
                const accessToken = resAccessToken?.accessToken;
                if (accessToken != null) {
                    user.bearerToken = accessToken;
                    const resMyData = await UserRequest.getMyData(user);
                    if (
                        resMyData == null ||
                        resMyData.rtnCd == null ||
                        resMyData.rtnCd < 0
                    ) {
                        user = null;
                    } else {
                        const langCd = Utility.getLanguageCode();
                        if (user.languageCd != null && user.languageCd !== langCd) {
                            dispatch(
                                LanguageAction({
                                    code: user.languageCd,
                                })
                            );                    
                        }
                        dispatch(
                            UserAction({
                                loginUser: user,
                                lstOwnGroup: resMyData.lstOwnGroup,
                                lstBlockUser: resMyData.lstBlockUser,
                                lstFollowUser: resMyData.lstFollow,
                                lstTag: resMyData.lstTag,
                                lstCustomTag: resMyData.lstCustomTag,
                                lstInvite: resMyData.lstInvite,
                            })
                        );
                        // メール未読件数取得
                        const p1 = Utility.getUnreadMailCount(user);
                        // トーク未読件数取得
                        const p2 = Utility.getUnreadTalkCount(user);
                        const [unreadMailCount, unreadTalkCount] =
                            await Promise.all([p1, p2]);
                        dispatch(
                            UnreadCountAction({
                                mail:
                                    unreadMailCount != null
                                        ? unreadMailCount
                                        : 0,
                                talk:
                                    unreadTalkCount != null
                                        ? unreadTalkCount
                                        : 0,
                            })
                        );
                    }
                }
            }
        }
    }, [
        dispatch
    ]);
    
    /**
     * トークメッセージ受信時
     * @param talkMessage
     */
    const onReceiveTalkMessage = React.useCallback(async(talkMessage: TalkMessageModel) => {
        if (emitter == null || emitter.current == null) {
            return;
        }

        if (
            talkMessage.senderId == null ||
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null
        ) {
            return;
        }

        // イベント発行
        emitter.current.emit("RECEIVED_NEW_MESSAGE", talkMessage);

        // 自分が発信したトークメッセージの場合
        if (talkMessage.senderId === refLoginUser.current.id) {
            return;
        }

        // バッジカウントのインクリメント
        dispatch(await IncrementTalkUnreadCountAction());
    }, [dispatch]);

    /**
     * トークメッセージ削除通知受信時
     * @param talkMessage
     */
    const onReceiveDeleteTalkMessage = React.useCallback(async(talkMessage: TalkMessageModel) => {
        if (emitter == null || emitter.current == null) {
            return;
        }

        if (
            talkMessage.senderId == null ||
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null
        ) {
            return;
        }

        // イベント発行
        emitter.current.emit("DELETE_TALK_MESSAGE", talkMessage);

        // 自分が発信したトークメッセージの場合
        if (talkMessage.senderId === refLoginUser.current.id) {
            return;
        }

        // バッジカウントのデクリメント
        if (talkMessage.read !== 1) {
            dispatch(await DecrementTalkUnreadCountAction());
        }
    }, [dispatch]);
    
    /**
     * メンテナンス予告通知受信時
     */
    const onReceiveMaintenanceNotification = React.useCallback(() => {
        const message = intl.formatMessage({id: "msg_maintenance_notification"})
        setDialogMessage(message);
        setOpenDialog(true);
    }, [intl]);

    /**
     * メンテナンス通知受信時
     */
    const onReceiveMaintenanceStartedNotification = React.useCallback(() => {
        dispatch(MaintenanceAction({ isMaintenance: true }));
    }, [dispatch]);

    /**
     * メンテナンス終了通知受信時
     */
    const onReceiveMaintenanceFinishedNotification = React.useCallback(() => {
        dispatch(MaintenanceAction({ isMaintenance: false }));
    }, [dispatch]);

    /**
     * Appサーバーイベントコールバック
     * @param obj
     */
    const appServerEventCallback = React.useCallback((obj: any) => {
        Utility.log("appServerEventCallback IN")
        if (Object.keys(obj).indexOf("type") === -1) {
            return;
        }
        switch (obj["type"]) {
            case "TALK_MESSAGE":
                const talkMessage1 = new TalkMessageModel(obj);
                onReceiveTalkMessage(talkMessage1);
                break;
            case "DELETE_TALK_MESSAGE":
                const talkMessage2 = new TalkMessageModel(obj);
                onReceiveDeleteTalkMessage(talkMessage2);
                break;
            case "READ_MESSAGE":
                const talkMessage3 = new TalkMessageModel(obj);
                onReceiveReadMessage(talkMessage3);
                break;
            case "MAINTENANCE_NOTIFICATION":
                onReceiveMaintenanceNotification();
                break;
            case "MAINTENANCE_STARTED_NOTIFICATION":
                onReceiveMaintenanceStartedNotification();
                break;
            case "MAINTENANCE_FINISHED_NOTIFICATION":
                onReceiveMaintenanceFinishedNotification();
                break;
            case "RESPONSE_FOR_CONFIRM_CONNECT_STATUS":
                Utility.log("App RESPONSE_FOR_CONFIRM_CONNECT_STATUS");
                onReceivedResponseForConnectStatusConfirmation(obj);
                break;
            case "RECEIVED_CALL_REQUEST":
                onReceivedTalkRequest(obj);
                break;
            case "RECEIVED_CANCEL_CALL_REQUEST":
                onReceiveCancelRequestCall(obj);
                break;
            case "RECEIVED_CALL_RESPONSE":
                onReceivedCallResponse(obj);
                break;
            case "TALK_STARTED":
                onReceivedTalkStartedNotification(obj);
                break;
            case "CHANGED_TALK_PERMISSION":
                onReceivedTalkPermissionChange(obj);
                break;
            case "TALK_FAILURE_NOTIFICATION":
                onReceivedTalkFailureNotification(obj);
                break;
            }
    }, [
        onReceiveTalkMessage,
        onReceiveDeleteTalkMessage,
        onReceiveMaintenanceNotification,
        onReceiveMaintenanceStartedNotification,
        onReceiveMaintenanceFinishedNotification,
    ]);

    /**
     * Appサーバーへ接続
     */
    const connectToAppServer = React.useCallback((_loginUser: UserModel) => {
        if (_loginUser != null && _loginUser.id != null) {
            appServer.current.connect(
                _loginUser,
                appServerConnectCallback,
                appServerEventCallback,
                appServerDisconnectCallback
            );
        }
    }, [
        appServerEventCallback
    ]);

    /**
     * 受信したトーク要求を拒否する
     * @returns
     */
    const onRejectTalkRequest = React.useCallback(() => {
        setShowConfirmTalkRequest(false);
        if (
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null ||
            refTalkRequestUser == null ||
            refTalkRequestUser.current == null ||
            refTalkRequestUser.current.id == null
        ) {
            return;
        }
        appServer.current.sendTalkCallResponse(
            refTalkRequestUser.current.id,
            refLoginUser.current.id,
            false,
            TALK_TYPE.NONE,
            false
        );
    }, [
        refLoginUser,
        refTalkRequestUser,
    ]);

    const getAppInfo = React.useCallback(() => {
        const url = new URL(window.location.href);
        let deviceType = DbConstants.DEVICE_TYPE_BROWSER;
        const wkDeviceType = url.searchParams.get("type");
        if (wkDeviceType != null && wkDeviceType.length > 0) {
            deviceType = parseInt(wkDeviceType);
        }
        let deviceToken: string | null = null;
        let majorVersion: number | null = null;
        let minorVersion: number | null = null;
        let buildNo: number | null = null;
        let grantedCamera: boolean | null = null;
        let grantedAudio: boolean | null = null;
        const wkDeviceToken = url.searchParams.get("token");
        if (wkDeviceToken != null) {
            deviceToken = wkDeviceToken
        }
        const wkMajorVersion = url.searchParams.get("major");
        if (wkMajorVersion != null) {
            try {
                majorVersion = parseInt(wkMajorVersion);
            } catch (e) {
                console.error(e);
            }
        }
        const wkMinorVersion = url.searchParams.get("minor");
        if (wkMinorVersion != null) {
            try {
                minorVersion = parseInt(wkMinorVersion);
            } catch (e) {
                console.error(e);
            }
        }
        const wkBuildNo = url.searchParams.get("build");
        if (wkBuildNo != null) {
            try {
                buildNo = parseInt(wkBuildNo);
            } catch (e) {
                console.error(e);
            }
        }
        const wkGrantedCamera = url.searchParams.get("camera")
        if (wkGrantedCamera != null) {
            try {
                grantedCamera = JSON.parse(wkGrantedCamera.toLowerCase());
            } catch (e) {
                console.error(e);
            }
        }
        const wkGrantedAudio = url.searchParams.get("audio")
        if (wkGrantedAudio != null) {
            try {
                grantedAudio = JSON.parse(wkGrantedAudio.toLowerCase());
            } catch (e) {
                console.error(e);
            }
        }
        const appInfo = {
            deviceType: deviceType,
            deviceToken: deviceToken,
            majorVersion: majorVersion,
            minorVersion: minorVersion,
            buildNo: buildNo,
            grantedCamera: grantedCamera,
            grantedAudio: grantedAudio
        }
        dispatch(AppInfoAction(appInfo));    
    }, [dispatch]);

    const sendTalkCallResponse = React.useCallback((talkType: TALK_TYPE) => {
        if (
            refLoginUser == null ||
            refLoginUser.current == null ||
            refLoginUser.current.id == null ||
            refTalkRequestUser == null ||
            refTalkRequestUser.current == null ||
            refTalkRequestUser.current.id == null
        ) {
            return;
        }
        if (talkType == null) {
            talkType = TALK_TYPE.VOICE;
        }
        appServer.current.sendTalkCallResponse(
            refTalkRequestUser.current.id,
            refLoginUser.current.id,
            true,
            talkType,
            false
        );
    }, [
        refLoginUser,
        refTalkRequestUser,
    ]);

    const requestAppPermission = React.useCallback((
        talkType: TALK_TYPE, 
        callback: string,
        receiverId: number
    ) => {
        if (refAppPermissionList.current.length === 0) {
            if (callback === "request_talk") {
                if (emitter != null && emitter.current != null) {
                    emitter.current.emit("ON_GRANT_APP_PERMISSION", receiverId);
                }
            } else if (callback === "received_talk_request") {
                sendTalkCallResponse(talkType);
            }
        } else {
            let obj = {
                talk_type: talkType,
                callback: callback,
                receiver_id: receiverId
            };
            const permission = refAppPermissionList.current[0];
            obj = Object.assign({}, obj, { permission: permission });
            const strJson = JSON.stringify(obj);
            switch (permission) {
                case APP_PERMISSION.CAMERA:
                    window.AndroidInterface.requestCameraPermission(strJson);
                    break;
                case APP_PERMISSION.AUDIO:
                    window.AndroidInterface.requestAudioPermission(strJson);
                    break;
            }
        }
    }, [sendTalkCallResponse]);
    
    const onGrantAppPermission = React.useCallback((obj: any) => {
        if (Object.keys(obj).indexOf("permission") === -1) {
            return;
        }
        if (Object.keys(obj).indexOf("talk_type") === -1) {
            return;
        }
        if (Object.keys(obj).indexOf("callback") === -1) {
            return;
        }
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const permission = obj.permission as APP_PERMISSION;
        const talkType = obj.talk_type as number;
        const callback = obj.callback as string;
        const receiverId = obj.receiver_id as number;
        const permissionList = refAppPermissionList.current.filter((_permission: APP_PERMISSION) => {
            if (permission === _permission) {
                return false;
            } else {
                return true;
            }
        });
        refAppPermissionList.current = permissionList;
        if (permissionList.length === 0) {
            if (Object.keys(obj).indexOf("callback") === -1) {
                return;
            }
            const callback = obj["callback"] as string;
            if (callback === "request_talk") {
                if (Object.keys(obj).indexOf("receiver_id") === -1) {
                    return;
                }
                const receiverId = obj["receiver_id"] as number;
                if (emitter != null && emitter.current != null) {
                    emitter.current.emit("ON_GRANT_APP_PERMISSION", receiverId);
                }
            } else if (callback === "received_talk_request") {
                sendTalkCallResponse(talkType);
            }
        } else {
            requestAppPermission(
                talkType,
                callback,
                receiverId
            );
        }
        switch (permission) {
            case APP_PERMISSION.CAMERA:
                dispatch(AppInfoAction({
                    grantedCamera: true,
                }));
                break;
            case APP_PERMISSION.AUDIO:
                dispatch(AppInfoAction({
                    grantedAudio: true,
                }));
                break;
        }

    }, [
        dispatch,
        requestAppPermission,
        sendTalkCallResponse
    ]);

    const onRejectAppPermission = React.useCallback((obj: any) => {
        const message = intl.formatMessage({id: "msg_talk_need_permissions"});
        setDialogMessage(message);
        setOpenDialog(true);

        if (Object.keys(obj).indexOf("permission") === -1) {
            return;
        }
        const permission = obj.permission as APP_PERMISSION
        switch (permission) {
            case APP_PERMISSION.CAMERA:
                dispatch(AppInfoAction({
                    grantedCamera: false,
                }));
                break;
            case APP_PERMISSION.AUDIO:
                dispatch(AppInfoAction({
                    grantedAudio: false,
                }));
                break;
        }
        if (Object.keys(obj).indexOf("callback") === -1) {
            return;
        }
        const callback = obj["callback"] as string;
        if (callback === "received_talk_request") {
            onRejectTalkRequest();
        }
    }, [
        intl,
        dispatch,
        onRejectTalkRequest,
    ]);
    const handlePermissionResult = React.useCallback((obj: any) => {
        if (Object.keys(obj).indexOf("granted") === -1) {
            return;
        }
        const granted = obj["granted"] as boolean;
        if (granted) {
            onGrantAppPermission(obj);
        } else {
            onRejectAppPermission(obj);
        }
    }, [
        onGrantAppPermission,
        onRejectAppPermission,
    ]);
    const registerAndroidAppEventListener = React.useCallback(() => {
        // Androidからのメッセージを受け取る関数を定義
        if (window.AndroidInterface) {
            window.AndroidInterface.onReceivePermissionResult = (jsonString: string) => {
                const obj = JSON.parse(jsonString);
                handlePermissionResult(obj);
            };
        }
    }, [
        handlePermissionResult,
    ]);

    const updateUserInfo = React.useCallback(async(user: UserModel) => {
        if (user.id == null || user.createdAt == null || user.bearerToken == null) {
            return;
        }
        const updateUser = new UserModel();
        updateUser.id = user.id;
        updateUser.deviceType = deviceType;
        if (deviceType !== DbConstants.DEVICE_TYPE_BROWSER) {
            updateUser.majorVersion = majorVersion;
            updateUser.minorVersion = minorVersion;
            updateUser.buildNo = buildNo;
            updateUser.deviceToken = deviceToken;
        }
        updateUser.createdAt = user.createdAt;
        updateUser.bearerToken = user.bearerToken;

        const result = await UserRequest.save(updateUser);
        if (result == null) {
            if (!window.navigator.onLine) {
                dispatch(NetworkAction({connected: false}));
            }
            return;
        }
        if (result.rtnCd != null && result.rtnCd === 0) {
            const appInfo = {
                deviceType: deviceType,
                deviceToken: deviceToken,
                majorVersion: majorVersion,
                minorVersion: minorVersion,
                buildNo: buildNo,
            }
            dispatch(AppInfoAction(appInfo));    
            if (result.user != null && result.bearerToken != null) {
                result.user.bearerToken = result.bearerToken;
                window.setTimeout(() => {
                    dispatch(UserAction({ loginUser: result.user }));
                });
            }
        }
    }, [
        dispatch,
        deviceType,
        majorVersion,
        minorVersion,
        buildNo,
        deviceToken
    ]);
    const needUpdateUserInfo = React.useCallback((user: UserModel) => {
        if (user.deviceType !== deviceType) {
            return true;
        }
        if (deviceType !== DbConstants.DEVICE_TYPE_BROWSER) {
            if (user.majorVersion !== majorVersion) {
                return true;
            }
            if (user.minorVersion !== minorVersion) {
                return true;
            }
            if (user.buildNo !== buildNo) {
                return true;
            }
            if (user.deviceToken !== deviceToken) {
                return true;
            }
        }
        return false;
    }, [
        deviceType,
        majorVersion,
        minorVersion,
        buildNo,
        deviceToken
    ]);
    const initialize = React.useCallback(async () => {
        if (deviceType == null) {
            getAppInfo();
            return;
        }
        IndexedDb.initialize();
        if (deviceType != null && deviceType === DbConstants.DEVICE_TYPE_ANDROID) {
            registerAndroidAppEventListener();
        }
        const timezoneOffset = new Date().getTimezoneOffset() + 540;
        dispatch(
            await TimeDifferenceAction({ difference: timezoneOffset * 60 })
        );
        await getSystemInfo();
        await fetchAccessToken();
        setCompletedAccessTokenResult(true);
        setShowPage(true);
    }, [
        deviceType,
        getAppInfo,
        dispatch,
        fetchAccessToken,
        getSystemInfo,
        registerAndroidAppEventListener,
    ]);

    /**
     * useEffect
     */
    React.useEffect(() => {
        // http接続の場合はhttpsにリダイレクト
        if (AppConstants.mode === MODE.PRODUCTION) {
            const protocol = window.location.protocol;
            const port = window.location.port;
            if (protocol === "http:" &&
                port !== "8082"
            ) {
                window.location.href = UrlConstants.URL_HOST;
                return;
            }
        }
    }, []);
    React.useEffect(() => {
        (async () => {
            if (
                initializeCompleted == null ||
                initializeCompleted.current == null ||
                !initializeCompleted.current
            ) {
                await initialize();
                initializeCompleted.current = true;
            }
        })();
    }, [
        initialize,
    ]);
    React.useEffect(() => {
        if (grantedCamera === true) {
            const permissionList = refAppPermissionList.current.filter((permission: APP_PERMISSION) => {
                if (permission === APP_PERMISSION.CAMERA) {
                    return false;
                } else {
                    return true;
                }
            });
            refAppPermissionList.current = permissionList;
        } else {
            let exist = false;
            for (let i=0; i<refAppPermissionList.current.length; i++) {
                const permission = refAppPermissionList.current[0];
                if (permission === APP_PERMISSION.CAMERA) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                refAppPermissionList.current.push(APP_PERMISSION.CAMERA);
            }
        }
    }, [
        grantedCamera,
    ]);
    React.useEffect(() => {
        if (grantedAudio !== true) {
            let exist = false;
            for (let i=0; i<refAppPermissionList.current.length; i++) {
                const permission = refAppPermissionList.current[0];
                if (permission === APP_PERMISSION.AUDIO) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                refAppPermissionList.current.push(APP_PERMISSION.AUDIO);
            }
        } else {
            const permissionList = refAppPermissionList.current.filter((permission: APP_PERMISSION) => {
                if (permission === APP_PERMISSION.AUDIO) {
                    return false;
                } else {
                    return true;
                }
            });
            refAppPermissionList.current = permissionList;
        }
    }, [
        grantedAudio,
    ]);
    React.useEffect(() => {
        if (wkLoginUser == null || deviceType == null) {
            return;
        }
        if (deviceType !== DbConstants.DEVICE_TYPE_BROWSER) {
            if (majorVersion == null || minorVersion == null || buildNo == null) {
                return;
            }
        }
        if (needUpdateUserInfo(wkLoginUser)) {
            updateUserInfo(wkLoginUser);
            return;
        }
    }, [
        wkLoginUser,
        deviceType,
        majorVersion,
        minorVersion,
        buildNo,
        deviceToken,
        needUpdateUserInfo,
        updateUserInfo,
    ]);
    React.useEffect(() => {
        window.sessionStorage.clear();
        if (wkLoginUser != null) {
            if (refLoginUser == null ||
                refLoginUser.current == null ||
                refLoginUser.current.id !== wkLoginUser.id
            ) {
                setLoginUser(wkLoginUser);
                refLoginUser.current = wkLoginUser;
                connectToAppServer(wkLoginUser);
            } else {
                setLoginUser(wkLoginUser);
                refLoginUser.current = wkLoginUser;
            }
        } else {
            setLoginUser(null);
            refLoginUser.current = undefined;
            appServer.current.disconnect();
        }
        return () => {
        };
    }, [
        wkLoginUser,
        connectToAppServer
    ]);
    React.useEffect(() => {
        (async () => {
            if (emitter == null || emitter.current == null) {
                emitter.current = new EventEmitter();

                dispatch(await EmitterAction({ instance: emitter.current }));
            }
        })();
    }, [
        emitter,
        dispatch
    ]);
    React.useEffect(() => {
        setShowSnackbar(!networkConnected);
    }, [networkConnected]);

    /**
     * 処理中バックドロップを閉じる
     */
    const handleClose = async () => {
        dispatch(ProcessAction({ processing: false, message: "" }));
    };

    /**
     * Appサーバー接続コールバック
     */
    function appServerConnectCallback() {
        appServer.current.sendStartupInfoToAppServer();
    }

    /**
     * トークメッセージ既読通知受信時
     * @param talkMessage
     */
    function onReceiveReadMessage(talkMessage: TalkMessageModel) {
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("READ_TALK_MESSAGE", talkMessage);
        }
    }
    /**
     * 接続状況の確認の結果受信時
     * @param obj
     */
    function onReceivedResponseForConnectStatusConfirmation(obj: any) {
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }

        if (refLoginUser.current.id !== senderId) {
            return;
        }
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("RESPONSE_FOR_CONFIRM_CONNECT_STATUS", obj);
        }
    }
    /**
     * トーク要求の受信時
     * @param obj
     */
    async function onReceivedTalkRequest(obj: any) {
        Utility.log("***** App onReceivedTalkRequest IN");
        if (refLoginUser == null || refLoginUser.current == null || refLoginUser.current.id == null) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 1");
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 2");
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 3");
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 4");
        if (Object.keys(obj).indexOf("talk_type") === -1) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 5");

        let partnerId = 0;
        const pathname = window.location.pathname;
        if (pathname.includes("/talk/call/")) {
            const strPartnerId = pathname.replace("/talk/call/", "");
            if (strPartnerId != null && strPartnerId.length > 0) {
                partnerId = parseInt(strPartnerId);
            }
        }
        // 通話状況取得
        const strRtcSenderId = window.sessionStorage.getItem("rtcSenderId");
        const strRtcReceiverId = window.sessionStorage.getItem("rtcReceiverId");
        const strRtcSenderTalkType = window.sessionStorage.getItem("rtcSenderTalkType");
        const strRtcReceiverTalkType = window.sessionStorage.getItem("rtcReceiverTalkType");
        const strTalkStatus = window.sessionStorage.getItem("rtcTalkStatus");
        // 通話中の場合
        if (strRtcSenderId != null && 
            strRtcReceiverId != null && 
            strRtcSenderTalkType != null && 
            strRtcReceiverTalkType != null &&
            partnerId > 0 &&
            strTalkStatus != null &&
            (parseInt(strTalkStatus) === TALK_STATUS.UNDER_TALK ||
            parseInt(strTalkStatus) === TALK_STATUS.PAUSE_TALK)
        ) {
            const rtcSenderId = parseInt(strRtcSenderId);
            const rtcReceiverId = parseInt(strRtcReceiverId);
            const rtcSenderTalkType = parseInt(strRtcSenderTalkType);
            const rtcReceiverTalkType = parseInt(strRtcReceiverTalkType);
            Utility.log("rtcSenderId:" + rtcSenderId);
            Utility.log("rtcReceiverId:" + rtcReceiverId);
            Utility.log("rtcSenderTalkType:" + rtcSenderTalkType);
            Utility.log("rtcReceiverTalkType:" + rtcReceiverTalkType);

            appServer.current.sendTalkCallResponse(
                senderId,
                refLoginUser.current.id,
                false,
                TALK_TYPE.NONE,
                true
            );
    
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 6");

        setShowConfirmTalkRequest(false);
        const talkType = obj.talk_type;

        const result = await UserRequest.getUser(senderId);
        if (
            result == null ||
            result.rtnCd == null ||
            result.rtnCd < 0 ||
            result.user == null
        ) {
            return;
        }
        Utility.log("***** App onReceivedTalkRequest 7");
        refTalkRequestUser.current = result.user;
        setTalkRequestUser(result.user);
        setTalkTypeRequest(talkType);
        setShowConfirmTalkRequest(true);
    }
    /**
     * 受信したトーク要求のキャンセル通知の受信時
     * @param obj
     */
    async function onReceiveCancelRequestCall(obj: any) {
        Utility.log("App onReceiveCancelRequestCall IN");
        setShowConfirmTalkRequest(false);
    }
    /**
     * トーク要求に対するトーク状況の受信時
     * @param obj
     */
    // async function onReceivedTalkStatus(obj: any) {
    //     Utility.log("App onReceivedTalkStatus IN");
    //     if (emitter != null && emitter.current != null) {
    //         Utility.log("App onReceivedTalkStatus before emitter");
    //         emitter.current.emit("RECEIVED_TALK_STATUS", obj);
    //     }
    // }
    /**
     * 発信したトーク要求に対する返信の受信時
     * @param obj
     * @returns
     */
    async function onReceivedCallResponse(obj: any) {
        Utility.log("App onReceivedCallResponse IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        if (refLoginUser.current.id !== senderId) {
            return;
        }
        if (Object.keys(obj).indexOf("talk_type") === -1) {
            return;
        }
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("ON_RECEIVE_RESPONSE_OF_TALK_REQUEST", obj);
        }
    }
    /**
     * トーク要求発信者がトーク開始した通知の受信時
     * @param obj
     * @returns
     */
    async function onReceivedTalkStartedNotification(obj: any) {
        Utility.log("App onReceivedTalkStartedNotification IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 1");
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 2");
        const senderId = obj.sender_id;
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 3");
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 4");
        if (Object.keys(obj).indexOf("sender_talk_type") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 5");
        const senderTalkType = obj.sender_talk_type;
        if (Object.keys(obj).indexOf("receiver_talk_type") === -1) {
            return;
        }
        Utility.log("App onReceivedTalkStartedNotification 6");

        const pathname = `/talk/talk/${senderId}`;
        if (window.location.pathname === pathname) {
            // イベント発行
            Utility.log("App onReceivedTalkStartedNotification 7");
            if (emitter != null && emitter.current != null) {
                emitter.current.emit("START_TALK", obj);
            }
        } else {
            Utility.log("App onReceivedTalkStartedNotification 8");
            const receiverTalkType = obj.receiver_talk_type;
            // window.sessionStorage.setItem("sender_talk_type", String(senderId));
            // window.sessionStorage.setItem("receiver_talk_type", String(receiverTalkType));
            // window.sessionStorage.setItem("talk_started", "1");
            
            // セッションストレージにトーク開始情報をセット
            window.sessionStorage.setItem("SENDER_ID", String(senderId));
            window.sessionStorage.setItem("RECEIVER_ID", String(receiverId));
            window.sessionStorage.setItem("SENDER_TALK_TYPE", String(senderTalkType));
            window.sessionStorage.setItem("RECEIVER_TALK_TYPE", String(receiverTalkType));
            window.sessionStorage.setItem("SENDER_TALK_STARTED", "1");
            // イベント発行
            if (emitter != null && emitter.current != null) {
                emitter.current.emit("GO_TO_TALK_SCREEN", senderId);
            }
        }
    }
    /**
     * トーク許可変更通知の受信時
     * @param obj
     * @returns
     */
    async function onReceivedTalkPermissionChange(obj: any) {
        Utility.log("App onReceivedTalkPermissionChange IN");
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;
        if (refLoginUser.current.id !== receiverId) {
            return;
        }
        if (Object.keys(obj).indexOf("permitted") === -1) {
            return;
        }

        // イベント発行
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("CHANGED_TALK_PERMISSION", obj);
        }
    }
    // /**
    //  * トーク開始失敗の通知の受信時
    //  * @param obj
    //  * @returns
    //  */
    async function onReceivedTalkFailureNotification(obj: any) {
        Utility.log("App onReceivedTalkFailureNotification IN");
        setShowConfirmTalkRequest(false);
        if (refLoginUser == null || refLoginUser.current == null) {
            return;
        }
        if (Object.keys(obj).indexOf("sender_id") === -1) {
            return;
        }
        if (Object.keys(obj).indexOf("receiver_id") === -1) {
            return;
        }
        const receiverId = obj.receiver_id;
        if (receiverId !== refLoginUser.current.id) {
            return;
        }

        // イベント発行
        if (emitter != null && emitter.current != null) {
            emitter.current.emit("TALK_FAILURE_NOTIFICATION", obj);
        }
    }

    /**
     * Appサーバー切断コールバック
     */
    function appServerDisconnectCallback() {}


    /**
     * 受信したトーク要求を承諾
     * @returns
     */
    async function onAcceptTalkRequest(talkType: TALK_TYPE) {
        setShowConfirmTalkRequest(false);
        if (refLoginUser == null || refLoginUser.current == null || refLoginUser.current.id == null) {
            return;
        }
        if (deviceType === DbConstants.DEVICE_TYPE_ANDROID) {
            if (window.AndroidInterface) {
                requestAppPermission(
                    talkType, 
                    "received_talk_request",
                    refLoginUser.current.id
                );
            }
        } else if (deviceType === DbConstants.DEVICE_TYPE_IOS) {

        } else {
            sendTalkCallResponse(talkType);
        }
    }    
    
    return (
        <div id="App" className="App">
            {showPage && completedAccessTokenResult && (
                <>
                    <>
                        <Backdrop
                            className="backdrop"
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                            }}
                            open={processing}
                            onClick={handleClose}
                        >
                            <div className="backdrop-content-wrapper">
                                <CircularProgress color="inherit" />
                                {processingMessage != null &&
                                    processingMessage.length > 0 && (
                                        <div className="backdrop-content">
                                            <FormattedMessage
                                                id={processingMessage}
                                            />
                                        </div>
                                    )}
                            </div>
                        </Backdrop>
                        <div
                            className={
                                completedAccessTokenResult
                                    ? "router-wrapper"
                                    : "d-none"
                            }
                        >
                            <Router
                                loginUser={loginUser}
                                maintenance={underMaintenance}
                            />
                        </div>
                        <Dialog
                            className="dialog"
                            open={openDialog}
                            onClose={() => {
                                setOpenDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent className="dialog-content2">
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    color="primary"
                                    onClick={() => {
                                        setOpenDialog(false);
                                    }}
                                >
                                    <FormattedMessage id={"btn_ok"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {loginUser != null && 
                         showConfirmTalkRequest && 
                         talkRequestUser != null && 
                         talkTypeRequest != null && (
                            <TalkCallingDialog
                                loginUser={loginUser}
                                show={showConfirmTalkRequest}
                                talkRequestUser={talkRequestUser}
                                talkTypeRequest={talkTypeRequest}
                                onRejectTalkRequest={onRejectTalkRequest}
                                onAcceptTalkRequest={(talkType: TALK_TYPE) => {
                                    onAcceptTalkRequest(talkType)
                                }
                                }
                            />
                        )}
                        <Snackbar
                            open={showSnackbar}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                            autoHideDuration={4000}
                            sx={{
                                top: "45% !important",
                                width: "fitContent",
                                minWidth: "270px",
                                whiteSpace: "nowrap",
                                left: "50% !important",
                                right: "auto",
                                transform: "translateX(-50%)",
                            }}
                            onClose={() => {
                                dispatch(NetworkAction({connected: true}));
                            }}
                        >
                            <Alert
                                severity="error"
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                <FormattedMessage id="err_internet" />
                            </Alert>
                        </Snackbar>
                    </>
                </>
            )}
        </div>
    );
};

export default App;
