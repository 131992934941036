type PageViewEvent = {
    event: 'page_view';
    screen: string;
    path: string;
    deviceType: number | null;
};

type ClickEvent = {
    event: 'click';
    label: string;
};


export type DataLayerType = PageViewEvent | ClickEvent

export const pushDataLayer = (data: DataLayerType): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};