/***** import */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, useScrollTrigger } from "@mui/material";
import { UserRequest } from "api/requests/Requests";
import { UserModel } from "models/Models";
import { NavBar, UserListItem } from "components/Components";
import { NetworkAction, ProcessAction, SelectedItemAction } from "redux/Actions";
import { getTimeDifference, getLanguageCode, getDeviceType } from "redux/Selectors";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/FollowUserList.scss";

/***** type */
type Props = {
    loginUser: UserModel;
};

/**
 * フォローユーザリスト画面
 * @param props
 * @returns
 */
const FollowUserList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ FollowUserList IN");
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const container = React.useRef<HTMLDivElement>(null);
        const deviceType: number | null = useSelector((state) => getDeviceType(state));
        const langCd = useSelector((state) => getLanguageCode(state));
        // 時差
        const timeDifference = useSelector((state) => getTimeDifference(state));

        /***** useRef */

        /***** useState */
        // フォロー中ユーザリスト
        const [lstUser, setUserList] = React.useState<UserModel[]>();
        // データロード完了フラグ
        const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

        const trigger = useScrollTrigger({
            target: window,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            window.scrollTo({ top: 0 });
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "フォローユーザ一覧",
                path: window.location.pathname,
                deviceType: deviceType,
            });
        }, []);
        React.useEffect(() => {
            if (props.loginUser != null) {
                (async () => {
                    dispatch(
                        ProcessAction({
                            processing: true,
                            message: "msg_loading",
                        })
                    );

                    // フォロー中ユーザリスト取得
                    await fetchFollowUserList();

                    setDataLoaded(true);
                    dispatch(ProcessAction({ processing: false, message: "" }));
                })();
            }
        }, [props.loginUser]);
        React.useEffect(() => {}, [lstUser]);

        /**
         * ユーザクリック時
         * @param user
         */
        async function onClickUser(user: UserModel) {
            if (user != null) {
                const key = Utility.getUserKey(user);
                if (key.length > 0) {
                    dispatch(SelectedItemAction({ userId: user.id }));
                    window.setTimeout(function () {
                        navigate(`/users/${key}/profile`);
                    });
                }
            }
        }

        /**
         * フォロー中ユーザリスト取得
         * @param index
         * @returns
         */
        async function fetchFollowUserList() {
            const result = await UserRequest.getFollowUserList(props.loginUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (result.lstFollow != null) {
                if (result.lstFollow != null) {
                    let wkUserList: UserModel[] | undefined = lstUser;
                    if (wkUserList == null) {
                        wkUserList = [];
                    }
                    for (let i = 0; i < result.lstFollow.length; i++) {
                        const follow = result.lstFollow[i];
                        const target = follow.target;
                        if (target != null) {
                            wkUserList.push(target);
                        }
                    }
                    setUserList(wkUserList);
                }
            }
        }
        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper FollowUserList">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "follow_user_list" })}
                />
                <Box
                    component="h1"
                    className="page-title"
                    display={{
                        xs: "none",
                        sm: "block",
                    }}
                >
                    <FormattedMessage id={"follow_user_list"} />
                </Box>

                {langCd != null &&
                    timeDifference != null &&
                    dataLoaded &&
                    lstUser != null && (
                        <>
                            <div className="list-container" ref={container}>
                                {lstUser.map((user: UserModel) => {
                                    return (
                                        <div
                                            className="list-item-wrapper"
                                            key={user.id}
                                            onClick={() => onClickUser(user)}
                                        >
                                            <UserListItem
                                                user={user}
                                                langCd={langCd}
                                                timeDifference={timeDifference}
                                                profImageUrlParam={user.id === props.loginUser?.id ? props.loginUser.profileImageUrlParam : null}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <Zoom in={trigger}>
                                <div
                                    className="scroll-to-top"
                                    role="presentation"
                                >
                                    <Fab
                                        onClick={scrollToTop}
                                        color="primary"
                                        size="small"
                                        aria-label="scroll back to top"
                                    >
                                        <KeyboardArrowUp />
                                    </Fab>
                                </div>
                            </Zoom> */}
                        </>
                    )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default FollowUserList;
